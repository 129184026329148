import React, { Component, Fragment } from 'react';
import Vendor from '../Vendor';
import ChildInfoGeneral from './ChildInfoGeneral';
import ChildInfoBilingualESL from './ChildInfoBilingualESL';
import ChildInfoDyslexia from './ChildInfoDyslexia';
import ChildInfoPeims from './ChildInfoPeims';
import ChildInfoProgressionPlan from './ChildInfoProgressionPlan';
import ChildInfoAddressAndContacts from './ChildInfoAddressAndContacts';
import tempLinksRemote from '../../../api/tempLinksRemote';
import Grades from './Grades';
import Absences from './Absences';
import AssignmentsAndGrades from './AssignmentsAndGrades';
import profileReqHandler from '../../../api/profileRemote';
import authentication from '../../../utilities/authentication';
import toastr from 'toastr';
import { updateEyesHelper } from '../../common/UpdateEyesHelper';
import { withTranslation } from 'react-i18next';
import { tWrapper } from '../../../utilities/translationWrapper';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

class ProfileTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            parentData: {},
            profile: {},
            providerCredentials: {},
            showVisibilitySettings: false,
            showHideBtnText: this.props.t('visibilitySettings.show')
        };

        this.deleteProfile = this.deleteProfile.bind(this);
    }

    componentDidMount() {
        this.setState({
            visibilitySchema: this.props.responseData.profile.visibilitySchema
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.parentData.profileId !== prevProps.parentData.profileId) {
            this.setState({
                visibilitySchema: this.props.responseData.profile.visibilitySchema
            })
        }
    }

    deleteProfile() {

        if (this.props.responseData.profile && this.props.responseData.profile.id) {

            const profileObj = { profileId: this.props.responseData.profile.id };

            profileReqHandler.deleteProfile(profileObj).then(res => {

                if (res.success) {
                    window.location.reload();
                }
            });
        }
    }

    eyeToggle(property) {
        return this.state.visibilitySchema._globalSettings[property] ? 'eyeBlack' : 'eyeGray';
    }

    eyeClicked(clickedEye, subClickedEye, subIndex) {
        const newState = this.props.eyeClicked(this.state, clickedEye, subClickedEye, subIndex);
        this.setState(newState);
    }

    showHideEye() {
        const currVisibility = !this.state.showVisibilitySettings;
        this.setState({ showVisibilitySettings: currVisibility });

        if (currVisibility) {
            this.setState({ showHideBtnText: this.props.t('visibilitySettings.hide') });
        } else {
            this.setState({ showHideBtnText: this.props.t('visibilitySettings.show') });
        }
    }

    updateVisibilitySettings() {

        const loggedData = authentication.loggedData();

        const updateData = {
            id: this.props.parentData.profileId,
            user: loggedData.userId,
            visibilitySchema: this.state.visibilitySchema
        }

        this.props.updateVisibilitySchema(updateData).then(() => {
            toastr.success(this.props.t('visibilitySettings.seccess'));
        })
    }

    markAll(value) {        
        let data = JSON.parse(JSON.stringify(this.state.visibilitySchema));
        this.setState({
            visibilitySchema: this.props.markAll(data, value)
        })
    }

    generateTempLink(values) {
        const loggedData = authentication.loggedData();

        const tempLink = {
            user: loggedData.userId,
            email: values.email,
            profileId: this.props.responseData.profile.id,
            url: 'printProfile',
            content: {
                parentData: this.props.parentData,
                responseData: this.props.responseData,
                updateVisibilitySchema: this.props.updateVisibilitySchema,
                adminMode: this.props.adminMode,
            }
        }

        tempLinksRemote.createTempLink(tempLink).then(res => {
            if (res) {
                console.log('Success!')
            }
        });
    }

    render() {
        const t = tWrapper(this.props.t);
        console.log(this.props);
        return (
            <Fragment>
                <div className="container">
                    {this.props.responseData.profile.content && this.props.parentData &&
                        <Fragment>
                            <div className="row">
                                <div className="tab-warper">
                                    {this.props.adminMode === false &&
                                        <Vendor
                                            providerId={this.props.parentData.providerId}
                                            providerName={this.props.parentData.providerName}
                                            providerStatus={this.props.parentData.providerStatus}
                                            profileId={this.props.parentData.profileId}
                                            providerCredentials={this.props.responseData.providerCredentials || {}}
                                            lastUpdate={this.props.responseData.profile.updated}
                                            t={t}
                                        >
                                        </Vendor>
                                    }
                                </div>
                            </div>
                            {this.props.adminMode === false &&
                                <div className="row">
                                    <div className="tab-warper">
                                        {
                                            this.state.showVisibilitySettings &&
                                            <div>
                                                <button className="btn btn-primary"
                                                    onClick={() => { this.markAll(true) }}>
                                                    {this.props.t('visibilitySettings.markAllButton')}
                                                </button>
                                                <button className="btn btn-default marginLeft10"
                                                    onClick={() => { this.markAll(false) }}>
                                                    {this.props.t('visibilitySettings.unmarkAllButton')}
                                                </button>
                                            </div>
                                        }
                                        <h2>
                                            {this.state.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('all'))}
                                                    onClick={() => { this.eyeClicked('all') }}>
                                                </i>
                                            }
                                            {this.props.parentData.providerName.toUpperCase() + ' - ' + this.props.responseData.profile.firstName + ' ' + this.props.responseData.profile.lastName}
                                        </h2>
                                        <button
                                            type="button"
                                            className="btn btn-default"
                                            onClick={this.showHideEye.bind(this)}
                                        >{this.state.showHideBtnText}</button>
                                        {this.state.showVisibilitySettings &&
                                            <button
                                                type="button"
                                                id="saveVisibilitySett"
                                                className="btn btn-primary"
                                                onClick={this.updateVisibilitySettings.bind(this)}
                                            >{t('visibilitySettings.update')}</button>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="tab-warper">
                                    <ChildInfoGeneral
                                        profile={this.props.responseData.profile}
                                        providerName={this.props.parentData.providerName}
                                        showVisibilitySettings={this.state.showVisibilitySettings}
                                        visibilitySchema={this.state.visibilitySchema}
                                        eyeClicked={(subClickedEye) => { this.eyeClicked('childInfoGeneral', subClickedEye) }}
                                        t={t}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="tab-warper">
                                    <ChildInfoAddressAndContacts
                                        profile={this.props.responseData.profile}
                                        providerName={this.props.parentData.providerName}
                                        showVisibilitySettings={this.state.showVisibilitySettings}
                                        visibilitySchema={this.state.visibilitySchema}
                                        eyeClicked={(subClickedEye) => { this.eyeClicked('childInfoAddressAndContacts', subClickedEye) }}
                                        t={t}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="tab-warper">
                                    <ChildInfoBilingualESL
                                        profile={this.props.responseData.profile}
                                        providerName={this.props.parentData.providerName}
                                        showVisibilitySettings={this.state.showVisibilitySettings}
                                        visibilitySchema={this.state.visibilitySchema}
                                        eyeClicked={(subClickedEye) => { this.eyeClicked('childInfoBilingualESL', subClickedEye) }}
                                        t={t}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="tab-warper">
                                    <ChildInfoProgressionPlan
                                        profile={this.props.responseData.profile}
                                        providerName={this.props.parentData.providerName}
                                        showVisibilitySettings={this.state.showVisibilitySettings}
                                        visibilitySchema={this.state.visibilitySchema}
                                        eyeClicked={(subClickedEye) => { this.eyeClicked('childInfoProgressionPlan', subClickedEye) }}
                                        t={t}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="tab-warper">
                                    <ChildInfoPeims
                                        profile={this.props.responseData.profile}
                                        providerName={this.props.parentData.providerName}
                                        showVisibilitySettings={this.state.showVisibilitySettings}
                                        visibilitySchema={this.state.visibilitySchema}
                                        eyeClicked={(subClickedEye) => { this.eyeClicked('childInfoPeims', subClickedEye) }}
                                        t={t}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="tab-warper">
                                    <ChildInfoDyslexia
                                        profile={this.props.responseData.profile}
                                        providerName={this.props.parentData.providerName}
                                        showVisibilitySettings={this.state.showVisibilitySettings}
                                        visibilitySchema={this.state.visibilitySchema}
                                        eyeClicked={(subClickedEye) => { this.eyeClicked('childInfoDyslexia', subClickedEye) }}
                                        t={t}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="tab-warper">
                                    <AssignmentsAndGrades
                                        profile={this.props.responseData.profile}
                                        providerName={this.props.parentData.providerName}
                                        showVisibilitySettings={this.state.showVisibilitySettings}
                                        tempLinkCodeAccess={this.props.adminMode}
                                        visibilitySchema={this.state.visibilitySchema}
                                        eyeClicked={(subClickedEye) => { this.eyeClicked('assignmentsAndGrades', subClickedEye) }}
                                        t={t}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="tab-warper">
                                    <Grades
                                        profile={this.props.responseData.profile}
                                        providerName={this.props.parentData.providerName}
                                        showVisibilitySettings={this.state.showVisibilitySettings}
                                        visibilitySchema={this.state.visibilitySchema}
                                        tempLinkCodeAccess={this.props.adminMode}
                                        eyeClicked={(subClickedEye) => { this.eyeClicked('grades', subClickedEye) }}
                                        t={t}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="tab-warper">
                                    <Absences
                                        profile={this.props.responseData.profile}
                                        providerName={this.props.parentData.providerName}
                                        showVisibilitySettings={this.state.showVisibilitySettings}
                                        visibilitySchema={this.state.visibilitySchema}
                                        tempLinkCodeAccess={this.props.adminMode}
                                        eyeClicked={(subClickedEye) => { this.eyeClicked('absences', subClickedEye) }}
                                        t={t}
                                    />
                                </div>
                            </div>

                            <div className="row" id="deleteProfileRow">
                                <div className="col-md-10">
                                </div>
                                <div className="col-md-5">
                                    {this.props.adminMode === false &&
                                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#tempLinkModal">{t('profileTab.tempLink.popupButton')}</button>
                                    }
                                    {this.props.adminMode === false &&
                                        <button type="button" className="btn btn-danger marginLeft10" data-toggle="modal" data-target="#deleteProfileModal">{t('deleteProfile.button')}</button>
                                    }
                                    {/* {this.props.adminMode === true &&
                                        <Link className="btn btn-primary" to={'/admin/allProfiles'} > {t('profileTab.cancelButton')}</Link>
                                    } */}
                                </div>
                            </div>

                        </Fragment>
                    }
                </div>
                {/* <!-- Modal --> */}
                <div className="modal fade" id="deleteProfileModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="myModalLabel">{t('deleteProfile.confirmation')}</h4>
                            </div>
                            <div className="modal-body">
                                {t('deleteProfile.confirmationMessage')}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal">{t('deleteProfile.cencelButton')}</button>
                                <button type="button" className="btn btn-danger" onClick={this.deleteProfile}>{t('deleteProfile.confirmButton')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Formik
                    initialValues={{
                        email: '',
                    }}
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .required(t('profileTab.tempLink.required'))
                            .email(t('profileTab.tempLink.invalid')),
                    })}
                    onSubmit={(values) => {
                        this.generateTempLink(values)
                    }}

                >{formik =>
                    <div className="modal fade" id="tempLinkModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    {t('profileTab.tempLink.popupTitle')}
                                </div>
                                <div>
                                    <form>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-4 col-sm-6 col-xs-8">
                                                    <div className="form-group">
                                                        <label htmlFor="typeProviders">{t('profileTab.tempLink.emailLabel')}</label>
                                                        <Field as="input" name="email" className="form-control" />
                                                        {formik.touched.email && formik.errors.email ? (
                                                            <span className="inputErrorText">{formik.errors.email}</span>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-primary" onClick={formik.submitForm} data-dismiss="modal">{t('profileTab.tempLink.sendButton')}</button>
                                    <button type="button" className="btn btn-default" data-dismiss="modal">{t('profileTab.tempLink.cancelButton')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }</Formik>
            </Fragment>
        );
    };
};

export default withTranslation()(updateEyesHelper(ProfileTab));