import React, { Fragment } from 'react';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

export const Diagnosis = ({ formik }) => {
    const { t } = useTranslation();

    return (
        <div className="tab-warper">
            <h4 className="headName">{t('myChart.diagnosis.diagnosisTitle')}</h4>
            <FieldArray
                name="diagnosis"
                render={arrayHelpers => (
                    <Fragment>
                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayHelpers.push({
                                    name: '',
                                    code: '',
                                    date: ''
                                })}>
                                    <i className="fa fa-plus"></i> {t('myChart.diagnosis.addButton')}
                                                             </button>
                            </div>
                        </div>
                        {formik.values.diagnosis && formik.values.diagnosis.length !== 0 &&
                            formik.values.diagnosis.map((diagnose, i) => {
                                return <Fragment key={i}>
                                    <div className="row">
                                        <div className="col-md-4 customLabel">
                                            <div className="form-group">
                                                <label htmlFor={'name-' + i}>{t('myChart.shared.name')}</label>
                                                <input
                                                    id={'name-' + i}
                                                    className="form-control"
                                                    {...formik.getFieldProps(`diagnosis[${i}].name`)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 customLabel">
                                            <div className="form-group">
                                                <label htmlFor={'code-' + i}>{t('myChart.diagnosis.code')}</label>
                                                <input
                                                    id={'code-' + i}
                                                    className="form-control"
                                                    {...formik.getFieldProps(`diagnosis[${i}].code`)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3 customLabel">
                                            <div className="form-group">
                                                <label htmlFor={'date-' + i}>{t('myChart.shared.date')}</label>
                                                <input
                                                    id={'date-' + i}
                                                    className="form-control"
                                                    type="date"
                                                    {...formik.getFieldProps(`diagnosis[${i}].date`)}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <label>{t('myChart.shared.remove')}</label>
                                            <button type="button" className="btn btn-danger editRemove" onClick={() => {
                                                arrayHelpers.remove(i)
                                            }} >
                                                <span>&times;</span>
                                            </button>
                                        </div>
                                    </div>
                                </Fragment>
                            })
                        }
                    </Fragment>
                )}
            />
        </div>
    );
}