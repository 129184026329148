import HealthInfo from "../../dashboard/Education/HealthInfo";

let CreateProfile = {

    myChart: {

        personalInfo: {
            address: '',
            dateOfBirth: '',
            emergencyContactName: '',
            emergencyContactPhone: '',
            firstName: '',
            insuranceName: '',
            insuranceNumber: '',
            insuranceType: '',
            lastName: '',
            phoneNumber: '',
            race: '',
            zipCode: ''
        },
        allergies: [],
        diagnosis: [],
        planOfCare:{
            planOfCareOverdue: [],
            planOfCareNext: [],
            planOfCareFuture: []
        },
        history: {
            familyHistory: [],
            medicalHistory: [],
            socialHistory: {
                alcoholUse: {
                    alcoholUse: '',
                    ouncesWeek: ''
                },
                smokelessTobacco: {
                    smokelessTobaccoUse: '',
                    smokelessTobaccoTypes: ''
                },
                smokingTobacco: {
                    smokingTobaccoUse: '',
                    smokingTobaccoTypes: '',
                    packsDay: '',
                    yearsSmoked: ''
                }
            },
            surgicalHistory: []
        },
        immunizations: [],
        laboratoryTests: [],
        medications: [],
        files: []
    },
    education: {
        gradebooks: [],
        attendances: [],
        disciplines: [],
        studentInfo: {
            firstName: '',
            lastName: '',
            schoolMail: '',
            address: '',
            call: '',
            address: '',
            gender: '',
            age: '',
            language: '',
            graduationyear: '',
            otherid: '',
            communityservice: '',
            second: '',
            school: '',
            principal: '',
            grade: '',
            status: '',
            homeRoom: '',
            disciplineOfficer: '',
            itemStudentInfo: []
        },
        healthInfo: {
            healthconditions: [],
            diabetescarelog: [],
            disabilities: [],
            officevisits: [],
            ihpforms: [],
            childhoodillnesses: [],
            injuries: [],
            medications: [],
            physicalexams: [],
            tests: [],
            vaccinations: []
        }
    },

}
export default CreateProfile;