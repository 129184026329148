import React from 'react';

export const PersonalInfo = ({ profiles, t }) => {

    const personalInfoVisibility = (currProfile, index, property) => {

        if (currProfile.personalInfo[property]) {
            return <div key={index} className="row">
                <div className="col-md-6 firstName">{currProfile.personalInfo[property]}</div>
                <div className="col-md-3 firstName">{currProfile.exportPersonName}</div>
                <div className="col-md-3 firstName">{currProfile.providerName}</div>
            </div>;
        }
    }

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('myChart.personalInfo.personalInfoTitle')}</h4>

                <div className="profileContent">

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.firstName', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'firstName');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.lastName', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'lastName');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.dateOfBirth', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'dateOfBirth');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.demographicsRace', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'race');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.medicalRecordNumber', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'medicalRecordNumber');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.phoneNumber', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'phoneNumber');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.address', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'address');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.zipCode', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'zipCode');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.primaryProvider', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'primaryProvider');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.clinic', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'clinic');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.emergencyContactName', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'emergencyContactName');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.emergencyContactPhoneNumber', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'emergencyContactPhone');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.insuranceName', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'insuranceName');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.insuranceType', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'insuranceType');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.personalInfo.insuranceNumber', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return personalInfoVisibility(currProfile, i, 'insuranceNumber');
                        })
                    }
                </div>
            </div>
        </div>
    )
}