import host from './host';
import headers from '../utilities/headers';
import authentication from '../utilities/authentication';
import notifications from '../utilities/notifications';
import fetchApi from '../utilities/fetch';

let userReqHandler = {

    register: (regObj) => {

        return fetch(host + '/register', {
            method: 'POST',
            headers: headers.basic,
            body: JSON.stringify(regObj)
        }).then(resp => {
            return handleResponse(resp);
        }).catch(e => {
            
            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    },
    login: (loginObj) => {

        return fetch(host + '/login', {
            method: 'POST',
            credentials: 'include',
            headers: headers.basic,
            body: JSON.stringify(loginObj)
        }).then(resp => {
            return handleResponse(resp);
        }).catch(e => {
            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    },
    logout: () => {

        return fetch(host + '/logout', {
            method: 'POST',
            credentials: 'include',
            headers: headers.basic
        }).then(resp => {
            return handleResponse(resp);
        }).catch(e => {
            
            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    },
    sendRecoveryEmail: (recoveryObj) => {
        return fetchApi.post('/forgottenPassword', recoveryObj)
    },
    changePassword: (passwordObj) => {

        return fetch(host + '/user/resetPassword', {
            method: 'POST',
            headers: headers.basic,
            body: JSON.stringify(passwordObj)
        }).then(resp => {
            return handleResponse(resp);
        }).catch(e => {
            
            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    }
};

function handleResponse(resp) {

    if (resp.ok) {
        return resp.json();
    } else {
        return resp.json().then(e =>{
            throw new Error(e);
        });
    }
}

export default userReqHandler;