import React, { Component } from 'react';
import userReqHandler from '../../api/userRemote';
import toastr from 'toastr';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';

class ForgottenPassword extends Component {

    onFormSubmit(values) {
        userReqHandler.sendRecoveryEmail(values).then(res => {

            if (res && res.success) {
                this.props.history.push('/login');
                toastr.success(this.props.t('forgottenPassword.successful'));
            }
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-xs-6">
                        <h2>{t('forgottenPassword.title')}</h2>
                        <p>{t('forgottenPassword.subTitle')}</p>
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={true}
                            initialValues={{
                                email: ''
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string()
                                    .required(t('validationErrors.fieldEmpty'))
                                    .matches(new RegExp(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/), t('validationErrors.emailNotValid'))
                            })}
                            onSubmit={values => this.onFormSubmit(values)}
                        >
                            {
                                formik => (
                                    <form className="form" onSubmit={formik.handleSubmit} >

                                        <div className="form-group">
                                            <label htmlFor="email">{t('forgottenPassword.emailLabel')}</label>
                                            <input
                                                {...formik.getFieldProps('email')}
                                                className="form-control"
                                                placeholder={t('forgottenPassword.fieldPlaceholder')}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <span className="inputErrorText">{formik.errors.email}</span>
                                            ) : null}
                                        </div>

                                        <input type="submit" className="btn btn-primary" value={t('forgottenPassword.submitButton')} />
                                        <p id="forgotDescr">{t('forgottenPassword.clarification')}</p>
                                    </form>
                                )}
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
};

export default withTranslation()(ForgottenPassword);