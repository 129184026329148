import React, { Component, Fragment } from 'react';

class ChildInfoBilingualESL extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        if (property === 'childInfoBilingualESL') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.childInfoBilingualESL[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('childInfoBilingualESL'))}
                                data-key="childInfoBilingualESL"
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('fwisd.childInfoBilingualESL.title')}</h4>
                        {this.props.profile.content && this.props.profile.content.childInfoBilingualESL &&
                            <div className="profileContent">
                                <div>
                                    {this.props.profile.content.childInfoBilingualESL.lepFlag &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('lepFlag'))}
                                                    onClick={() => this.props.eyeClicked('lepFlag')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoBilingualESL.lepFlag')} {this.props.profile.content.childInfoBilingualESL.firstName}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoBilingualESL.texasSchool &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('texasSchool'))}
                                                    onClick={() => this.props.eyeClicked('texasSchool')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoBilingualESL.texasSchool')} {this.props.profile.content.childInfoBilingualESL.lastName}</span>
                                        </Fragment>
                                    }
                                </div>

                                <div>
                                    {this.props.profile.content.childInfoBilingualESL.district &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('district'))}
                                                    onClick={() => this.props.eyeClicked('district')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoBilingualESL.district')} {this.props.profile.content.childInfoBilingualESL.birthDate}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoBilingualESL.nl4flag &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('nl4flag'))}
                                                    onClick={() => this.props.eyeClicked('nl4flag')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoBilingualESL.nl4flag')} {this.props.profile.content.childInfoBilingualESL.localStudentId}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoBilingualESL.subjectiveTeacherEvalForm &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('subjectiveTeacherEvalForm'))}
                                                    onClick={() => this.props.eyeClicked('subjectiveTeacherEvalForm')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoBilingualESL.subjectiveTeacherEvalForm')} {this.props.profile.content.childInfoBilingualESL.tccId}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoBilingualESL.denialDate &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('denialDate'))}
                                                    onClick={() => this.props.eyeClicked('denialDate')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoBilingualESL.denialDate')} {this.props.profile.content.childInfoBilingualESL.denialDate}</span>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
export default ChildInfoBilingualESL;