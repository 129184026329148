import React, { Component, Fragment } from 'react';

class Allergies extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }
    eyeToggle(property) {

        if (property === 'allergies') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.allergies[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div>
                        { this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + ( this.eyeToggle('allergies') )} 
                                onClick={ () => this.props.eyeClicked() }>
                            </i>
                        }
                        <h4 className="headName">{t('myChart.allergies.allergiesTitle')}</h4>
                        { this.props.profile.content &&
                            <div className="profileContent">

                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-3 customLabel">{t('myChart.shared.name')}</div>
                                    <div className="col-md-3 customLabel">{t('myChart.allergies.type')}</div>
                                    <div className="col-md-3 customLabel">{t('myChart.allergies.severity')}</div>
                                    <div className="col-md-2 customLabel">{t('myChart.shared.date')}</div>
                                </div>

                                { this.props.profile.content.allergies &&

                                    this.props.profile.content.allergies.map((allergy, i) => {
                                        return <Fragment key={ i }>
                                            <div className="row trows   ">
                                                <div className="col-md-1">
                                                    { this.props.showVisibilitySettings &&
                                                        <i className={"fa fa-eye " + ( this.eyeToggle( i ) )} 
                                                            onClick={ () => this.props.eyeClicked(i) }>
                                                        </i>
                                                    }
                                                </div>
                                                <div className="col-md-3">{ allergy.name }</div>
                                                <div className="col-md-3">{ allergy.type }</div>
                                                <div className="col-md-3">{ allergy.severity }</div>
                                                <div className="col-md-2">{ allergy.date }</div>
                                            </div>
                                        </Fragment>
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
};

export default Allergies;