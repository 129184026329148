import fetchApi from '../utilities/fetch';

let providerCredentialsReqHandler = {
    
    getProviderCredentialId: (providerId) => {
        return fetchApi.get(`/providerCredentialId?provider=${providerId}`);
    },
    updateProviderCredentials: (credentialsData) => {
        return fetchApi.post('/updateCredentials', credentialsData);
    }
};

export default providerCredentialsReqHandler;