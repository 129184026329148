import React from 'react';

export const StudentInfo = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('educationProviders.studentInfo.title')}</h4>
        <div className='profileContent'>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='studentInfoItemFamilyInfoGridselector'>{t('educationProviders.studentInfo.studentInfoItemMainInfoGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.studentInfoItemMainInfoGridselector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.studentInfoItemMainInfoGridselector && formik.errors.configuration && formik.errors.configuration.studentInfoItemMainInfoGridselector ? (
                            <span className="inputErrorText">{formik.errors.configuration.studentInfoItemMainInfoGridselector}</span>
                        ) : null}
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='studentInfoItemFamilyInfoGridselector'>{t('educationProviders.studentInfo.studentInfoItemFamilyInfoGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.studentInfoItemFamilyInfoGridselector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.studentInfoItemFamilyInfoGridselector && formik.errors.configuration && formik.errors.configuration.studentInfoItemFamilyInfoGridselector ? (
                            <span className="inputErrorText">{formik.errors.configuration.studentInfoItemFamilyInfoGridselector}</span>
                        ) : null}
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='studentInfoItemStudentEntityGridselector'>{t('educationProviders.studentInfo.studentInfoItemStudentEntityGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.studentInfoItemStudentEntityGridselector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.studentInfoItemStudentEntityGridselector && formik.errors.configuration && formik.errors.configuration.studentInfoItemStudentEntityGridselector ? (
                            <span className="inputErrorText">{formik.errors.configuration.studentInfoItemStudentEntityGridselector}</span>
                        ) : null}
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='studentinfoItemStudentInfoGridselector'>{t('educationProviders.studentInfo.studentInfoItemStudentInfoGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.studentinfoItemStudentInfoGridselector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.studentinfoItemStudentInfoGridselector && formik.errors.configuration && formik.errors.configuration.studentinfoItemStudentInfoGridselector ? (
                            <span className="inputErrorText">{formik.errors.configuration.studentinfoItemStudentInfoGridselector}</span>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    </div>

)