import React, { Component, Fragment } from 'react';

class PersonalInfo extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        if (property === 'personalInfo') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.personalInfo[property] ? 'eyeBlack' : 'eyeGray';
        }
    }
    
    render() {
        const { t } =  this.props;
        return (
            <div className="container">
                <div className="row">
                <div>
                    { this.props.profile.content && this.props.showVisibilitySettings &&
                        <i className={"fa fa-eye " + ( this.eyeToggle('personalInfo') )} 
                            onClick={ () => this.props.eyeClicked() }>
                        </i>
                    }
                    <h4 className="headName">{t('myChart.personalInfo.personalInfoTitle')}</h4>
                    { this.props.profile.content &&
                        <div className="profileContent">
                            <div>
                                { this.props.profile.content.personalInfo.firstName &&
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('firstName') )} 
                                                onClick={ () => this.props.eyeClicked('firstName', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.firstName')} { this.props.profile.content.personalInfo.firstName }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.lastName &&
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('lastName') )} 
                                                onClick={ () => this.props.eyeClicked('lastName', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.lastName')} { this.props.profile.content.personalInfo.lastName }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.dateOfBirth && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('dateOfBirth') )} 
                                                onClick={ () => this.props.eyeClicked('dateOfBirth', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.dateOfBirth')} { this.props.profile.content.personalInfo.dateOfBirth }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.race && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('race') )} 
                                                onClick={ () => this.props.eyeClicked('race', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.demographicsRace')} { this.props.profile.content.personalInfo.race }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.medicalRecordNumbers && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('medicalRecordNumbers') )}
                                                onClick={ () => this.props.eyeClicked('medicalRecordNumbers', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.medicalRecordNumber')} { this.props.profile.content.personalInfo.medicalRecordNumbers }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.phoneNumber && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('phoneNumber') )} 
                                                onClick={ () => this.props.eyeClicked('phoneNumber', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.phoneNumber')} { this.props.profile.content.personalInfo.phoneNumber }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.address && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('address') )} 
                                                onClick={ () => this.props.eyeClicked('address', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.address')} { this.props.profile.content.personalInfo.address }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.zipCode && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('zipCode') )} 
                                                onClick={ () => this.props.eyeClicked('zipCode', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.zipCode')} { this.props.profile.content.personalInfo.zipCode }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.primaryProvider && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('primaryProvider') )} 
                                                onClick={ () => this.props.eyeClicked('primaryProvider', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.primaryProvider')} { this.props.profile.content.personalInfo.primaryProvider }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.clinic && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('clinic') )}
                                                onClick={ () => this.props.eyeClicked('clinic', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.clinic')} { this.props.profile.content.personalInfo.clinic }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.emergencyContactName && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('emergencyContactName') )}
                                                onClick={ () => this.props.eyeClicked('emergencyContactName', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.emergencyContactName')} { this.props.profile.content.personalInfo.emergencyContactName }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.emergencyContactPhoneNumber && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('emergencyContactPhoneNumber') )}
                                                onClick={ () => this.props.eyeClicked('emergencyContactPhoneNumber', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.emergencyContactPhoneNumber')} { this.props.profile.content.personalInfo.emergencyContactPhoneNumber }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.insuranceName && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('insuranceName') )}
                                                onClick={ () => this.props.eyeClicked('insuranceName', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.insuranceName')} { this.props.profile.content.personalInfo.insuranceName }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.insuranceType && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('insuranceType') )}insuranceType
                                                onClick={ () => this.props.eyeClicked('insuranceType', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.insuranceType')} { this.props.profile.content.personalInfo.insuranceType }</span>
                                    </Fragment>
                                }
                            </div>
                            <div>
                                { this.props.profile.content.personalInfo.insuranceNumber && 
                                    <Fragment>
                                        { this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + ( this.eyeToggle('insuranceNumber') )}
                                                onClick={ () => this.props.eyeClicked('insuranceNumber', false) }>
                                            </i>
                                        }
                                        <span className="persInfoPtag">{t('myChart.personalInfo.insuranceNumber')} { this.props.profile.content.personalInfo.insuranceNumber }</span>
                                    </Fragment>
                                }
                            </div>
                        </div>
                    }
                </div>
                </div>
            </div>
        );
    };
};

export default PersonalInfo;