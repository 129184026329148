import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import userReqHandler from '../../api/userRemote';
import authentication from '../../utilities/authentication';
import profileReqHandler from '../../api/profileRemote';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import LanguageSelector from './LanguageSelector';
const logoPerson = require('../../person_image.png');
const logo = require('../../Logo2_small.png');


class Header extends Component {

    constructor(props) {
        super(props);
    }

    isPersonMenu() {        
        return this.props.location.pathname !== '/' &&
            this.props.location.pathname !== '/login' &&
            this.props.location.pathname !== '/editPeople' &&
            this.props.location.pathname !== '/providerUpdate' &&
            this.props.location.pathname !== '/admin' &&
            this.props.location.pathname !== '/admin/users' && 
            this.props.location.pathname !== '/admin/allProfiles' &&
            (this.props.location.state ? !this.props.location.state.adminMode : true)
    }

    componentDidMount() {
        
        if (this.isPersonMenu() && this.props.loggedInData.loggedIn && !this.props.peopleList) {
            const loggedData = authentication.loggedData();
            this.props.getPeopleList(loggedData.userId);
        }
    }

    logout() {
        userReqHandler.logout().then(res => {
            if (res && res.success) {
                authentication.logout();
            }
        });
    }

    render() {
        const { t } = this.props;
        let person;        
        if (this.isPersonMenu() && this.props.peopleList && this.props.peopleList.length !== 0) {
            const personId = authentication.getPerson().id;
            person = this.props.peopleList.filter(person => person.id === personId)[0];
        }       
        
        return (
            <nav className="navbar navbar-default">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                            <span className="sr-only">{t("header.toggleNavigation")}</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link className="navbar-brand paddingTop2px" to="/"><img src={logo} /></Link>
                        {this.isPersonMenu() && this.props.peopleList && this.props.peopleList.length !== 0 && this.props.loggedInData.loggedIn &&
                            <Link className="navbar-brand paddingTop2px" to="/dashboard">
                                <img className="headerImage" src={person && person.fileURL ? person.fileURL : logoPerson}
                                    title={person ? person.name : ''} />
                            </Link>
                        }
                    </div>
                    <div id="navbar" className="navbar-collapse collapse">

                        {this.isPersonMenu() && this.props.peopleList && this.props.peopleList.length !== 0 && this.props.loggedInData.loggedIn &&
                            <ul className="nav navbar-nav">
                                <li className="dropdown">
                                    <a href="#"
                                        className="dropdown-toggle"
                                        data-toggle="dropdown"
                                        role="button"
                                        aria-haspopup="true"
                                        aria-expanded="false">{t("header.addProfile")}
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to="/newOwnProfile">{t("profileType.selfProvider")}</Link>
                                        </li>
                                        <li>
                                            <Link to="/newProviderProfile">{t("profileType.fromProviders")}</Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/exportSummary">{t("header.exportSummary")}</Link>
                                </li>
                            </ul>
                        }
                        <ul className="nav navbar-nav navbar-right">
                            {!this.props.loggedInData.loggedIn &&

                                <Fragment>
                                    <li>
                                        <Link to="/login">{t("header.login")}</Link>
                                    </li>
                                    <li>
                                        <Link to="/register">{t("header.createAccount")}</Link>
                                    </li>
                                    <LanguageSelector />
                                </Fragment>
                            }
                            {this.props.loggedInData.loggedIn &&

                                <Fragment>
                                    <li>
                                        <Link to="/consent">{t("header.consent")}</Link>
                                    </li>
                                    <li>
                                        <Link to="/profilesJobs">{t("header.jobs")}</Link>
                                    </li>
                                    {this.props.loggedInData.userType !== 'BASIC' &&
                                        <li className="dropdown">
                                            <a href="#"
                                                className="dropdown-toggle"
                                                data-toggle="dropdown"
                                                role="button"
                                                aria-haspopup="true"
                                                aria-expanded="false">
                                                <i className="fa fa-tools"></i> {t("header.adminPanel")}
                                            </a>
                                            <ul className="dropdown-menu">
                                                {this.props.loggedInData.userType === 'GOD' &&
                                                    <li>
                                                        <Link to='/admin/users'>{t("header.users")}</Link>
                                                    </li>
                                                }
                                                <li>
                                                    <Link to='/admin'>{t("header.providers")}</Link>
                                                </li>
                                                {this.props.loggedInData.userType === 'GOD' &&
                                                    <li>
                                                        <Link to='/admin/allProfiles'>{t("header.allProfilesList")}</Link>
                                                    </li>
                                                }
                                                 {this.props.loggedInData.userType === 'GOD' &&
                                                    <li>
                                                        <Link to='/admin/profileImporter'>{t("header.profileImporter")}</Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    }
                                    <li className="dropdown">
                                        <a href="#"
                                            className="dropdown-toggle"
                                            data-toggle="dropdown"
                                            role="button"
                                            aria-haspopup="true"
                                            aria-expanded="false">
                                            <i className="fa fa-user"></i> {this.props.loggedInData.username}
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link to="/" onClick={this.logout.bind(this)}>{t("header.logout")}</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <LanguageSelector />
                                </Fragment>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        );
    };
};

const mapStateToProps = state => {
    return {
        peopleList: state.profile.profiles
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getPeopleList: (userId) => dispatch(profileReqHandler.getPeopleList(userId))
    }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Header)));