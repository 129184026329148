import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const Gradebooks = ({ formik, t }) => {
    return (
        <div className="tab-warper">
            <h4 className="headName">{t('education.gradebooks.gradebooksTitle')}</h4>
            <FieldArray
                name="gradebooks"
                render={
                    arrayHelper => (
                        <Fragment>
                            <div className="row buttonFromHead">
                                <div className="col-md-6 customLabel">
                                    <button type="button" className="btn" onClick={() => arrayHelper.push({
                                        className: '',
                                        period: '',
                                        teacher: '',
                                        marks: []
                                    })}>
                                        <i className="fa fa-plus"></i> {t('education.gradebooks.addGradebookButton')}
                                    </button>
                                </div>
                            </div>
                            <div className="profileContent">
                                {formik.values.gradebooks.map((gradebook, i) => {
                                    return <Fragment key={i}>
                                        <div className="row">
                                            <div className="col-md-4 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'className-' + i}>{t('education.shared.className')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`gradebooks[${i}].className`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'period-' + i}>{t('education.shared.period')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`gradebooks[${i}].period`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'teacher-' + i}>{t('education.gradebooks.teacher')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`gradebooks[${i}].teacher`)}
                                                    />
                                                </div>
                                            </div>
                                            <FieldArray
                                                name={`gradebooks[${i}].marks`}
                                                render={
                                                    marksArrayHelper => (
                                                        <Fragment>
                                                            <div className="col-md-2">
                                                                <div className="testDetailsDiv">
                                                                    <button type="button" className="btn" onClick={() => marksArrayHelper.push({ mark: '' })}>
                                                                        <i className="fa fa-plus"></i> {t('education.gradebooks.addMarksButton')}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-1">
                                                                <label>{t('education.shared.remove')}</label>
                                                                <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelper.remove(i)} >
                                                                    <span>&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="row">
                                                                {
                                                                    formik.values.gradebooks[i].marks.map((testDetail, j) => {
                                                                        return <Fragment key={j}>
                                                                            <div className="row">
                                                                                <div className="col-md-4 customLabel">
                                                                                    <div className="form-group">
                                                                                        <label htmlFor={'mark-' + j}>{t('education.gradebooks.mark')}</label>
                                                                                        <input
                                                                                            className="form-control"
                                                                                            {...formik.getFieldProps(`gradebooks[${i}].marks[${j}].mark`)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-1">
                                                                                    <label>{t('education.shared.remove')}</label>
                                                                                    <button type="button" className="btn btn-danger editRemove" onClick={() => marksArrayHelper.remove(j)} >
                                                                                        <span>&times;</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </Fragment>
                                                                    })
                                                                }
                                                            </div>
                                                        </Fragment>
                                                    )
                                                }
                                            />
                                        </div>
                                    </Fragment>
                                })}
                            </div>
                        </Fragment>
                    )
                }
            />
        </div>
    );
}