import * as moment from 'moment';
import jobsReqHandler from '../api/jobsRemote';
import profileReqHandler from '../api/profileRemote';
import visitReqHandler from '../api/visitRemote';

export class CheckJobsDone {
    static storageName = 'jobStart'

    static getJobs() {
        const jobStart = localStorage.getItem(this.storageName);

        if (jobStart) {
            return JSON.parse(jobStart);
        }

        return null;
    }

    static setJobs(personId) {
        const jobs = this.getJobs() || {};

        setTimeout(() => {
            jobsReqHandler.getJobs().then(res => {
                if (!res) return;
                res.forEach(job => {
                    if (job.person === personId && job.status === 'In progress...' && job.error.length === 0) {
                        if (jobs[personId]) {
                            const jobsIds = jobs[personId].filter(j => j.id === job.id);

                            if (jobsIds.length === 0) {
                                jobs[personId].push({
                                    id: job.id,
                                    time: moment().utc().valueOf()
                                })
                            }
                        } else {
                            jobs[personId] = [{
                                id: job.id,
                                time: moment().utc().valueOf()
                            }]
                        }
                    }
                })
                localStorage.setItem(this.storageName, JSON.stringify(jobs));
            })
        }, 5000)
    }

    static removeJob(personId, id) {
        const jobs = this.getJobs();

        if (jobs[personId]) {
            const jobsById = jobs[personId].filter(j => j.id === id)[0];
            const index = jobs[personId].indexOf(jobsById);
            jobs[personId].splice(index, 1);

            if (jobs[personId].length === 0) {
                delete jobs[personId];
            }
        }

        localStorage.setItem(this.storageName, JSON.stringify(jobs));
    }

    static check(dispatch) {
        const jobStart = this.getJobs();
        if (jobStart && Object.keys(jobStart).length !== 0) {
            jobsReqHandler.getJobs().then(res => {
                Object.keys(jobStart).forEach(key => {
                    const job = jobStart[key];
                    const jobs = res.filter(j => j.person === key);

                    job.forEach(storedJob => {
                        if (moment().utc().diff(moment(parseInt(storedJob.time)).utc(), 'hours') > 0) {
                            this.removeJob(key, storedJob.id);
                        } else {
                            jobs.some(job => {
                                if (moment().utc().diff(moment(job.time).utc(), 'hours') === 0 && job.status === 'Created' && job.error[0] === '' && storedJob.id === job.id) {
                                    dispatch(profileReqHandler.getProfilesList(job.person));
                                    dispatch(visitReqHandler.getVisitsList(job.person));
                                    this.removeJob(key, storedJob.time);
                                    return true;
                                }
                            })
                        }
                    })
                })
            })
        }
    }

}