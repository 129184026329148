import React from "react";

export const VisitsSelectors = ({ formik, t }) => (
  <div className="tab-warper">
    <h4 className="headName">{t("myChartProviders.visits.title")}</h4>
    <div className="profileContent">
      <div className="row">
        <div className="col-md-6 customLabel">
          <div className="form-group">
            <label htmlFor="visitsUrl">
              {t("myChartProviders.shared.url")}
            </label>
            <input
              className="form-control"
              {...formik.getFieldProps("configuration.visitsUrl")}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 customLabel">
          <div className="form-group">
            <label htmlFor="visitsDocumentSelector">
              {t("myChartProviders.visits.visitsDocumentSelector")}
            </label>
            <input
              className="form-control"
              {...formik.getFieldProps("configuration.visitsDocumentSelector")}
            />
          </div>
        </div>
        <div className="col-md-6 customLabel">
          <div className="form-group">
            <label htmlFor="visitsAllSelector">
              {t("myChartProviders.visits.visitsAllSelector")}
            </label>
            <input
              className="form-control"
              {...formik.getFieldProps("configuration.visitsAllSelector")}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 customLabel">
          <div className="form-group">
            <label htmlFor="visitsPreviewButtonSelector">
              {t("myChartProviders.visits.visitsPreviewButtonSelector")}
            </label>
            <input
              className="form-control"
              {...formik.getFieldProps(
                "configuration.visitsPreviewButtonSelector"
              )}
            />
          </div>
        </div>
        <div className="col-md-6 customLabel">
          <div className="form-group">
            <label htmlFor="visitsSummarySelector">
              {t("myChartProviders.visits.visitsSummarySelector")}
            </label>
            <input
              className="form-control"
              {...formik.getFieldProps("configuration.visitsSummarySelector")}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 customLabel">
          <div className="form-group">
            <label htmlFor="visitsSummaryContentSelector">
              {t("myChartProviders.visits.visitsSummaryContentSelector")}
            </label>
            <input
              className="form-control"
              {...formik.getFieldProps(
                "configuration.visitsSummaryContentSelector"
              )}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);
