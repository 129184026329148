import fetchApi from "../utilities/fetch";
import { push } from "connected-react-router";
import fileTransferReqHandler from "./fileTransferRemote";
import providerCredentialsReqHandler from "./providerCredentialsRemote";

import { profiles, people, visits } from "../actions";

let profileReqHandler = {
  getEducationProfilesList: (userId) => {
    return fetchApi.get(`/educationProfiles?user=${userId}`);
  },

  getPeopleList: (userId) => {
    return (dispatch) => {
      const fetchArray = [];
      return new Promise((resolve, reject) => {
        fetchApi.get(`/people?user=${userId}`).then((response) => {
          if (response && response.length !== 0) {
            response.forEach((people) => {
              if (people.photo && people.photo !== null) {
                fetchArray.push(
                  fetchApi.getFile(`/readFile?id=${people.photo}`, true)
                );
              }
            });

            Promise.all(fetchArray).then(
              (files) => {
                let index = 0;
                response.forEach(
                  (people) => {
                    if (files[index] && people.photo) {
                      const fileUrl = URL.createObjectURL(files[index]);
                      people.fileURL = fileUrl;
                      index++;
                    }
                  },
                  (error) => reject(error)
                );

                dispatch({
                  type: people.GET_PEOPLE,
                  payload: response,
                });

                resolve(true);
              },
              (error) => reject(error)
            );
          }
        });
      });
    };
  },

  addPerson: (personData) => {
    return (dispatch) => {
      return new Promise((resolve) => {
        fetchApi.post("/addPerson", personData).then((response) => {
          if (response.success) {
            dispatch(profileReqHandler.getPeopleList(personData.user)).then(
              (success) => {
                resolve(success);
              }
            );
          }
        });
      });
    };
  },

  updatePerson: (personData) => {
    return (dispatch) => {
      return fetchApi.post("/updatePerson", personData).then((response) => {
        if (response.photo && response.photo !== null) {
          fetchApi
            .getFile(`/readFile?id=${response.photo}`, true)
            .then((file) => {
              if (file) {
                const fileUrl = URL.createObjectURL(file);
                response.fileURL = fileUrl;
              }

              dispatch({
                type: people.EDIT_PERSON,
                payload: response,
              });
            });
        } else {
          dispatch({
            type: people.EDIT_PERSON,
            payload: response,
          });
        }
      });
    };
  },

  deletePerson: (personObj) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        fetchApi.post("/deletePerson", personObj).then((response) => {
          if (response.success) {
            dispatch({
              type: people.DELETE_PERSON,
              payload: response.key.id,
            });

            resolve(response.key.id);
          }
        });
      });
    };
  },

  getProfilesList: (personId) => {
    return (dispatch) => {
      return fetchApi.get(`/profiles?person=${personId}`).then((response) => {
        dispatch({
          type: profiles.GET_PROFILES_LIST,
          payload: {
            id: personId,
            profilesList: response,
          },
        });
      });
    };
  },

  getAllProfiles: (param) => {
    return new Promise((resolve) => {
      fetchApi
        .get(
          encodeURI(
            `/getAllProfiles?pageCursor=${param.endCursor}&searchTags=${param.searchTags}`
          )
        )
        .then((res) => {
          if (res) {
            const result = {
              profiles: res[0],
              endCursor: res[1].endCursor,
              hasMore: res[1].moreResults !== "NO_MORE_RESULTS",
            };

            resolve(result);
          }
        });
    });
  },

  getProfile: (data) => {
    return (dispatch) => {
      fetchApi.get(`/profile?id=${data.profileId}`).then((res) => {
        const responseData = {};
        const otherRequests = [];

        responseData["profile"] = res;

        if (data.providerName !== "self provider") {
          otherRequests.push(
            providerCredentialsReqHandler.getProviderCredentialId(res.provider)
          );
        } else {
          otherRequests.push(null);
        }

        if (res.content.files && res.content.files.length !== 0) {
          let idsString = [];
          res.content.files.forEach((s) => {
            idsString.push(s.id);
          });

          otherRequests.push(
            fileTransferReqHandler.getFileNamesByIds(idsString.join("%2C"))
          );
        } else {
          otherRequests.push(null);
        }

        Promise.all(otherRequests).then((responses) => {
          if (responses[0]) {
            responseData["providerCredentials"] = responses[0];
          }

          if (responses[1]) {
            const files = [];
            res.content.files.forEach((resF) => {
              responses[1].some((file) => {
                if (file.id === resF.id) {
                  files.push(file);
                  return true;
                }
              });
            });

            responseData["files"] = files;
          }
          dispatch({
            type: profiles.GET_PROFILE,
            payload: responseData,
            id: data.profileId,
          });
        });

        if (!otherRequests[0] && !otherRequests[1]) {
          dispatch({
            type: profiles.GET_PROFILE,
            payload: responseData,
            id: data.profileId,
          });
        }
      });
    };
  },
  addProfile: (profileData, formData) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        fetchApi.post("/addProfile", profileData).then((addedProfile) => {
          if (addedProfile && addedProfile.id && formData) {
            formData.append("profileId", addedProfile.id);

            fileTransferReqHandler.fileUpload(formData).then((res) => {
              dispatch(
                profileReqHandler.getProfilesList(profileData.personId.id)
              ).then((res) => {
                resolve(true);
                dispatch(push("/dashboard"));
              });
            });
          } else {
            dispatch(
              profileReqHandler.getProfilesList(profileData.personId.id)
            ).then((res) => {
              resolve(true);
              dispatch(push("/dashboard"));
            });
          }
        });
      });
    };
  },
  addProfileFromProvider: (data) => {
    return fetchApi.post("/addProfileFromProvider", data);
  },
  updateSelfProfile: (updateData, formData) => {
    return (dispatch) => {
      return new Promise((resolve, reject) => {
        fetchApi
          .post("/updateSelfProfile", updateData)
          .then((updatedProfile) => {
            if (updatedProfile && updatedProfile.id && formData) {
              formData.append("profileId", updatedProfile.id);
              fileTransferReqHandler.fileUpload(formData).then((res) => {
                dispatch(
                  profileReqHandler.getProfile({
                    profileId: updatedProfile.id,
                    providerName: "self provider",
                  })
                );
                resolve(true);
                dispatch(push("/dashboard"));
              });
            } else {
              dispatch(
                profileReqHandler.getProfile({
                  profileId: updatedProfile.id,
                  providerName: "self provider",
                })
              );
              resolve(true);
              dispatch(push("/dashboard"));
            }
          });
      });
    };
  },
  updateVendorProfile: (updateData) => {
    return fetchApi.post("/updateVendorProfile", updateData);
  },
  updateVisibilitySchema: (updateData) => {
    return (dispatch) => {
      return fetchApi
        .post("/updateProfileVisibility", updateData)
        .then((response) => {
          if (response) {
            dispatch({
              type: profiles.UPADATE_VISIBILITY_SCHEMA,
              id: updateData.id,
              payload: response.visibilitySchema,
            });
          }
        });
    };
  },
  isProfileUpdatedSaved: (type) => {
    return fetchApi.get(`/checkCrawlStatus?type=${type}`);
  },
  deleteProfile: (profileObj) => {
    return fetchApi.post("/deleteProfile", profileObj);
  },

  summaryProfilesList: (personId) => {
    return new Promise((resolve) => {
      fetchApi.get(`/summaryProfiles?person=${personId}`).then((res) => {
        const resProfiles = res.profiles;
        const result = {
          profiles: resProfiles.profiles,
          // visits: res.visits,
        };
        const filesRequests = [];

        if (resProfiles.profiles.length !== 0) {
          resProfiles.profiles.forEach((profile) => {
            if (profile.files && profile.files.length !== 0) {
              let idsString = [];
              profile.files.forEach((s) => {
                idsString.push(s.id);
              });

              filesRequests.push(
                fileTransferReqHandler.getFileNamesByIds(idsString.join("%2C"))
              );
            }
          });

          if (filesRequests.length !== 0) {
            Promise.all(filesRequests).then((responses) => {
              result.files = responses.map((response) => {
                return {
                  profileFiles: response,
                };
              });

              resolve(result);
            });
          } else {
            resolve(result);
          }
        }
        // if (result.visits.length !== 0) {
        //   result.visits.forEach((visit) => {
        //     fetchApi
        //       .getFile(`/readFile?id=${visit.content}`, true)
        //       .then((file) => {
        //         const reader = new FileReader();

        //         reader.addEventListener("loadend", (e) => {
        //           const content = e.srcElement.result;
        //           visit.content = content;
        //         });

        //         // Start reading the blob as text.
        //         reader.readAsText(file);
        //       });
        //   });
        // }
      });
    });
  },
  summaryVisitsList: (personId) => {
    return new Promise((resolve) => {
      fetchApi.get(`/summaryVisits?person=${personId}`).then((res) => {
        const result = {
          visits: res.visits,
        };
        if (result.visits.length !== 0) {
          let index = 0;
          result.visits.forEach((visit) => {
              fetchApi
                .getFile(`/readFile?id=${visit.content}`, true)
                .then((file) => {
                  const reader = new FileReader();

                  reader.addEventListener("loadend", (e) => {
                    const content = e.srcElement.result;
                    visit.htmlSource = content;
                    if(index === result.visits.length - 1){
                      resolve(result)
                    }
                    index++;
                  });

                  // Start reading the blob as text.
                  reader.readAsText(file);
                })
          });
        }       
      });
    });
  },
  
  summaryProfilesListFromTempLink: (personId) => {
    return new Promise((resolve) => {
      fetchApi.get(`/summaryProfilesFromTempLink?person=${personId}`).then((res) => {
        const resProfiles = res.profiles;
        const result = {
          profiles: resProfiles.profiles,
          // visits: res.visits,
        };
        const filesRequests = [];

        if (resProfiles.profiles.length !== 0) {
          resProfiles.profiles.forEach((profile) => {
            if (profile.files && profile.files.length !== 0) {
              let idsString = [];
              profile.files.forEach((s) => {
                idsString.push(s.id);
              });

              filesRequests.push(
                fileTransferReqHandler.getFileNamesByIds(idsString.join("%2C"))
              );
            }
          });

          if (filesRequests.length !== 0) {
            Promise.all(filesRequests).then((responses) => {
              result.files = responses.map((response) => {
                return {
                  profileFiles: response,
                };
              });

              resolve(result);
            });
          } else {
            resolve(result);
          }
        }
        // if (result.visits.length !== 0) {
        //   result.visits.forEach((visit) => {
        //     fetchApi
        //       .getFile(`/readFile?id=${visit.content}`, true)
        //       .then((file) => {
        //         const reader = new FileReader();

        //         reader.addEventListener("loadend", (e) => {
        //           const content = e.srcElement.result;
        //           visit.content = content;
        //         });

        //         // Start reading the blob as text.
        //         reader.readAsText(file);
        //       });
        //   });
        // }
      });
    });
  },
  summaryVisitsListFromTempLink: (personId) => {
    return new Promise((resolve) => {
      fetchApi.get(`/summaryVisitsFromTempLink?person=${personId}`).then((res) => {
        const result = {
          visits: res.visits,
        };
        if (result.visits.length !== 0) {
          let index = 0;
          result.visits.forEach((visit) => {
              fetchApi
                .getFile(`/readFile?id=${visit.content}`, true)
                .then((file) => {
                  const reader = new FileReader();

                  reader.addEventListener("loadend", (e) => {
                    const content = e.srcElement.result;
                    visit.htmlSource = content;
                    if(index === result.visits.length - 1){
                      resolve(result)
                    }
                    index++;
                  });

                  // Start reading the blob as text.
                  reader.readAsText(file);
                })
          });
        }       
      });
    });
  },
  getPerson: (personId) => {
    return fetchApi.get(`/person?personId=${personId}`);
  },
};

export default profileReqHandler;
