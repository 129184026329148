import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export const FamilyHistory = ({ formik, arrayHelpers }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="row buttonFromHead">
                <div className="col-md-6 customLabel">
                    <button type="button" className="btn" onClick={() => arrayHelpers.push({ comment: '', healthIssue: '', relationship: '' })}>
                        <i className="fa fa-plus"></i> {t('myChart.history.abbFamilyHistoryButton')}
                                                                </button>
                </div>
            </div>
            {formik.values.history.familyHistory && formik.values.history.familyHistory.length !== 0 &&
                formik.values.history.familyHistory.map((family, i) => {
                    return <Fragment key={i}>
                        <div className="row">
                            <div className="col-md-4 customLabel">
                                <div className="form-group">
                                    <label htmlFor={'healthIssue-' + i}>{t('myChart.history.healthIssue')}</label>
                                    <input
                                        id={'healthIssue-' + i}
                                        className="form-control"
                                        {...formik.getFieldProps(`history.familyHistory[${i}].healthIssue`)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-3 customLabel">
                                <div className="form-group">
                                    <label htmlFor={'relationship-' + i}>{t('myChart.history.relationship')}</label>
                                    <input
                                        id={'relationship-' + i}
                                        className="form-control"
                                        {...formik.getFieldProps(`history.familyHistory[${i}].relationship`)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 customLabel">
                                <div className="form-group">
                                    <label htmlFor={'comment-' + i}>{t('myChart.history.comment')}</label>
                                    <input
                                        id={'comment-' + i}
                                        className="form-control"
                                        {...formik.getFieldProps(`history.familyHistory[${i}].comment`)}
                                    />
                                </div>
                            </div>
                            <div className="col-md-1">
                                <label>{t('myChart.shared.remove')}</label>
                                <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelpers.remove(i)} >
                                    <span>&times;</span>
                                </button>
                            </div>
                        </div>
                    </Fragment>
                })
            }
        </Fragment>
    )
}