import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const PersonalInfo = ({ formik, t }) => {
    return (
        <div className="tab-warper">
            <h4 className="headName">{t('education.personalInfo.personalInfoTitle')}</h4>

            <div className="profileContent">

                {/* START ROW */}
                <div className="row">
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="firstName">{t('education.personalInfo.firstName')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.firstName')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="lastName">{t('education.personalInfo.lastName')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.lastName')}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="gender">{t('education.personalInfo.gender')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.gender')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="age">{t('education.personalInfo.age')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.age')}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="address">{t('education.personalInfo.address')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.address')}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="address">{t('education.personalInfo.otherId')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.otherid')}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="language">{t('education.personalInfo.language')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.language')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="graduationyear">{t('education.personalInfo.graduationYear')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.graduationyear')}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="communityservice">{t('education.personalInfo.communityService')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.communityservice')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="second">{t('education.personalInfo.second')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.second')}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="school">{t('education.personalInfo.school')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.school')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="principal">{t('education.personalInfo.principal')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.principal')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="grade">{t('education.personalInfo.grade')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.grade')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="status">{t('education.personalInfo.status')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.status')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="homeRoom">{t('education.personalInfo.homeRoom')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.homeRoom')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="disciplineOfficer">{t('education.personalInfo.disciplineOfficer')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.disciplineOfficer')}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 customLabel">
                        <div className="form-group">
                            <label htmlFor="schoolMail">{t('education.personalInfo.schoolMail')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.schoolMail')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="call">{t('education.personalInfo.call')}</label>
                            <input
                                className="form-control"
                                {...formik.getFieldProps('studentInfo.call')}
                            />
                        </div>
                    </div>
                    <FieldArray
                        name="studentInfo.itemStudentInfo"
                        render={
                            arrayHelpers => (
                                <Fragment>
                                    <div className="row buttonFromHead">
                                        <div className="col-md-6 customLabel">
                                            <button type="button" className="btn" onClick={() => arrayHelpers.push({
                                                emergancecontacts: '',
                                                primaryphone: '',
                                                secondphone: '',
                                                thirdphone: '',
                                                employerphone: '',
                                                homeemail: ''
                                            })}>
                                                <i className="fa fa-plus"></i> {t('education.personalInfo.addEmerganceContactButton')}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="profileContent">
                                        {
                                            formik.values.studentInfo.itemStudentInfo.map((itemStudent, i) => {
                                                return <Fragment key={i}>
                                                    <div className="row">
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'emergancecontacts-' + i}>{t('education.personalInfo.emerganceContacts')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`studentInfo.itemStudentInfo[${i}].emergancecontacts`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'primaryphone-' + i}>{t('education.personalInfo.primaryPhone')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`studentInfo.itemStudentInfo[${i}].primaryphone`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'secondphone-' + i}>{t('education.personalInfo.secondPhone')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`studentInfo.itemStudentInfo[${i}].secondphone`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'thirdphone-' + i}>{t('education.personalInfo.thirdPhone')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`studentInfo.itemStudentInfo[${i}].thirdphone`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'employerphone-' + i}>{t('education.personalInfo.employerPhone')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`studentInfo.itemStudentInfo[${i}].employerphone`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'homeemail-' + i}>{t('education.personalInfo.homeEmail')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`studentInfo.itemStudentInfo[${i}].homeemail`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label>{t('education.shared.remove')}</label>
                                                            <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelpers.remove(i)} >
                                                                <span>&times;</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            })
                                        }
                                    </div>
                                </Fragment>
                            )
                        }
                    />
                </div>
            </div>
        </div>
    )
}