import React, { Fragment } from 'react';

export const ChildInfoAddressAndContacts = ({ profiles, t }) => {

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('fwisd.childInfoAddressAndContacts.title')}</h4>
                <div className="profileContent">
                    {
                        profiles.map((currProfile, i) => {
                            if (currProfile.childInfoAddressAndContacts && currProfile.childInfoAddressAndContacts.length > 0) {
                                return <Fragment key={i}>
                                    <div className="row">
                                        <div className="col-md-6"> </div>
                                        <div className="col-md-3">{currProfile.exportPersonName}</div>
                                        <div className="col-md-3">{currProfile.providerName}</div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-1 customLabel"></div>
                                        <div className="col-md-4 customLabel">{t('fwisd.childInfoAddressAndContacts.mainAddress', true)}</div>
                                        <div className="col-md-4 customLabel">{t('fwisd.childInfoAddressAndContacts.mailingAddress', true)}</div>
                                    </div>
                                    {currProfile.childInfoAddressAndContacts &&
                                        currProfile.childInfoAddressAndContacts.map((item, k) => {
                                            return <div key={k} className="row">
                                                <div className="col-md-4">
                                                    <span>{item.mainAddress}</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <span>{item.mailingAddress}</span>
                                                </div>
                                            </div>
                                        })
                                    }
                                </Fragment>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}