import React from 'react';

const Footer = () => {

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <p id="notFoundBigText">OOPS! - Could not Find it</p>
                    <p id="notFoundBigDigits">404</p>
                </div>
                <div className="col-md-2"></div>
            </div>
        </div>
    );
};

export default Footer;