import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import profileReqHandler from '../../../api/profileRemote';
import providerReqHandler from '../../../api/providerRemote';
import profileHelper from '../Helpers/ProfileHelper';
import toastr from 'toastr';
import authentication from '../../../utilities/authentication';
import { connect } from 'react-redux';
import { FormWrapper } from '../../common/FormWrapper';
import { withTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner'
import {
    PersonalInfo,
    Allergies,
    Diagnosis,
    History,
    PlanOfCare,
    Immunizations,
    Laboratory,
    Medications,
    Files
} from './ProfileForms';

class BaseProfile extends Component {
    profileId;
    formValues;
    formsSubmit = [];

    constructor(props) {
        super(props);

        this.state = {
            isNew: false,
            profileLoaded: false,
            personId: null,
            disabled: false
        };

        this.componentDidMount = this.componentDidMount.bind(this);
        this.disposeHelperObject = this.disposeHelperObject.bind(this);
    }

    componentDidMount() {
        debugger;
        if (this.props.isNew === true) {
            this.setState({ personId: authentication.getPerson() });
            providerReqHandler.getAllProviders().then(res => {
                if (res) {
                    const provider = res.find((pr) => {
                        return pr.name === 'self provider' && pr.providerTypeName === 'mychart';
                    })

                    this.setState({ providerId: provider.id });
                }
            })

            this.formValues = profileHelper.myChart;

            this.setState({ isNew: true, profileLoaded: true });

            return;
        }

        this.profileId = this.props.match.params.id;

        this.formValues = this.props.profile && this.props.profile[this.profileId] ?
            this.props.profile[this.profileId].profile.content :
            profileHelper.myChart;

        this.formValues = {
            ...this.formValues,
            files: [],
            editFiles: this.props.profile[this.profileId].files || []
        }

        this.setState({ profileLoaded: true });
    }

    update(values) {
        const newFiles = [
            ...values.editFiles.map(file => { return { id: file.id } }),
            ...values.files.map(() => { return { id: '' } })
        ]

        const deletedFiles = [];
        values.editFiles.forEach(file => {
            if (file.deleted) {
                deletedFiles.push(file.id);
            }
        })

        const profile = {
            id: this.profileId,
            content: {
                personalInfo: values.personalInfo,
                allergies: values.allergies,
                diagnosis: values.diagnosis,
                history: values.history,
                planOfCare: values.planOfCare,
                immunizations: values.immunizations,
                laboratoryTests: values.laboratoryTests,
                medications: values.medications,
                files: newFiles
            },
            visibilitySchema: this.props.profile[this.profileId].profile.visibilitySchema,
            fileNameChanges: values.editFiles.filter(e => e.changed),
            deletedFiles
        };

        let formData;
        if (values.files && values.files.length !== 0) {
            formData = new FormData();

            values.files.forEach(file => {
                formData.append('avatar', file);
            })
        }

        this.props.updateSelfProfile(profile, formData).then(() => {
            toastr.success(this.props.t('editPeople.profileUpdatedSuccess'));
        })
    }

    addNew(values) {
        let formData;
        const userId = authentication.loggedData().userId;
        const providerId = this.state.providerId;
        const personId = this.state.personId;
        const profile = {
            personalInfo: values.personalInfo,
            allergies: values.allergies,
            diagnosis: values.diagnosis,
            history: values.history,
            planOfCare: values.planOfCare,
            immunizations: values.immunizations,
            laboratoryTests: values.laboratoryTests,
            medications: values.medications,
            files: values.files.map(() => {
                return { id: '' }
            })
        };

        const profileData = {
            userId,
            personId,
            providerId,
            profile
        }

        if (values.files && values.files.length !== 0) {
            formData = new FormData();

            values.files.forEach(file => {
                formData.append('avatar', file);
            })
        }

        this.props.addProfile(profileData, formData).then(() => {
            console.log('asdasdasd')
            toastr.success(this.props.t('addPeople.profileSavedSuccess'));
        });
    }

    onFormSubmit(values) {
        if (this.state.isNew) {
            this.addNew(values);
        }
        else {
            this.update(values);
        }
        this.disposeHelperObject();
    }

    disposeHelperObject() {

        profileHelper.myChart = {
            personalInfo: {
                address: '',
                dateOfBirth: '',
                emergencyContactName: '',
                emergencyContactPhone: '',
                firstName: '',
                insuranceName: '',
                insuranceNumber: '',
                insuranceType: '',
                lastName: '',
                phoneNumber: '',
                race: '',
                zipCode: ''
            },
            allergies: [],
            diagnosis: [],
            planOfCare: {
                planOfCareOverdue: [],
                planOfCareNext: [],
                planOfCareFuture: []
            },
            history: {
                familyHistory: [],
                medicalHistory: [],
                socialHistory: {
                    alcoholUse: {
                        alcoholUse: '',
                        ouncesWeek: ''
                    },
                    smokelessTobacco: {
                        smokelessTobaccoUse: '',
                        smokelessTobaccoTypes: ''
                    },
                    smokingTobacco: {
                        smokingTobaccoUse: '',
                        smokingTobaccoTypes: '',
                        packsDay: '',
                        yearsSmoked: ''
                    }
                },
                surgicalHistory: []
            },
            immunizations: [],
            laboratoryTests: [],
            medications: [],
            files: []
        }

    }

    submitForms() {
        this.setState({ disabled: true })
        this.formsSubmit.forEach(submit => submit());
        setTimeout(() => this.onFormSubmit(this.formValues));
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                {this.state.profileLoaded &&
                    <Fragment>
                        <FormWrapper
                            formValues={this.formValues}
                            onSubmit={values => this.formValues.personalInfo = values.personalInfo}
                            formSubmit={formSubmit => this.formsSubmit.push(formSubmit)}
                            render={
                                formik => <PersonalInfo formik={formik} />
                            }
                        />
                        <FormWrapper
                            formValues={this.formValues}
                            onSubmit={values => this.formValues.allergies = values.allergies}
                            formSubmit={formSubmit => this.formsSubmit.push(formSubmit)}
                            render={
                                formik => <Allergies formik={formik} />
                            }
                        />
                        <FormWrapper
                            formValues={this.formValues}
                            onSubmit={values => this.formValues.diagnosis = values.diagnosis}
                            formSubmit={formSubmit => this.formsSubmit.push(formSubmit)}
                            render={
                                formik => <Diagnosis formik={formik} />
                            }
                        />
                        <FormWrapper
                            formValues={this.formValues}
                            onSubmit={values => this.formValues.history = values.history}
                            formSubmit={formSubmit => this.formsSubmit.push(formSubmit)}
                            render={
                                formik => <History formik={formik} />
                            }
                        />
                        <FormWrapper
                            formValues={this.formValues}
                            onSubmit={values => this.formValues.planOfCare = values.planOfCare}
                            formSubmit={formSubmit => this.formsSubmit.push(formSubmit)}
                            render={
                                formik => <PlanOfCare formik={formik} />
                            }
                        />
                        <FormWrapper
                            formValues={this.formValues}
                            onSubmit={values => this.formValues.immunizations = values.immunizations}
                            formSubmit={formSubmit => this.formsSubmit.push(formSubmit)}
                            render={
                                formik => <Immunizations formik={formik} />
                            }
                        />
                        <FormWrapper
                            formValues={this.formValues}
                            onSubmit={values => this.formValues.laboratoryTests = values.laboratoryTests}
                            formSubmit={formSubmit => this.formsSubmit.push(formSubmit)}
                            render={
                                formik => <Laboratory formik={formik} />
                            }
                        />
                        <FormWrapper
                            formValues={this.formValues}
                            onSubmit={values => this.formValues.medications = values.medications}
                            formSubmit={formSubmit => this.formsSubmit.push(formSubmit)}
                            render={
                                formik => <Medications formik={formik} />
                            }
                        />
                        <FormWrapper
                            formValues={this.formValues}
                            onSubmit={values => this.formValues.files = values.files}
                            formSubmit={formSubmit => this.formsSubmit.push(formSubmit)}
                            render={
                                formik => <Files formik={formik} isNew={this.state.isNew} />
                            }
                        />
                        {this.state.isNew &&
                            <div className="text-right">
                                <button disabled={this.state.disabled} className="btn btn-primary button-update" id="btnSaveCloseFixWidth" onClick={() => this.submitForms()}>
                                    <Loader
                                        type="Oval"
                                        color="#ffffff"
                                        height={20}
                                        width={20}
                                        visible={this.state.disabled}>
                                    </Loader>
                                    {!this.state.disabled && <span>{t('myChart.saveProfileButton')}</span>}
                                </button>
                                <Link to='/' className='btn btn-default btnSaveCloseFixWidth' >{t('myChart.cancelButton')}</Link>
                            </div>
                        }
                        {!this.state.isNew &&
                            <div className="text-right">
                                <button disabled={this.state.disabled} className="btn btn-primary button-update" id="btnSaveCloseFixWidth" onClick={() => this.submitForms()}>
                                    <Loader
                                        type="Oval"
                                        color="#ffffff"
                                        height={20}
                                        width={20}
                                        visible={this.state.disabled}>
                                    </Loader>
                                    {!this.state.disabled && <span>{t('myChart.updateProfileButton')}</span>}
                                </button>
                                <Link to='/' className='btn btn-default btnSaveCloseFixWidth' >{t('myChart.cancelButton')}</Link>
                            </div>
                        }
                        <button type="button" className="btn btn-danger" id="fileSizeBtn" data-toggle="modal" data-target="#fileSizeModal"></button>
                    </Fragment>
                }
            </div>
        )
    };
};

const mapDispatchToProps = dispatch => {
    return {
        addProfile: (profileData, formData) => dispatch(profileReqHandler.addProfile(profileData, formData)),
        updateSelfProfile: (profileData, formData) => dispatch(profileReqHandler.updateSelfProfile(profileData, formData))
    }
}

export default withTranslation()(connect(null, mapDispatchToProps)(BaseProfile));