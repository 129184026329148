import React, { Component } from 'react';
import { Formik } from 'formik';

export class FormWrapper extends Component {
    render() {
        return (
            <Formik
                validateOnChange={false}
                validateOnBlur={true}
                initialValues={this.props.formValues}
                enableReinitialize={true}
                onSubmit={this.props.onSubmit}
            >
                {formik => {
                    this.props.formSubmit(formik.submitForm)
                    return (
                        <form>
                            {this.props.render(formik)}
                        </form>
                    )
                }}
            </Formik>
        )
    }
}