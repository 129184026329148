
import React, { Component, Fragment } from 'react';
import profileReqHandler from '../../api/profileRemote';
import fileTransferReqHandler from '../../api/fileTransferRemote';
import authentication from '../../utilities/authentication';
import toastr from 'toastr';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
const logo = require('../../person_image.png');

class CreatePerson extends Component {

    constructor(props) {
        super(props);

        this.state = {
            person: {
                name: ''
            },
        };
    }

    handleChange(event) {
        const { value } = event.target;
        let newPerson = {
            ...this.state.person,
            name: value
        };


        this.setState({ person: newPerson })
    }

    addPerson(personData) {
        this.props.addPerson(personData).then(success => {
            if (success) {
                toastr.success('Name updated successful.');
                this.props.history.push('/');
            }
        });
    }

    SavePerson() {
        const data = this.state.person;
        const userId = authentication.loggedData().userId;
        const personData = {
            person: {
                name: data.name,
                photo: null
            },
            user: userId
        }

        const formData = this.state.person.formData;

        if (formData) {
            fileTransferReqHandler.photoUpload(formData).then(result => {
                if (result) {
                    personData.person.photo = result.fileName;
                    this.addPerson(personData);
                }
            });
        } else {
            this.addPerson(personData);
        }
    }

    onNewFileChange(event) {

        const file = event.target.files[0];
        const oneMBSize = 10048487;

        this.setState({ tempFileSize: (file.size / 1024 / 1024).toFixed(2) });

        if (file && file.size <= oneMBSize) {

            let formData = new FormData();

            formData.append('avatar', file);

            const person = this.state.person;
            person.fileURL = URL.createObjectURL(file);
            person.formData = formData;

            this.setState({ person: person });
        } else {
            document.getElementById('fileSizeBtn').click();
        }
    }

    render() {
        const { t } = this.props;
        return (

            <Fragment>

                <div className="container">
                    <div className="row">
                        <h1>{t('addPeople.addPeopleTitle')}</h1>

                        <div className='row'>
                            <div className='col-md-1 customLabel'>
                                <div className="input-group-btn">
                                    <label htmlFor={'file'} className="btn btn-default btnaddImageToProfile">
                                        <img className="addImageToProfile" src={this.state.person.fileURL === undefined ? logo : this.state.person.fileURL} />
                                    </label>
                                    <input
                                        id={'file'}
                                        type="file"
                                        name="avatar"
                                        onChange={this.onNewFileChange.bind(this)}
                                    />
                                </div>
                            </div>
                            <div className='col-md-6 customLabel'>
                                <div className='form-group'>
                                    <label htmlFor='name'>{t('addPeople.nameField')}</label>
                                    <input
                                        id='name'
                                        className='form-control'
                                        type='text'
                                        name='name'
                                        onChange={(event) => this.handleChange(event)}
                                        required
                                        value={this.state.person.name}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="text-right">
                            <input type="submit" className="btn btn-primary" id="btnSaveProfile" value={t('addPeople.saveButton')} onClick={() => this.SavePerson()} />
                            <Link to='/' className='btn btn-default btnSaveClose' >{t('addPeople.cencelButton')}</Link>
                        </div>
                    </div>

                </div>
            </Fragment>
        );
    };
}

const mapDispatchToProps = dispatch => {
    return {
        addPerson: personData => dispatch(profileReqHandler.addPerson(personData))
    }
}

export default withTranslation()(connect(null, mapDispatchToProps)(CreatePerson));