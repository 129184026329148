import React, { Component, Fragment } from 'react';
import fileTransferReqHandler from '../../../api/fileTransferRemote';
import PdfViewer from '../../PdfViewer';
import fileTypes from '../../../utilities/fileTypes';

class Files extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openFileType: '',
            openImg: null,
            openPdf: null,
            openUnknown: null
        };

        this.eyeToggle = this.eyeToggle.bind(this);
    }


    openFile(fileId, fileType) {

        this.setState({ openFileType: fileType });
        this.setState({ openImg: null });

        fileTransferReqHandler.openFile(fileId).then(res => {

            const fileUrl = URL.createObjectURL(res);

            switch (fileType) {

                case 'image/jpeg':
                case 'image/png':
                    this.setState({ openImg: fileUrl });
                    break;
                case 'application/pdf':
                    this.setState({ openPdf: fileUrl });
                    break;
                default: break;
            }
        });
    }

    saveFile(fileId, filename, index) {

        fileTransferReqHandler.openFile(fileId).then(res => {

            const fileUrl = URL.createObjectURL(res);

            const downloadLink = document.createElement("a");
            downloadLink.setAttribute('href', fileUrl);
            downloadLink.setAttribute('target', '_self');
            downloadLink.setAttribute('download', filename);
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        });
    }

    eyeToggle(property) {

        if (property === 'files') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.files && this.props.visibilitySchema.files[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('files'))}
                                onClick={ () => this.props.eyeClicked() }>
                            </i>
                        }
                        <h4 className="headName">{t('myChart.files.filesTitle')}</h4>
                        {this.props.profile.content &&
                            <div className="profileContent">

                                <div className="row">
                                    <div className="col-md-1 customLabel"></div>
                                    <div className="col-md-4 customLabel">{t('myChart.files.fileName')}</div>
                                    <div className="col-md-3 customLabel">{t('myChart.files.type')}</div>
                                    <div className="col-md-2 customLabel">{t('myChart.files.action')}</div>
                                </div>

                                {this.props.files && this.props.files.length > 0 &&

                                    this.props.files.map((file, i) => {
                                        return <Fragment key={i}>

                                            <div className="row fileList">

                                                <div className="col-md-1">
                                                    {this.props.showVisibilitySettings &&
                                                        <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                            onClick={ () => this.props.eyeClicked(i) }>
                                                        </i>
                                                    }
                                                </div>

                                                <div className="col-md-4">
                                                    <span>{file.filename}</span>
                                                </div>

                                                <div className="col-md-3">
                                                    <span>{fileTypes.formatFileTypes(file.mimetype)}</span>
                                                </div>

                                                <div className="col-md-2">
                                                    {!fileTypes.unreadableFormat(file.mimetype) &&
                                                        <button
                                                            type="button"
                                                            className="btn btn-link editRemove textLeft paddingLeft4px"
                                                            data-toggle="modal"
                                                            data-target="#fileModal"
                                                            onClick={this.openFile.bind(this, file.id, file.mimetype)}
                                                        >{t('myChart.files.open')}</button>
                                                    }

                                                    {fileTypes.unreadableFormat(file.mimetype) &&
                                                        <button
                                                            type="button"
                                                            className="btn btn-link editRemove textLeft paddingLeft4px"
                                                            onClick={this.saveFile.bind(this, file.id, file.filename, i)}
                                                        >{t('myChart.files.save')}</button>
                                                    }
                                                </div>

                                            </div>
                                        </Fragment>
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
                {/* <!-- Open File Modal --> */}
                <div className="modal fade" id="fileModal" tabIndex="-1" role="dialog" aria-labelledby="myFileModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                {this.state.openFileType === 'application/pdf' &&
                                    <PdfViewer file={this.state.openPdf}></PdfViewer>
                                }
                                {this.state.openFileType.startsWith('image') &&
                                    // <object data={ this.state.openImg } type="image/jpg">
                                    //     <embed src={ this.state.openImg } type="image/jpg" />
                                    // </object>
                                    <img src={this.state.openImg} id="imagePreview" />
                                }

                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Files;