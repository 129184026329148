import React from 'react';

export const HealthIssues = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('educationProviders.healthInfo.title')}</h4>
        <div className='profileContent'>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfoLinkMenuItems'>{t('educationProviders.healthInfo.healthInfoLinkMenu')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfoLinkMenuItems')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.healthInfoLinkMenuItems && formik.errors.configuration && formik.errors.configuration.healthInfoLinkMenuItems ? (
                            <span className="inputErrorText">{formik.errors.configuration.healthInfoLinkMenuItems}</span>
                        ) : null}
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfoLinkSubMenuItems'>{t('educationProviders.healthInfo.healthInfoLinkSubMenu')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfoLinkSubMenuItems')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfoMenuItems'>{t('educationProviders.healthInfo.menuItems')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfoMenuItems')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfoNumberOfMenus'>{t('educationProviders.healthInfo.menusCount')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfoNumberOfMenus')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfochildhoodIllnessesGridSelector'>{t('educationProviders.healthInfo.childhoodIllnessesGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfochildhoodIllnessesGridSelector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfoDiabeteScareLogGridSelector'>{t('educationProviders.healthInfo.diabeteScarelogGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfoDiabeteScareLogGridSelector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfoHealthConditionsGridSelector'>{t('educationProviders.healthInfo.healthConditionsGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfoHealthConditionsGridSelector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfoMedicationsGridselector'>{t('educationProviders.healthInfo.medicationsGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfoMedicationsGridselector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfoOfficeVisitsGridselector'>{t('educationProviders.healthInfo.officeVisitsGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfoOfficeVisitsGridselector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfoTestsGridSelector'>{t('educationProviders.healthInfo.testsGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfoTestsGridSelector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInfoVaccinationsGridselector'>{t('educationProviders.healthInfo.vaccinationsGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInfoVaccinationsGridselector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthIHPFormsGridselector'>{t('educationProviders.healthInfo.ihpFormsGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthIHPFormsGridselector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthPhysicalExamsGridselector'>{t('educationProviders.healthInfo.physicalExamsGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthPhysicalExamsGridselector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthInjuriesGridselector'>{t('educationProviders.healthInfo.injuriesGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthInjuriesGridselector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthDisabilitiesGridselector'>{t('educationProviders.healthInfo.disabilitiesGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthDisabilitiesGridselector')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
)