import React, { Fragment } from 'react';

export const Attendance = ({ profiles, t }) => {

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('education.attendances.attendancesTitle')}</h4>
                <div className="profileContent">
                    {
                        profiles.map((currProfile, i) => {
                            if (currProfile.attendances && currProfile.attendances.length > 0) {
                                return <Fragment key={i}>
                                    <div className="row">
                                        <div className="col-md-6"> </div>
                                        <div className="col-md-3">{currProfile.exportPersonName}</div>
                                        <div className="col-md-3">{currProfile.providerName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-2 customLabel">{t('education.shared.date', true)}</div>
                                        <div className="col-md-4 customLabel">{t('education.attendances.attendance', true)}</div>
                                        <div className="col-md-1 customLabel">{t('education.shared.period', true)}</div>
                                        <div className="col-md-3 customLabel">{t('education.shared.className', true)}</div>
                                    </div>
                                    {currProfile.attendances &&
                                        currProfile.attendances.map((item, k) => {
                                            return <div key={k} className="row fileList">
                                                <div className="col-md-2">
                                                    <span>{item.date}</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <span>{item.attendance}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.period}</span>
                                                </div>
                                                <div className="col-md-3">
                                                    <span>{item.className}</span>
                                                </div>
                                            </div>
                                        })
                                    }
                                </Fragment>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}