import React, { Fragment } from 'react';

export const AssignmentsAndGrades = ({ profiles, t }) => {

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('fwisd.assignmentsAndGrades.title')}</h4>
                <div className="profileContent">
                    {
                        profiles.map((currProfile, i) => {

                            if (currProfile.assignmentsAndGrades && currProfile.assignmentsAndGrades.length > 0) {
                                return <Fragment key={i}>

                                    {currProfile.assignmentsAndGrades &&
                                        currProfile.assignmentsAndGrades.map((assignment, i) => {
                                            return <Fragment key={i}>
                                                {/* <div className="row trows">
                                                    <div className="col-md-1 labTestRowItem periodLabel" data-toggle="collapse" data-target={'#assignment' + i}>{assignment.assignmentName}
                                                        <span class="glyphicon glyphicon-chevron-down customSpanExpander" />
                                                    </div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-md-6">{assignment.assignmentName}</div>
                                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                                    <div className="col-md-3">{currProfile.providerName}</div>
                                                </div>
                                                <div id={'assignment' + i} className="row">

                                                    <div className="row">
                                                        <div className="col-md-2 customLabel">{t('fwisd.assignmentsAndGrades.assigment', true)}</div>
                                                        <div className="col-md-1 customLabel">{t('fwisd.assignmentsAndGrades.points', true)}</div>
                                                        <div className="col-md-1 customLabel">{t('fwisd.assignmentsAndGrades.grade', true)}</div>
                                                        <div className="col-md-2 customLabel">{t('fwisd.assignmentsAndGrades.assigned', true)}</div>
                                                        <div className="col-md-2 customLabel">{t('fwisd.assignmentsAndGrades.due', true)}</div>
                                                        <div className="col-md-3 customLabel">{t('fwisd.assignmentsAndGrades.category', true)}</div>
                                                    </div>

                                                    {assignment && assignment.assignmentDetails.map((item, i) => {
                                                        return <Fragment key={i}>
                                                            <div className="row fileList">
                                                                <div className="col-md-2">
                                                                    <span>{item.assigment}</span>
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <span>{item.points}</span>
                                                                </div>
                                                                <div className="col-md-1">
                                                                    <span>{item.grade}</span>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <span>{item.assigned}</span>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <span>{item.due}</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span>{item.category}</span>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    })
                                                    }

                                                </div>
                                                <br />
                                                <br />
                                            </Fragment>
                                        })
                                    }
                                </Fragment>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}