import React, { Component } from 'react';
import profileReqHandler from '../../api/profileRemote';
import fileTransferReqHandler from '../../api/fileTransferRemote';
import { connect } from 'react-redux';
import authentication from '../../utilities/authentication';
import toastr from 'toastr';
import { withTranslation } from 'react-i18next';

const logo = require('../../person_image.png');

class EditPeople extends Component {

    userId;

    constructor(props) {
        super(props);

        this.state = {
            people: [],
            fields: {
                name: ''
            },
            errors: {},
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.people && state.people.length === 0) {
            return {
                people: props.peopleList || []
            }
        }

        return null;
    }

    componentDidMount() {
        const loggedData = authentication.loggedData();
        this.userId = loggedData.userId;

        if (!this.props.peopleList) {
            this.props.getPeopleList(loggedData.userId);
        }
    }

    updatePersonValue(personId, field, value) {
        const peopleList = this.state.people.map(p => {
            if (p.id === personId) {
                return {
                    ...p,
                    [field]: value
                }
            }

            return p;
        });

        return peopleList;
    }

    updatePersonRequest(personData) {
        this.props.updatePerson(personData).then(res => {
            toastr.success('Name updated successful.');
        });
    }

    onChangeHandler(event, personId) {
        const name = event.target.value;
        const peopleList = this.state.people.map(p => {
            if (p.id === personId) {
                return {
                    ...p,
                    personName: name
                }
            }

            return p;
        });


        this.setState({
            people: peopleList
        })
    }

    updatePerson(arrayIndex, personId) {
        const personData = {
            id: personId,
            name: this.state.people[arrayIndex].personName
        };

        if (this.state.people[arrayIndex].formData) {
            const formData = this.state.people[arrayIndex].formData;

            fileTransferReqHandler.photoUpload(formData).then(result => {

                if (result && result.fileName !== null) {

                    personData.photo = result.fileName;

                    this.updatePersonRequest(personData);
                }
            });
        } else {
            this.updatePersonRequest(personData);
        }
    }

    onNewFileChange(event, personId) {

        const file = event.target.files[0];
        const oneMBSize = 10048487;

        this.setState({ tempFileSize: (file.size / 1024 / 1024).toFixed(2) });

        if (file && file.size <= oneMBSize) {

            let formData = new FormData();

            formData.append('avatar', file);

            const peopleList = this.state.people.map(p => {
                if (p.id === personId) {
                    return {
                        ...p,
                        fileURL: URL.createObjectURL(file),
                        formData
                    }
                }

                return p;
            });

            this.setState({
                people: peopleList
            })
        } else {
            document.getElementById('fileSizeBtn').click();
        }
    }

    deletePerson(personId) {

        const profileObj = { personId: personId };
        this.props.deletePerson(profileObj).then(deletedId => {
            const profiles = this.state.people.filter(p => p.id !== deletedId.toString());

            this.setState({
                people: profiles
            })
        });

    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <h1>{t('editPeople.editPeopleTitle')}</h1>

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="customTable50px">{t('editPeople.pictureColumn')}</th>
                                        <th className="customTableAuto">{t('editPeople.nameColumn')}</th>
                                        <th className="customTableTd">{t('editPeople.actionsColumn')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.people && this.state.people.length > 0 &&
                                        this.state.people.map((person, i) => {
                                            return <tr key={i} className="customTableRow">
                                                <td className="customTable50px">
                                                    <div className="input-group-btn">
                                                        <label htmlFor={'file-' + i} className="btn btn-default btnaddImageToProfile">
                                                            <img className="addImageToProfile" src={person.fileURL === undefined ? logo : person.fileURL} />
                                                        </label>
                                                        <input
                                                            id={'file-' + i}
                                                            type="file"
                                                            name="avatar"
                                                            onChange={event => { this.onNewFileChange(event, person.id) }}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="customTableAuto">

                                                    <input
                                                        ref={'personName-' + i}
                                                        id={'personName-' + i}
                                                        type="text"
                                                        className="form-control personEditInput"
                                                        name="personName"
                                                        value={person.personName}

                                                        onChange={(event) => {
                                                            this.onChangeHandler(event, person.id);
                                                        }}
                                                    />
                                                    <span className="inputErrorText">{this.state.errors[i]}</span>
                                                </td>
                                                <td className="customTableTd">
                                                    <div className="text-left">
                                                        <button
                                                            id="btnUpdate"
                                                            type="button"
                                                            className="btn btn-default personEditInput"
                                                            onClick={this.updatePerson.bind(this, i, person.id)}
                                                        >{t('editPeople.updateAction')}</button>
                                                        <span> </span>
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btnUpdate personEditInput"
                                                            data-toggle="modal"
                                                            onClick={this.deletePerson.bind(this, person.id)}
                                                            data-target="#deletePersonModal">{t('editPeople.deleteAction')}</button>
                                                    </div>

                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>

                            <button type="button" className="btn btn-danger" id="fileSizeBtn" data-toggle="modal" data-target="#fileSizeModal"></button>
                            {/* <!-- Open File Modal --> */}
                            <div className="modal fade" id="fileModal" tabIndex="-1" role="dialog" aria-labelledby="myFileModalLabel">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                        </div>
                                        <div className="modal-body">

                                            <img src={this.state.openImg} id="imagePreview" />


                                        </div>
                                        <div className="modal-footer">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    };
}

const mapStateToProps = state => {
    return {
        peopleList: state.profile.profiles
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPeopleList: (userId) => dispatch(profileReqHandler.getPeopleList(userId)),
        updatePerson: personData => dispatch(profileReqHandler.updatePerson(personData)),
        deletePerson: profileObj => dispatch(profileReqHandler.deletePerson(profileObj))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(EditPeople));