import React from 'react';

export const LaboratoryTests = ({ profiles, t }) => {

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('myChart.laboratory.laboratoryTitle')}</h4>

                <div className="profileContent">
                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.shared.name', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return currProfile.laboratoryTests.map((test, j) => {
                                return <div key={j} className="row">
                                    <div className="col-md-6">{test.name}</div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>

                                    <div className="row sub-lab-tab-warper">
                                        <div className="row">
                                            <div className="col-md-6 customLabel">{t('myChart.shared.date', true)}</div>
                                            <div className="col-md-6 customLabel">{t('myChart.laboratory.orderBy', true)}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">{test.date}</div>
                                            <div className="col-md-6">{test.orderBy}</div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 customLabel">{t('myChart.laboratory.studyResult', true)}</div>
                                            <div className="col-md-6"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">{test.studyResult}</div>
                                        </div>
                                        {
                                            test.details.map((detail, ind) => {
                                                return <div key={ind}>
                                                    {ind === 0 &&
                                                        <div className="row marginTop25">
                                                            <div className="col-md-4 customLabel">{t('myChart.laboratory.component', true)}</div>
                                                            <div className="col-md-4 customLabel">{t('myChart.laboratory.yourValue', true)}</div>
                                                            <div className="col-md-4 customLabel">{t('myChart.laboratory.standardRange', true)}</div>
                                                        </div>
                                                    }
                                                    <div className="row">
                                                        <div className="col-md-4">{detail.component}</div>
                                                        <div className="col-md-4">{detail.value}</div>
                                                        <div className="col-md-4">{detail.range}</div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        })
                    }
                </div>
            </div>
        </div>
    )
}