import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    GlobalSelectors,
    GradebookSelectors,
    HealthIssues,
    AttendanceSelectors,
    StudentInfo
} from './EducationProviderForms';


class EducationProviderConfig extends Component {
    initValues = {
        name: '',
        providerType: '',
        providerTypeName: '',
        id: '',
        configuration: {
            loginUrl: '',

            selectorUsername: '',
            selectorPassword: '',
            selectorSubmitBtn: '',

            loginErrorSelector: '',

            //gradebook
            leftGridSelector: '',
            leftGridCellSelector: '',
            marksGridSelector: '',
            marksGridColSelector: '',

            //healthinfo
            healthInfoLinkMenuItems: '',
            healthInfoLinkSubMenuItems: '',
            healthInfoMenuItems: [],
            healthInfoNumberOfMenus: 0,

            disciplineMainGrid: '',


            healthInfochildhoodIllnessesGridSelector: '',
            healthInfoDiabeteScareLogGridSelector: '',
            healthInfoHealthConditionsGridSelector: '',
            healthInfoMedicationsGridselector: '',
            healthInfoOfficeVisitsGridselector: '',
            healthInfoTestsGridSelector: '',
            healthInfoVaccinationsGridselector: '',
            healthPhysicalExamsGridselector: '',
            healthInjuriesGridselector: '',
            healthIHPFormsGridselector: '',
            healthDisabilitiesGridselector: '',

            //attendance
            attendanceGridSelector: '',
            attendanceGridCellSelector: '',

            //studentinfo
            studentInfoselector: '',
            studentInfoItemMainInfoGridselector: '',
            studentinfoItemStudentInfoGridselector: '',
            studentInfoItemStudentEntityGridselector: '',
            studentInfoItemFamilyInfoGridselector: '',


            selectorLogOut: '',

        },
    }

    constructor(props) {
        super(props);
        this.state = {
            notName: 'true',
        }
    }

    componentDidMount() {
        if (this.props.provider.id) {
            this.setState({
                notName: false
            });
        }
    }

    handleSubmitForm = (values) => {
        Object.keys(values.configuration).map((key) => {
            if (values.configuration[key] === '') {
                values.configuration[key] = null;
            }
        });

        if (values.name === '') {
            values.name = null;
        }

        if (values.providerType === '') {
            values.providerType = null;
        }

        if (values.providerTypeName === '') {
            values.providerTypeName = null;
        }

        if (values.id === '') {
            values.id = null;
        }

        this.props.handleSubmit(values);
    }


    render() {
        const initValue = { ...this.initValues, ...this.props.provider };
        const { t } = this.props;
        return (
            <Formik
                validateOnChange={false}
                validateOnBlur={true}
                initialValues={initValue}
                enableReinitialize={true}
                validationSchema={Yup.object({
                    configuration: Yup.object({
                        loginUrl: Yup.string()
                            .required(t('validationErrors.fieldEmpty'))
                            .matches(new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/), t('validationErrors.invalidField')),
                        studentInfoSelector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        leftGridSelector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        leftGridCellSelector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        marksGridSelector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        marksGridColSelector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        healthInfoLinkMenuItems: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        attendanceGridSelector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        attendanceGridCellSelector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        studentInfoItemMainInfoGridselector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        studentInfoItemFamilyInfoGridselector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        studentInfoItemStudentEntityGridselector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                        studentinfoItemStudentInfoGridselector: Yup.string()
                            .required(t('validationErrors.fieldEmpty')),
                    })
                })}
                onSubmit={values => this.handleSubmitForm(values)}
            >
                {formik => (
                    <form className='create-profile-form' onSubmit={formik.handleSubmit}>

                        {this.state.notName &&

                            <div className='tab-warper'>
                                <h4 className='headName'>{t('educationProviders.title')}</h4>
                                <div className='profileContent'>

                                    <div className='row'>
                                        <div className='col-md-6 customLabel'>
                                            <div className='form-group'>
                                                <label htmlFor='nameProvider'>{t('educationProviders.name')}</label>
                                                <input
                                                    className='form-control'
                                                    {...formik.getFieldProps('name')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <GlobalSelectors formik={formik} t={t} />
                        <GradebookSelectors formik={formik} t={t} />
                        <HealthIssues formik={formik} t={t} />
                        <AttendanceSelectors formik={formik} t={t} />
                        <StudentInfo formik={formik} t={t} />
                        <div className="text-right">
                            <input type="submit" className="btn btn-primary btnSaveClose" value="Save provider" />
                            <Link to='/admin' className='btn btn-default btnSaveClose' >Cancel</Link>
                        </div>
                    </form>
                )}
            </Formik>
        )
    }
}

export default EducationProviderConfig;