import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { Switch, Route } from 'react-router-dom';
import { withRouter } from "react-router";
import PrivateRoute from '../common/PrivateRoute';
import GodRoute from './GodRoute';
import AdminRoute from './AdminRoute';
import Register from '../authentication/Register';
import Login from '../authentication/Login';
import Dashboard from '../dashboard/Dashboard';
import ProfileTab from '../dashboard/ProfileTab';
// import CreateProfileMyChart from '../profile/MyChart/CreateProfile';
import CreateProfile from '../profile/PersonalProfile';

import EditPersonalProfile from '../profile/EditPersonalProfile';
import EditProfileMyChart from '../profile/MyChart/EditProfile';
import BaseProfileMyChart from '../profile/MyChart/BaseProfile';
import CreateProviderProfile from '../profile/CreateProviderProfile';
import Summary from '../summary/Summary';
import Admin from '../admin/Admin';
import ProfileImporter from '../admin/ProfileImporter';
import AllProfiles from '../admin/AllProfilesList';
import ProviderCreate from '../admin/ProviderCreate';
import ProviderUpdate from '../admin/ProviderUpdate';
import AdminUsers from '../admin/AdminUsers';
import ForgottenPassword from '../authentication/ForgottenPassword';
import ResetPassword from '../authentication/ResetPassword';
import NotFound404 from '../common/NotFound404';
import ProfileJobs from '../profile/ProfileJobs';
import Consent from '../profile/Consent';
import People from '../dashboard/People';
import CreatePerson from '../dashboard/CreatePerson';
import EditPeople from '../dashboard/EditPeople';
import { CheckJobsDone } from '../../utilities/checkJobsDone';
const Routes = ({ history }) => {

    let dispatch = useDispatch();

    useEffect(() => {
        const listen = history.listen(() => {
            CheckJobsDone.check(dispatch);
        });

        return () => {
            listen();
        } 
    })

    return (
        <Switch>
            <PrivateRoute path="/" exact component={People}></PrivateRoute>
            <Route path="/login" component={Login}></Route>
            <Route path="/register" component={Register}></Route>
            <Route path="/user/forgottenPassword" component={ForgottenPassword}></Route>
            <Route path="/user/reset/:token" component={ResetPassword}></Route>
            <PrivateRoute path="/newPerson" component={CreatePerson}></PrivateRoute>
            <PrivateRoute path="/newOwnProfile" component={CreateProfile}></PrivateRoute>
            <PrivateRoute path="/profileDashboard" component={ProfileTab}></PrivateRoute>
            
            <PrivateRoute path="/editProfile/:id" component={EditProfileMyChart}></PrivateRoute>
            <PrivateRoute path="/editPeople" component={EditPeople}></PrivateRoute>
            <PrivateRoute path="/editPersonalProfile/:id" component={EditPersonalProfile}></PrivateRoute>
            <PrivateRoute path="/newProviderProfile" component={CreateProviderProfile}></PrivateRoute>
            {/* <PrivateRoute path="/baseProfileMyChart" component={ BaseProfileMyChart }></PrivateRoute> */}
            <PrivateRoute path="/exportSummary" component={Summary}></PrivateRoute>
            <PrivateRoute path="/profilesJobs" component={ProfileJobs}></PrivateRoute>
            <PrivateRoute path="/consent" component={Consent}></PrivateRoute>
            <PrivateRoute path="/dashboard" component={Dashboard}></PrivateRoute>
            <AdminRoute path="/admin/providerUpdate/:typeName/:id" component={ProviderUpdate}></AdminRoute>
            <AdminRoute path="/admin/providerCreate/:typeName/:typeId" component={ProviderCreate}></AdminRoute>
            <GodRoute path="/admin/users" component={AdminUsers}></GodRoute>
            <GodRoute path="/admin/allProfiles" component={AllProfiles}></GodRoute>
            <GodRoute path="/admin/ProfileImporter" component={ProfileImporter}></GodRoute>
            <AdminRoute path="/admin" component={Admin}></AdminRoute>
            <Route path="*" component={NotFound404} />
        </Switch>
    );
}

export default withRouter(Routes);