import fetchApi from '../utilities/fetch';

let jobsReqHandler = {

    getJobs: () => {
        return fetchApi.get('/profilesJobs');
    },
    deleteJob: (jobObj) => {
        return fetchApi.post('/deleteJob', jobObj);
    }
};

export default jobsReqHandler;