import React, { Component, Fragment } from 'react';

class Grades extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        // const filedData = property.split('-');
        // const fieldName = filedData[0];
        // const fieldId = parseInt(filedData[1]);

        if (property === 'grades') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.grades[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('grades'))}
                                data-key="grades"
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('fwisd.grades.title')}</h4>
                        {this.props.profile.content &&
                            <div className="profileContent">

                                <div className="row">
                                    <div className="col-md-1 customLabel"></div>
                                    <div className="col-md-1 customLabel">{t('fwisd.grades.year', true)}</div>
                                    <div className="col-md-2 customLabel">{t('fwisd.grades.markingPeriod', true)}</div>
                                    <div className="col-md-3 customLabel">{t('fwisd.grades.course', true)}</div>
                                    <div className="col-md-2 customLabel">{t('fwisd.grades.courseNumber', true)}</div>
                                    <div className="col-md-1 customLabel">{t('fwisd.grades.percent', true)}</div>
                                    <div className="col-md-2 customLabel">{t('fwisd.grades.grade', true)}</div>
                                </div>

                                {this.props.profile.content.grades &&
                                    this.props.profile.content.grades.map((item, i) => {
                                        return <Fragment key={i}>
                                            <div className="row fileList">
                                                <div className="col-md-1">
                                                    {this.props.showVisibilitySettings &&
                                                        <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                            onClick={() => this.props.eyeClicked(i)}>
                                                        </i>
                                                    }
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.year}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.markingPeriodId}</span>
                                                </div>
                                                <div className="col-md-3">
                                                    <span>{item.course}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.courseNum}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.percentGrade}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.gradeTitle}</span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
export default Grades;