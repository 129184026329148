import React from 'react';
import { FieldArray } from 'formik';
import { PlanOfCareOverdue } from './PlanOfCareOverdue';
import { PlanOfCareNext } from './PlanOfCareNext';
import { PlanOfCareFuture } from './PlanOfCareFuture';
import { useTranslation } from 'react-i18next';

export const PlanOfCare = ({ formik }) => {
    const { t } = useTranslation();
    
    return (
        <div className="tab-warper">
            <h4 className="headName">{t('myChart.planOfCare.planOfCareTitle')}</h4>
            <FieldArray
                name="planOfCare.planOfCareOverdue"
                render={arrayHelpers => <PlanOfCareOverdue formik={formik} arrayHelpers={arrayHelpers} />}
            />
            <FieldArray
                name="planOfCare.planOfCareNext"
                render={arrayHelpers => <PlanOfCareNext formik={formik} arrayHelpers={arrayHelpers} />}
            />
             <FieldArray
                name="planOfCare.planOfCareFuture"
                render={arrayHelpers => <PlanOfCareFuture formik={formik} arrayHelpers={arrayHelpers} />}
            />
        </div>
        
    );
}