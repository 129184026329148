import React, { Fragment } from 'react';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

export const Medications = ({ formik }) => {
    const { t } = useTranslation();
    return (
        <div className="tab-warper">
            <h4 className="headName">{t('myChart.medications.medicationsTitle')}</h4>
            <FieldArray
                name="medications"
                render={arrayHelpers => (
                    <Fragment>
                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayHelpers.push({
                                    name: '',
                                    code: '',
                                    dosage: '',
                                    date: ''
                                })}>
                                    <i className="fa fa-plus"></i> {t('myChart.medications.addMedicationButton')}
                        </button>
                            </div>
                        </div>
                        <div className="profileContent">
                            {
                                formik.values.medications && formik.values.medications.length !== 0 &&
                                formik.values.medications.map((medication, i) => {
                                    return <Fragment key={i}>
                                        <div className="row">
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'name-' + i}>{t('myChart.shared.name')}</label>
                                                    <input
                                                        id={'name-' + i}
                                                        className="form-control"
                                                        {...formik.getFieldProps(`medications[${i}].name`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'code-' + i}>{t('myChart.medications.code')}</label>
                                                    <input
                                                        id={'code-' + i}
                                                        className="form-control"
                                                        {...formik.getFieldProps(`medications[${i}].code`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'dosage-' + i}>{t('myChart.medications.dosage')}</label>
                                                    <input
                                                        id={'dosage-' + i}
                                                        className="form-control"
                                                        {...formik.getFieldProps(`medications[${i}].dosage`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-2 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'date-' + i}>{t('myChart.shared.date')}</label>
                                                    <input
                                                        id={'date-' + i}
                                                        className="form-control"
                                                        type="date"
                                                        {...formik.getFieldProps(`medications[${i}].date`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <label>{t('myChart.shared.remove')}</label>
                                                <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelpers.remove(i)} >
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Fragment>
                                })
                            }
                        </div>
                    </Fragment>
                )}
            />
        </div>
    )
}