import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const PhysicalExams = ({ formik, t }) => {
    return (
        <FieldArray
            name="healthInfo.physicalexams"
            render={
                arrayHelper => (
                    <Fragment>

                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayHelper.push({
                                    schoolyear: '',
                                    date: '',
                                    school: '',
                                    height: '',
                                    weight: '',
                                    bloodpressure: '',
                                    examinedby: '',
                                    referredby: ''
                                })}>
                                    <i className="fa fa-plus"></i> {t('education.healthInfo.physicalExams.addPhysicalExamButton')}
                                </button>
                            </div>
                        </div>

                        <div className="profileContent">
                            {
                                formik.values.healthInfo.physicalexams.map((medication, i) => {
                                    return <Fragment key={i}>
                                        <div className="row">
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'schoolyear-' + i}>{t('education.shared.schoolYear')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].schoolyear`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'date-' + i}>{t('education.shared.date')}</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].date`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'school-' + i}>{t('education.shared.school')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].school`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'height-' + i}>{t('education.healthInfo.physicalExams.height')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].height`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'weight-' + i}>{t('education.healthInfo.physicalExams.weight')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].weight`)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'bloodpressure-' + i}>{t('education.healthInfo.physicalExams.bloodPressure')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].bloodpressure`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'examinedby-' + i}>{t('education.shared.examinedBy')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].examinedby`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'referredby-' + i}>{t('education.shared.referredBy')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].referredby`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <label>{t('education.shared.remove')}</label>
                                                <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelper.remove(i)} >
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Fragment>
                                })
                            }
                        </div>
                    </Fragment>
                )
            }
        />
    );
}