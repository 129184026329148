import React from 'react';

export const MedicalHistory = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.medicalHistory.title')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='medicalHistoryUrl'>{t('myChartProviders.shared.url')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.medicalHistoryUrl')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='medicalHistoryDiagnosisSelectorAll'>{t('myChartProviders.medicalHistory.diagnosisAll')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.medicalHistoryDiagnosisSelectorAll')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='medicalHistoryDiagnosisSelectorName'>{t('myChartProviders.medicalHistory.diagnosisName')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.medicalHistoryDiagnosisSelectorName')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='medicalHistoryDiagnosisSelectorWhen'>{t('myChartProviders.medicalHistory.diagnosisWhen')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.medicalHistoryDiagnosisSelectorWhen')}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='surgicalHistorySelectorAll'>{t('myChartProviders.medicalHistory.surgicalHistoryAll')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.surgicalHistorySelectorAll')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='surgicalHistorySelectorName'>{t('myChartProviders.medicalHistory.surgicalHistoryName')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.surgicalHistorySelectorName')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='surgicalHistorySelectorWhen'>{t('myChartProviders.medicalHistory.surgicalHistoryWhen')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.surgicalHistorySelectorWhen')}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='familyHistorySelectorAll'>{t('myChartProviders.medicalHistory.familyHistoryAll')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.familyHistorySelectorAll')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='familyHistorySelectorRelationship'>{t('myChartProviders.medicalHistory.familyHistoryRelationship')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.familyHistorySelectorRelationship')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='familyHistorySelectorHealthIssue'>{t('myChartProviders.medicalHistory.familyHistoryHealthIssue')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.familyHistorySelectorHealthIssue')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='familyHistorySelectorComment'>{t('myChartProviders.medicalHistory.familyHistoryComment')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.familyHistorySelectorComment')}
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='socialHistorySelector'>{t('myChartProviders.medicalHistory.socialHistoryGlobal')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.socialHistorySelector')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='smokingTobaccoSelectorUse'>{t('myChartProviders.medicalHistory.smokingTobaccoUse')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.smokingTobaccoSelectorUse')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='smokingTobaccoSelectorType'>{t('myChartProviders.medicalHistory.smokingTobaccoType')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.smokingTobaccoSelectorType')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='smokingTobaccoSelectorPacks'>{t('myChartProviders.medicalHistory.smokingTobaccoPacks')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.smokingTobaccoSelectorPacks')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='smokingTobaccoSelectorTears'>{t('myChartProviders.medicalHistory.smokingTobaccoTears')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.smokingTobaccoSelectorTears')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='smokelessTobaccoSelectorUse'>{t('myChartProviders.medicalHistory.smokelessTobaccoUse')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.smokelessTobaccoSelectorUse')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='smokelessTobaccoSelectorType'>{t('myChartProviders.medicalHistory.smokelessTobaccoType')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.smokelessTobaccoSelectorType')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='alcoholUseSelectorUse'>{t('myChartProviders.medicalHistory.alcoholUse')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.alcoholUseSelectorUse')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='alcoholUseSelectorOunces'>{t('myChartProviders.medicalHistory.alcoholUseOunces')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.alcoholUseSelectorOunces')}
                        />
                    </div>
                </div>
            </div>
        </div>

    </div>

)