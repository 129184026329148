import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const Officevisits = ({ formik, t }) => {
    return (
        <FieldArray
            name="healthInfo.officevisits"
            render={
                arrayHelper => (
                    <Fragment>

                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayHelper.push({
                                    schoolyear: '',
                                    date: '',
                                    school: '',
                                    checkin: '',
                                    timeout: '',
                                    examinedby: '',
                                    referredby: '',
                                    reasonforvisit: '',
                                    treatment: '',
                                    disposition: ''
                                })}>
                                    <i className="fa fa-plus"></i> {t('education.healthInfo.officevisits.addOfficeVisitButton')}
                                </button>
                            </div>
                        </div>

                        <div className="profileContent">
                            {
                                formik.values.healthInfo.officevisits.map((officevisit, i) => {
                                    return <Fragment key={i}>
                                        <div className="row">
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'schoolyear-' + i}>{t('education.shared.schoolYear')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.officevisits[${i}].schoolyear`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'date-' + i}>{t('education.shared.date')}</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        {...formik.getFieldProps(`healthInfo.officevisits[${i}].date`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'school-' + i}>{t('education.shared.school')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.officevisits[${i}].school`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'checkin-' + i}>{t('education.healthInfo.officevisits.checkIn')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.officevisits[${i}].checkin`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'timeout-' + i}>{t('education.healthInfo.officevisits.timeOut')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.officevisits[${i}].timeout`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'examinedby-' + i}>{t('education.shared.examinedBy')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.officevisits[${i}].examinedby`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'referredby-' + i}>{t('education.shared.referredBy')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.officevisits[${i}].referredby`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'reasonforvisit-' + i}>{t('education.healthInfo.officevisits.reasonForVisit')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.officevisits[${i}].reasonforvisit`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'treatment-' + i}>{t('education.shared.treatment')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.officevisits[${i}].treatment`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'disposition-' + i}>{t('education.healthInfo.officevisits.disposition')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.officevisits[${i}].disposition`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <label>{t('education.shared.remove')}</label>
                                                <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelper.remove(i)} >
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Fragment>
                                })
                            }
                        </div>
                    </Fragment>
                )
            }
        />
    );
}