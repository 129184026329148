import React from 'react';

export const GlobalSelectors = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('educationProviders.global.title')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='loginUrl'>{t('educationProviders.global.loginUrl')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.loginUrl')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.loginUrl && formik.errors.configuration && formik.errors.configuration.loginUrl ? (
                            <span className="inputErrorText">{formik.errors.configuration.loginUrl}</span>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorUsername'>{t('educationProviders.global.userName')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorUsername')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorPassword'>{t('educationProviders.global.password')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorPassword')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorSubmitBtn'>{t('educationProviders.global.submitButton')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorSubmitBtn')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='loginErrorSelector'>{t('educationProviders.global.loginError')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.loginErrorSelector')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
)