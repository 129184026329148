import React from 'react';

export const History = ({ profiles, t }) => {

    const socialHistoryAlcoholUseVisibility = (currProfile, index) => {

        if (currProfile.history.socialHistory.alcoholUse.alcoholUse) {
            return <div key={index} className="row">
                <div className="col-md-3">{currProfile.history.socialHistory.alcoholUse.alcoholUse}</div>
                <div className="col-md-3">{currProfile.history.socialHistory.alcoholUse.ouncesWeek}</div>
                <div className="col-md-3">{currProfile.exportPersonName}</div>
                <div className="col-md-3">{currProfile.providerName}</div>
            </div>
        }
    }

    const socialHistorySmokingTobaccoUseVisibility = (currProfile, index) => {

        if (currProfile.history.socialHistory.smokingTobacco.smokingTobaccoUse) {
            return <div key={index} className="row">
                <div className="col-md-6 healthIssueName">{currProfile.history.socialHistory.smokingTobacco.smokingTobaccoUse}</div>
                <div className="col-md-3">{currProfile.exportPersonName}</div>
                <div className="col-md-3">{currProfile.providerName}</div>
                <div className="sub-tab-warper his-sub-elem-wrap">
                    <div className="row">
                        <div className="col-md-4 customLabel">{t('myChart.history.yearsSmoked', true)}</div>
                        <div className="col-md-4 customLabel">{t('myChart.history.tobaccoTypes', true)}</div>
                        <div className="col-md-4 customLabel">{t('myChart.history.packsPerDay', true)}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">{currProfile.history.socialHistory.smokingTobacco.yearsSmoked}</div>
                        <div className="col-md-4">{currProfile.history.socialHistory.smokingTobacco.smokingTobaccoTypes}</div>
                        <div className="col-md-4">{currProfile.history.socialHistory.smokingTobacco.packsDay}</div>
                    </div>
                </div>
            </div>
        }
    }

    const socialHistorySmokelessTobaccoUseVisibility = (currProfile, index) => {
        if (currProfile.history.socialHistory.smokelessTobacco.smokelessTobaccoUse) {
            return <div key={index} className="row">
                <div className="col-md-3">{currProfile.history.socialHistory.smokelessTobacco.smokelessTobaccoUse}</div>
                <div className="col-md-3">{currProfile.history.socialHistory.smokelessTobacco.smokelessTobaccoTypes}</div>
                <div className="col-md-3">{currProfile.exportPersonName}</div>
                <div className="col-md-3">{currProfile.providerName}</div>
            </div>
        }
    }

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('myChart.history.historyTitle')}</h4>

                <div className="profileContent">

                    {/* START FAMILY HISTORY */}
                    <div className="row">
                        <div className="historyLabel">{t('myChart.history.familyHistoryTitle', true)}</div>
                        <div className="col-md-6 customLabel">{t('myChart.history.healthIssue', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return currProfile.history.familyHistory.map((issue, j) => {
                                return <div key={j} className="row">
                                    <div className="col-md-6 healthIssueName">{issue.healthIssue}</div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                    <div className="sub-tab-warper his-sub-elem-wrap">
                                        <div className="row">
                                            <div className="col-md-6 customLabel">{t('myChart.history.relationship', true)}</div>
                                            <div className="col-md-6 customLabel">{t('myChart.history.comment', true)}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">{issue.relationship}</div>
                                            <div className="col-md-6">{issue.comment}</div>
                                        </div>
                                    </div>
                                </div>
                            })
                        })
                    }
                    {/* END FAMILY HISTORY */}

                    {/* START MEDICAL HISTORY */}
                    <div className="row">
                        <div className="historyLabel">{t('myChart.history.medicalHistoryTitle', true)}</div>
                        <div className="col-md-5 customLabel">{t('myChart.shared.name', true)}</div>
                        <div className="col-md-2 customLabel">{t('myChart.shared.when', true)}</div>
                        <div className="col-md-2 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return currProfile.history.medicalHistory.map((medicalItem, j) => {
                                return <div key={j} className="row ">
                                    <div className="col-md-5">{medicalItem.name}</div>
                                    <div className="col-md-2">{medicalItem.when}</div>
                                    <div className="col-md-2">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                            })
                        })
                    }
                    {/* END MEDICAL HISTORY */}

                    {/* START SOCIAL HISTORY */}
                    <div className="historyLabel">{t('myChart.history.socialHistoryTitle', true)}</div>

                    <div className="row">
                        <div className="col-md-3 customLabel">{t('myChart.history.alcoholUse', true)}</div>
                        <div className="col-md-3 customLabel">{t('myChart.history.ouncesWeek', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return socialHistoryAlcoholUseVisibility(currProfile, i);
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.history.smokingTobaccoUse', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return socialHistorySmokingTobaccoUseVisibility(currProfile, i);
                        })
                    }

                    <div className="row">
                        <div className="col-md-3 customLabel">{t('myChart.history.smokelessTobacco', true)}</div>
                        <div className="col-md-3 customLabel">{t('myChart.history.smokelessTobaccoTypes', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return socialHistorySmokelessTobaccoUseVisibility(currProfile, i);
                        })
                    }
                    {/* END SOCIAL HISTORY */}

                    {/* START SURGICAL HISTORY */}
                    <div className="row">
                        <div className="historyLabel">{t('myChart.history.surgicalHistoryTitle', true)}</div>
                        <div className="col-md-4 customLabel">{t('myChart.shared.name', true)}</div>
                        <div className="col-md-2 customLabel">{t('myChart.shared.when', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return currProfile.history.surgicalHistory.map((surgicalItem, j) => {
                                return <div key={j} className="row">
                                    <div className="col-md-4">{surgicalItem.name}</div>
                                    <div className="col-md-2">{surgicalItem.when}</div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                            })
                        })
                    }
                    {/* END SURGICAL HISTORY */}
                </div>

            </div>
        </div>

    )
}