import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const Attendances = ({ formik, t }) => {
    return (
        <div className="tab-warper">
            <h4 className="headName">{t('education.attendances.attendancesTitle')}</h4>
            <FieldArray
                name="attendances"
                render={
                    arrayHelper => (
                        <Fragment>
                            <div className="row buttonFromHead">
                                <div className="col-md-6 customLabel">
                                    <button type="button" className="btn" onClick={() => arrayHelper.push({
                                        date: '',
                                        attendance: '',
                                        period: '',
                                        className: ''
                                    })}>
                                        <i className="fa fa-plus"></i> {t('education.attendances.addAttendanceButton')}
                                    </button>
                                </div>
                            </div>
                            <div className="profileContent">
                                {
                                    formik.values.attendances.map((attendance, i) => {
                                        return <Fragment key={i}>
                                            <div className="row">
                                                <div className="col-md-3 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'date-' + i}>{t('education.shared.date')}</label>
                                                        <input
                                                            className="form-control"
                                                            type="date"
                                                            {...formik.getFieldProps(`attendances[${i}].date`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'attendance-' + i}>{t('education.attendances.attendance')}</label>
                                                        <input
                                                            className="form-control"
                                                            {...formik.getFieldProps(`attendances[${i}].attendance`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'period-' + i}>{t('education.shared.period')}</label>
                                                        <input
                                                            className="form-control"
                                                            {...formik.getFieldProps(`attendances[${i}].period`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'className-' + i}>{t('education.shared.className')}</label>
                                                        <input
                                                            className="form-control"
                                                            {...formik.getFieldProps(`attendances[${i}].className`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-1">
                                                    <label>Remove:</label>
                                                    <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelper.remove(i)} >
                                                        <span>&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </Fragment>
                                    })
                                }
                            </div>
                        </Fragment>
                    )
                }
            />
        </div>
    )
}