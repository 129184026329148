import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import userReqHandler from '../../api/userRemote';
import { withTranslation } from 'react-i18next';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {}
        };
    }

    onChangeHandler(field, event) {
        const fields = this.state.fields;
        fields[field] = event.target.value;
        this.setState({ fields });
    }

    onFormSubmit(event) {
        event.preventDefault();

        if (this.handleValidation()) {

            const loginData = {
                email: this.state.fields["email"],
                password: this.state.fields["password"]
            };

            userReqHandler.login(loginData).then(res => {
                if (res) {
                    this.props.history.push('/');
                }
            });
        }
    }

    handleValidation() {

        const fields = this.state.fields;
        const errors = {};
        let formIsValid = true;

        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Please enter your email.";
        }

        if (!fields["password"]) {
            formIsValid = false;
            errors["password"] = "Please enter your password.";
        }

        this.setState({ errors: errors });

        return formIsValid;
    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-xs-6">
                            <h2>{t('login.title')}</h2>
                            <form className="login-form" onSubmit={this.onFormSubmit.bind(this)} >

                                <div className="form-group">
                                    <label htmlFor="email">{t('login.email')}</label>
                                    <input
                                        ref="email"
                                        id="email"
                                        className="form-control"
                                        type="text"
                                        placeholder="Email"
                                        name="email"
                                        onChange={this.onChangeHandler.bind(this, "email")}
                                    />
                                    <span className="inputErrorText">{this.state.errors["email"]}</span>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password">{t('login.password')}</label>
                                    <input
                                        ref="password"
                                        id="password"
                                        className="form-control"
                                        type="password"
                                        placeholder="Password"
                                        name="password"
                                        onChange={this.onChangeHandler.bind(this, "password")}
                                    />
                                    <span className="inputErrorText">{this.state.errors["password"]}</span>
                                </div>

                                <input type="submit" className="btn btn-primary" value={t('login.loginButton')} />
                                <div className="row">
                                    <Link id="goToReg" to="/register">{t('login.addAccount')}</Link>
                                </div>
                                <div className="row">
                                    <Link id="goToReg" to="/user/forgottenPassword">{t('login.forgottenPassword')}</Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };
};

export default withTranslation()(Login);