import React from 'react';

export const AllergiesSelectors = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.allergiesTitle')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='immunizationsUrl'>{t('myChartProviders.shared.url')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.allergiesUrl')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='allergiesSelectorAll'>{t('myChartProviders.shared.all')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.allergiesSelectorAll')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='allergiesSelectorList'>{t('myChartProviders.shared.allList')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.allergiesSelectorList')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='allergiesSelectorName'>{t('myChartProviders.shared.name')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.allergiesSelectorName')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='allergiesSelectorDate'>{t('myChartProviders.shared.date')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.allergiesSelectorDate')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

)