import React from 'react';

export const GradebookSelectors = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('educationProviders.gradebook.title')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='studentInfoSelector'>{t('educationProviders.gradebook.studentName')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.studentInfoSelector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.studentInfoselector && formik.errors.configuration && formik.errors.configuration.studentInfoSelector ? (
                            <span className="inputErrorText">{formik.errors.configuration.studentInfoSelector}</span>
                        ) : null}
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='leftGridSelector'>{t('educationProviders.gradebook.mainGridLeft')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.leftGridSelector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.leftGridSelector && formik.errors.configuration && formik.errors.configuration.leftGridSelector ? (
                            <span className="inputErrorText">{formik.errors.configuration.leftGridSelector}</span>
                        ) : null}
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='leftGridCellSelector'>{t('educationProviders.gradebook.mainGridLeftCell')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.leftGridCellSelector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.leftGridCellSelector && formik.errors.configuration && formik.errors.configuration.leftGridCellSelector ? (
                            <span className="inputErrorText">{formik.errors.configuration.leftGridCellSelector}</span>
                        ) : null}
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='marksGridSelector'>{t('educationProviders.gradebook.mainGridMarks')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.marksGridSelector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.marksGridSelector && formik.errors.configuration && formik.errors.configuration.marksGridSelector ? (
                            <span className="inputErrorText">{formik.errors.configuration.marksGridSelector}</span>
                        ) : null}
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='marksGridColSelector'>{t('educationProviders.gradebook.mainGridMarksColumn')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.marksGridColSelector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.marksGridColSelector && formik.errors.configuration && formik.errors.configuration.marksGridColSelector ? (
                            <span className="inputErrorText">{formik.errors.configuration.marksGridColSelector}</span>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    </div>
)