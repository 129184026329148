import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import profile from './profile';
import visit from './visit';
import providers from './providers';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    profile: profile,
    providers: providers,
    visit: visit,
})
export default createRootReducer