import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import CSVReader from "react-csv-reader";
import importReqHandler from '../../api/importRemote';
import toastr from 'toastr';

class ProfileImporter extends Component {
    fileReader;
    constructor(props) {
        super(props);
        this.state = {
            file: {},
            fileLoaded: false
        }
        this.import = this.import.bind(this);
        // this.loadCSVFile = this.loadCSVFile.bind(this);
    }

    componentDidMount() { }

    import() {
        const data = this.state.data;
        console.log(data)
        importReqHandler.import(data).then((res) => {
            if(res){
                toastr.success('Profiles imported successfully!');                
                this.props.history.push({
                    pathname: '/',
                    state: { reload: true }
                })
            }
        });
    }

    handleFileRead = (e) => {
        const content = this.fileReader.result;
        console.log(content);
    }

    onChangeFileInputHandler = event => {
        const file = event.currentTarget.files[0];
        this.setState({ file: file });
        this.fileReader = new FileReader();
        this.fileReader.onloadend = this.handleFileRead;
        this.fileReader.readAsText(file)
    }

    render() {
        const { t } = this.props;
        const papaparseOptions = {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            transformHeader: header => header.toLowerCase().replace(/\W/g, "_")
        };

        const handleForce = (data, fileInfo) => {
            this.setState({ fileLoaded: true });
            this.setState({ data: data });
            // importReqHandler.import(data);
            console.log(data, fileInfo);
        }

        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <h1>{t('admin.ProfileImporter.title')}</h1>
                    </div>
                    <div className="row">
                        <CSVReader
                            cssClass="react-csv-input col-md-6"
                            label="Select CSV file"
                            onFileLoaded={handleForce}
                            parserOptions={papaparseOptions}
                        />
                        <div className="col-md-4">
                            <button type="button" disabled={!this.state.fileLoaded} className="btn btn-danger width-200 btn-import" onClick={this.import}>{t('admin.ProfileImporter.Import')}</button>
                        </div>
                        {/* <div className="col-md-6 input-group fileWrapper">
                            <span className="form-control">{this.state.file.name}</span>
                            <div className="input-group-btn">
                                <label htmlFor='file' className="btn btn-default">{t('myChart.files.browseButton')}</label>
                                <input
                                    id="file"
                                    type="file"
                                    onChange={this.onChangeFileInputHandler}

                                />
                            </div>
                        </div> */}

                        <div className="col-md-5"></div>

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default withTranslation()(ProfileImporter);