import React from 'react';

export const HealthIssues = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.healthIssuesTitle')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthIssuesUrl'>{t('myChartProviders.shared.url')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthIssuesUrl')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthIssueSelectorAll'>{t('myChartProviders.shared.all')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthIssueSelectorAll')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthIssueSelectorName'>{t('myChartProviders.shared.name')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthIssueSelectorName')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='healthIssueSelectorDate'>{t('myChartProviders.shared.date')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.healthIssueSelectorDate')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

)