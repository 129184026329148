import React, { Component, Fragment } from 'react';
import adminUserReqHandler from '../../api/adminUserRemote';
import { Formik, Field } from 'formik';
import toastr from 'toastr';
import { withTranslation } from 'react-i18next';

class AdminUsers extends Component {
    
    searchData = {
        searchTags: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            fields: {
                userType: ''
            },
            errors: {},
        };
    }

    componentDidMount() {

        this.loadUsersList();
    }

    onFormSubmit(values) {
        this.searchData = values;        
        this.setState({ users: [] });
        this.loadUsersList();
    }

    loadUsersList() {
 
        let params = {
        }

        if (this.searchData) {
            params = {
                ...this.searchData
            }
        }

        adminUserReqHandler.getUsers(params).then(res => {
            console.log(res)
            if (res) {
                this.setState({ users: res.users });
            }
        });
    }

    onChangeHandler(field, event) {

        const inputFiledData = field.split('-');
        const fieldId = parseInt(inputFiledData[1]);
        const changedType = (event.target.value).toUpperCase();

        const currUsers = this.state.users;
        currUsers[fieldId].userType = changedType;

        this.setState({ users: currUsers });
    }

    updateUserType(arrayIndex, userId, event) {

        if(this.handleValidation(arrayIndex)) {

            const userData = {
                id: userId,
                userType: this.state.users[arrayIndex].userType
            };

            adminUserReqHandler.updateUserType(userData).then(res => {

                if (res && res.success) {
                    toastr.success(this.props.t('userAdmin.updateSuccess'));
                }
            });
        }
    }

    handleValidation(arrayIndex) {

        const field = this.state.users[arrayIndex].userType;
        const errors = {};
        let formIsValid = true;

        if(field.trim().length === 0) {
            formIsValid = false;
            errors[arrayIndex] = this.props.t('validationErrors.fieldEmpty');
        }

        if(field !== 'BASIC' && field !== 'ADMIN') {
            formIsValid = false;
            errors[arrayIndex] = this.props.t('userAdmin.userMustBe');
        } else {
            errors[arrayIndex] = "";
        }         

        this.setState({ errors: errors });

        return formIsValid;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <h1>{t('userAdmin.title')}</h1>
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={true}
                            initialValues={this.searchData}
                            enableReinitialize={true}
                            onSubmit={(values) => this.onFormSubmit(values)}
                        >
                            {formik => (
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="form-group">
                                                <label htmlFor="searchTags">{t('allProfilesList.searchField')}</label>
                                                <Field className="form-control" type="text" name="searchTags" id="searchTags" />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="marginTop25">
                                                <button
                                                    className="btn btn btn-primary"
                                                >{t('allProfilesList.searchButton')}</button>
                                                <button
                                                    type="button"
                                                    className="btn btn-default marginLeft10"
                                                    onClick={() => this.noRefresh(formik.resetForm)}
                                                ><i className="fa fa-refresh" aria-hidden="true"></i> {t('allProfilesList.refreshButton')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>{t('userAdmin.email')}</th>
                                        <th>{t('userAdmin.fullName')}</th>
                                        <th>{t('userAdmin.password')}</th>
                                        <th>{t('userAdmin.userType')}</th>
                                        <th>{t('userAdmin.actions')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.state.users.length > 0 &&
                                        this.state.users.map((user, i) => {
                                            return <tr key={ i } className="customTableRow">
                                                <td className="customTableTd">{ user.email }</td>
                                                <td className="customTableTd">{ user.firstName } { user.lastName }</td>
                                                <td className="customTableTd">{ user.password }</td>
                                                <td className="customTableTd">
                                                { this.state.users[i].userType !== 'GOD' &&
                                                    <Fragment>
                                                        <input
                                                            ref={ 'userType-' + i }
                                                            id={ 'userType-' + i }
                                                            type="text"
                                                            className="form-control"
                                                            name="userType"
                                                            value={ this.state.users[i].userType }
                                                            onChange={ this.onChangeHandler.bind(this, ('userType-' + i)) }
                                                        />
                                                        <span className="inputErrorText">{ this.state.errors[i] }</span>
                                                    </Fragment>
                                                }
                                                { this.state.users[i].userType === 'GOD' &&
                                                    <span>{ this.state.users[i].userType }</span>
                                                }
                                                </td>
                                                <td className="customTableTd">
                                                    { user.userType !== 'GOD' &&
                                                        <button
                                                            type="button"
                                                            className="btn btn-default"
                                                            onClick={ this.updateUserType.bind(this, i, user.id) }
                                                        >{t('userAdmin.updateButton')}</button>
                                                    }
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        );
    };
}

export default withTranslation()(AdminUsers);