import React, { Component, Fragment } from 'react';

class Attendance extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        if (property === 'attendances') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.attendances[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('attendances'))}
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('education.attendances.attendancesTitle')}</h4>
                        {this.props.profile.content &&
                            <div className="profileContent">

                                <div className="row">
                                    <div className="col-md-1 customLabel"></div>
                                    <div className="col-md-2 customLabel">{t('education.shared.date', true)}</div>
                                    <div className="col-md-4 customLabel">{t('education.attendances.attendance', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.period', true)}</div>
                                    <div className="col-md-3 customLabel">{t('education.shared.className', true)}</div>
                                </div>

                                {this.props.profile.content.attendances &&
                                    this.props.profile.content.attendances.map((item, i) => {
                                        return <Fragment key={i}>
                                            <div className="row fileList">
                                                <div className="col-md-1">
                                                    {this.props.showVisibilitySettings &&
                                                        <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                            onClick={() => this.props.eyeClicked(i)}>
                                                        </i>
                                                    }
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.date}</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <span>{item.attendance}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.period}</span>
                                                </div>
                                                <div className="col-md-3">
                                                    <span>{item.className}</span>
                                                </div>
                                            </div>
                                        </Fragment>
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default Attendance;