import React from 'react';

export const MedicationsSelectors = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.medications.title')}</h4>
        <div className='profileContent'>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='medicationsUrl'>{t('myChartProviders.shared.url')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.medicationsUrl')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='medicationsSelectorAll'>{t('myChartProviders.shared.all')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.medicationsSelectorAll')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='medicationsSelectorName'>{t('myChartProviders.shared.name')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.medicationsSelectorName')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='medicationsSelectorDosage'>{t('myChartProviders.medications.dosage')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.medicationsSelectorDosage')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='medicationsSelectorDate'>{t('myChartProviders.shared.date')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.medicationsSelectorDate')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
)