import React, { Component, Fragment } from 'react';
import profileReqHandler from '../../api/profileRemote';
import providerReqHandler from '../../api/providerRemote';
import MyChartEditProfile from './MyChart/EditProfile';
import EducationEditProfile from './Education/EditProfile';
import { connect } from 'react-redux';
import toastr from 'toastr';
toastr.options.closeButton = true;

class EditPersonalProfile extends Component {
    profileId;
    constructor(props) {
        super(props);

        this.state = {
            providers: []
        }
    }

    componentDidMount() {
        this.profileId = this.props.match.params.id;
        if (this.profileId  && (!this.props.profile || !this.props.profile[this.profileId ])) {
            this.props.getProfile({ profileId: this.profileId  });
        }

        providerReqHandler.getAllProviders().then(res => {

            if (res) {
                this.setState({ providers: res });
            }
        });
    }

    render() {
        let provider;
        if (this.state.providers && this.props.profile && this.props.profile[this.profileId]) {
            provider = this.state.providers.find(pr => {
                return pr.id === this.props.profile[this.profileId].profile.provider;
            })
        }

        return (
            <Fragment>
                <div className="container">
                    <div className="container">
                        {provider &&
                            <div className="row">
                                {provider.providerTypeName === 'mychart' &&
                                    <MyChartEditProfile {...this.props} />}
                                {provider.providerTypeName === 'education' &&
                                    <EducationEditProfile  {...this.props} />}
                            </div>
                        }
                    </div>
                </div>
            </Fragment >
        );
    }
};

const mapStateToProps = state => {
    return {
        profile: state.profile.profileData
    }
}


const mapDispatchToProps = dispatch => {
    return {
        getProfile: profileId => dispatch(profileReqHandler.getProfile(profileId))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalProfile);