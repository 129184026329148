import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

const Footer = ({t}) => {

    function currentYear() {
        const date = new Date();
        return date.getFullYear();
    }

    return (
        <footer className="footer">
            <div className="container">
                <p className="text-muted">
                    &copy; {t("footer.copyright")} {currentYear()}
                </p>
            </div>
        </footer>
    );
};

export default withTranslation()(Footer);