import React from 'react';

export const TestsDetails = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.testsDetails.title')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='testsDetailsSelectorAll'>{t('myChartProviders.shared.all')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.testsDetailsSelectorAll')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='testsDetailsSelectorComponent'>{t('myChartProviders.testsDetails.component')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.testsDetailsSelectorComponent')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='testsDetailsSelectorValue'>{t('myChartProviders.testsDetails.value')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.testsDetailsSelectorValue')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='testsDetailsSelectorRange'>{t('myChartProviders.testsDetails.range')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.testsDetailsSelectorRange')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='testsDetailsSelectorName'>{t('myChartProviders.shared.name')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.testsDetailsSelectorName')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='testsDetailsSelectorOrderBy'>{t('myChartProviders.testsDetails.orderBy')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.testsDetailsSelectorOrderBy')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='testsDetailsSelectorDate'>{t('myChartProviders.shared.date')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.testsDetailsSelectorDate')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='testsDetailsSelectorStudyResult'>{t('myChartProviders.testsDetails.studyResult')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.testsDetailsSelectorStudyResult')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

)