import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import profileReqHandler from "../../api/profileRemote";
import providerReqHandler from "../../api/providerRemote";
import visitReqHandler from "../../api/visitRemote";
import authentication from "../../utilities/authentication";
import ProfileTab from "./ProfileTab";
import VisitTab from "./VisitTab";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Loader from "react-loader-spinner";
import Helpers from '../../utilities/Helpers'
class Dashboard extends Component {
  visitStructure = {
    id: "",
    provider: {
      name: "",
      providerTypeName: "",
      id: "",
      status: "",
    },
    profileName: "",
  };

  constructor(props) {
    super(props);

    this.state = {
      person: {},
      profiles: [],
      profileData: {},
      visitData: {},
      profileSelected: false,
      visitSelected: false,
    };
    this.clearDropDowns = this.clearDropDowns.bind(this);
  }

  componentDidMount() {
    const personId = authentication.getPerson().id;
    if (!this.props.profilesList || !this.props.profilesList[personId]) {
      this.props.getProfilesList(personId);
    }
    if (!this.props.visitsList || !this.props.visitsList[personId]) {
      this.props.getVisitsList(personId);
    }
  }

  componentDidUpdate() {
    this.clearDropDowns();
  }

  clearDropDowns() {
    if (this.state.profileSelected) {
      if (Helpers.isEmpty(this.state.visitData)) return;
      document.getElementById("visits").value = "default";
      this.setState({ visitData: {} });
    }
    if (this.state.visitSelected) {
      if (Helpers.isEmpty(this.state.profileData)) return;
      document.getElementById("profiles").value = "default";
      this.setState({ profileData: {} });
    }
  }

  profileSelect(event) {
    this.setState({ profileSelected: true });
    this.setState({ visitSelected: false });

    if (event.target.value === "default") {
      this.setState({ profileData: {} });
    } else {
      const profileData = event.target.value.split("+");

      // get provider
      providerReqHandler.getProvider(profileData[3]).then((res) => {
        if (res) {
          const provider = {
            providerName: res.providerName,
            providerTypeName: res.providerTypeName,
            status: res.status,
            providerId: res.providerId,
          };

          console.log(res);
          const data = {
            profileId: profileData[0],
            providerName: provider.providerName,

            providerTypeName: provider.providerTypeName,
            providerId: provider.providerId,
            providerStatus: provider.status,
            profileDetailId: profileData[5]
          };

          console.log(data);

          if (!this.props.profile || !this.props.profile[data.profileId]) {
            this.props.getProfile(data);
          }

          this.setState({
            profileData: data,
          });
        }
      });
    }
  }

  visitSelect(event) {
    this.setState({ profileSelected: false });
    this.setState({ visitSelected: true });
    this.clearDropDowns();


    if (event.target.value === "default") {
      this.setState({ visitData: {} });
    } else {
      const visitData = event.target.value.split("+");

      // this.props.profilesList.filter((profile) => { profile.id === })
      const data = {
        visitId: visitData[0],
      };

      if (!this.props.visit || !this.props.visit[data.visitId]) {
        this.props.getVisit(data);
      }

      this.setState({
        visitData: data,
      });
    }
  }
  render() {
    const { t } = this.props;
    const personId = authentication.getPerson().id;

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <h1>{t("dashboard.dashboardTitle")}</h1>
            <label htmlFor="profiles">
              {t("dashboard.selectProfileField")}
            </label>
            <select
              className="form-control"
              id="profiles"
              onChange={(event) => this.profileSelect(event)}
            >
              <option value="default">
                {t("dashboard.dropDownDefaultValue")}
              </option>

              {this.props.profilesList &&
                this.props.profilesList[personId] &&
                this.props.profilesList[personId].map((profTitle, i) => {
                  return (
                    <option
                      key={i}
                      value={
                        profTitle.id +
                        "+" +
                        profTitle.provider.name +
                        "+" +
                        profTitle.provider.providerTypeName +
                        "+" +
                        profTitle.provider.id +
                        "+" +
                        profTitle.provider.status + 
                        "+" + 
                        profTitle.profileDetailId
                      }
                    >
                      {profTitle.profileName}
                    </option>
                  );
                })}
            </select>
          </div>
          <br />

          <div className="row">
            <label htmlFor="visits">{t("dashboard.selectVisitField")}</label>
            <select
              className="form-control"
              id="visits"
              onChange={(event) => this.visitSelect(event)}
            >
              <option value="default">
                {t("dashboard.dropDownDefaultValueVisit")}
              </option>

              {this.props.visitsList &&
                this.props.visitsList[personId] &&
                this.props.visitsList[personId].map((visitTitle, i) => {
                  return (
                    <option key={i} value={visitTitle.id + 
                      "+" +
                      visitTitle.profileId
                        }>
                      {visitTitle.visitName}
                    </option>
                  );
                })}
            </select>
          </div>

          {(!this.props.profile && this.state.profileSelected
             && (
              <div className="center-screen deleteProfileRow">
                <Loader
                  type="Oval"
                  color="#00BFFF"
                  height={80}
                  width={80}
                ></Loader>
              </div>
            ))}
            {(
            !this.props.visit && this.state.visitSelected
             && (
              <div className="center-screen deleteProfileRow">
                <Loader
                  type="Oval"
                  color="#00BFFF"
                  height={80}
                  width={80}
                ></Loader>
              </div>
            ))}
          <div className="row">
            {this.props.profile &&
              this.props.profile[this.state.profileData.profileId] && (
                <ProfileTab
                  profileData={this.state.profileData}
                  profile={this.props.profile[this.state.profileData.profileId]}
                  updateVisibilitySchema={(updateData) =>
                    this.props.updateVisibilitySchema(updateData)
                  }
                  adminMode={false}
                />
              )}
            {this.props.visit &&
              this.props.visit[this.state.visitData.visitId] && (
                <VisitTab
                  visit={this.props.visit[this.state.visitData.visitId].visit}
                  updateVisitVisibilitySetting={(updateData) =>
                    this.props.updateVisitVisibilitySetting(updateData)
                  }
                  adminMode={false}
                />
              )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profilesList: state.profile.profilesList,
    profile: state.profile.profileData,
    visitsList: state.visit.visitsList,
    visit: state.visit.visitData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProfilesList: (personId) =>
      dispatch(profileReqHandler.getProfilesList(personId)),
    getProfile: (profileId) =>
      dispatch(profileReqHandler.getProfile(profileId)),
    getVisitsList: (personId) =>
      dispatch(visitReqHandler.getVisitsList(personId)),
    getVisit: (visitId) => dispatch(visitReqHandler.getVisit(visitId)),
    updateVisibilitySchema: (updateData) =>
      dispatch(profileReqHandler.updateVisibilitySchema(updateData)),
    updateVisitVisibilitySetting: (updateData) =>
      dispatch(visitReqHandler.updateVisitVisibilitySetting(updateData)),
      
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
