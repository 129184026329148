import React, { Fragment } from 'react';

export const Grades = ({ profiles, t }) => {

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('fwisd.grades.title')}</h4>
                <div className="profileContent">
                    {
                        profiles.map((currProfile, i) => {
                            if (currProfile.grades && currProfile.grades.length > 0) {
                                return <Fragment key={i}>
                                    <div className="row">
                                        <div className="col-md-6"> </div>
                                        <div className="col-md-3">{currProfile.exportPersonName}</div>
                                        <div className="col-md-3">{currProfile.providerName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-1 customLabel">{t('fwisd.grades.year', true)}</div>
                                        <div className="col-md-2 customLabel">{t('fwisd.grades.markingPeriod', true)}</div>
                                        <div className="col-md-3 customLabel">{t('fwisd.grades.course', true)}</div>
                                        <div className="col-md-2 customLabel">{t('fwisd.grades.courseNumber', true)}</div>
                                        <div className="col-md-1 customLabel">{t('fwisd.grades.percent', true)}</div>
                                        <div className="col-md-2 customLabel">{t('fwisd.grades.grade', true)}</div>
                                    </div>
                                    {currProfile.grades &&
                                        currProfile.grades.map((item, k) => {
                                            return <div key={k} className="row fileList">
                                                <div className="col-md-1">
                                                    <span>{item.year}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.markingPeriodId}</span>
                                                </div>
                                                <div className="col-md-3">
                                                    <span>{item.course}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.courseNum}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.percentGrade}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.gradeTitle}</span>
                                                </div>
                                            </div>
                                        })
                                    }
                                </Fragment>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
}