import fetchApi from '../utilities/fetch';

let tempLinksReqHandler = {
    checkAccessCode: (code, guid) => {
        return fetchApi.get(`/checkAccessCode?code=${code}&guid=${guid}`);
    },
    checkIfCodeIsValidated: (guid) => {
        return fetchApi.get(`/checkIfCodeIsValidated?guid=${guid}`);
    },
    getTempLinks: (guid) => {
        return fetchApi.get(`/tempLink?guid=${guid}`);
    },
    createTempLink: (tempLink) => {
        return fetchApi.post('/tempLink', tempLink);
    },
    createTempLinkSummary: (tempLink) => {
        return fetchApi.post('/tempLinkSummary', tempLink);
    }
};

export default tempLinksReqHandler;