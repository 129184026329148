import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';


export const PlanOfCareFuture = ({ formik, arrayHelpers }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="row buttonFromHead">
                <div className="col-md-6 customLabel">
                    <button type="button" className="btn" onClick={() => arrayHelpers.push({ title: '', date: '',  location: '', orderBy: '', orderDetails: '' })}>
                        <i className="fa fa-plus"></i> {t('myChart.planOfCare.addPlanOfCareFutureButton')}
                    </button>
                </div>
            </div>
            <div className="profileContent">
                {   formik.values.planOfCare &&
                    formik.values.planOfCare.planOfCareFuture && formik.values.planOfCare.planOfCareFuture.length !== 0 && 
                    formik.values.planOfCare.planOfCareFuture.map((mh, i) => {
                        return <Fragment key={i}>
                            <div className="row">
                                <div className="col-md-2 customLabel">
                                    <div className="form-group">
                                        <label htmlFor={'title-' + i}>{t('myChart.planOfCare.title')}</label>
                                        <input
                                            id={'title-' + i}
                                            className="form-control"
                                            {...formik.getFieldProps(`planOfCare.planOfCareFuture[${i}].title`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-2 customLabel">
                                    <div className="form-group">
                                        <label htmlFor={'date-' + i}>{t('myChart.planOfCare.date')}</label>
                                        <input
                                            id={'date-' + i}
                                            className="form-control"
                                            type="date"
                                            {...formik.getFieldProps(`planOfCare.planOfCareFuture[${i}].date`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 customLabel">
                                    <div className="form-group">
                                        <label htmlFor={'location-' + i}>{t('myChart.planOfCare.location')}</label>
                                        <input
                                            id={'location-' + i}
                                            className="form-control"
                                            {...formik.getFieldProps(`planOfCare.planOfCareFuture[${i}].location`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 customLabel">
                                    <div className="form-group">
                                        <label htmlFor={'orderBy-' + i}>{t('myChart.planOfCare.orderBy')}</label>
                                        <input
                                            id={'orderBy-' + i}
                                            className="form-control"
                                            {...formik.getFieldProps(`planOfCare.planOfCareFuture[${i}].orderBy`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-5 customLabel">
                                    <div className="form-group">
                                        <label htmlFor={'orderDetails-' + i}>{t('myChart.planOfCare.orderDetails')}</label>
                                        <input
                                            id={'orderDetails-' + i}
                                            className="form-control"
                                            {...formik.getFieldProps(`planOfCare.planOfCareFuture[${i}].orderDetails`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <label>{t('myChart.shared.remove')}</label>
                                    <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelpers.remove(i)} >
                                        <span>&times;</span>
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    })
                }
            </div>
        </Fragment>
    );
}
