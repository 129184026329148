import React, { Component, Fragment } from 'react';

class Absences extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {
        if (property === 'absences') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.absences[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('absences'))}
                                data-key="absences"
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('fwisd.absences.title')}</h4>
                        {this.props.profile.content &&
                            <div className="profileContent">

                                {this.props.profile.content.absences &&
                                    this.props.profile.content.absences.map((absence, i) => {
                                        return <Fragment key={i}>
                                            <div className="row trows">
                                                <div style={{ width: 25 }} className="col-md-1">
                                                    {this.props.showVisibilitySettings &&
                                                        <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                            onClick={() => this.props.eyeClicked(i)}>
                                                        </i>
                                                    }
                                                </div>
                                                {!this.props.tempLinkCodeAccess &&
                                                    <div style={{ width: 200 }} className="col-md-1 labTestRowItem periodLabel" data-toggle="collapse" data-target={'#absence' + i}>{absence.year}
                                                        <span class="glyphicon glyphicon-chevron-down customSpanExpander" />
                                                    </div>
                                                }
                                                 {this.props.tempLinkCodeAccess &&
                                                    <div style={{ width: 200 }} className="col-md-1 labTestRowItem periodLabel" data-toggle="expand" data-target={'#absence' + i}>{absence.year}
                                                    </div>
                                                }
                                            </div>

                                            <div id={'absence' + i} className={this.props.tempLinkCodeAccess ? "row labTest-warper expand" : "row labTest-warper collapse"}>

                                                <br />
                                                <div className="row">
                                                    <div className="historyLabel">{t('fwisd.absences.absencesTitle')}</div>

                                                </div>

                                                {absence && absence.absences &&
                                                    absence.absences.map((item, i) => {
                                                        return <Fragment key={i}>
                                                            <div className="row fileList">
                                                                <div className="col-md-1">
                                                                    {this.props.showVisibilitySettings &&
                                                                        <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                                            onClick={() => this.props.eyeClicked(i)}>
                                                                        </i>
                                                                    }
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span>{item.absenceType}</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span>{item.periods}</span>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    })
                                                }

                                                <br />
                                                <div className="row">
                                                    <div className="historyLabel">{t('fwisd.absences.otherMarksTitle')}</div>
                                                </div>

                                                {absence && absence.otherMarks &&
                                                    absence.otherMarks.map((item, i) => {
                                                        return <Fragment key={i}>
                                                            <div className="row fileList">
                                                                <div className="col-md-1">
                                                                    {this.props.showVisibilitySettings &&
                                                                        <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                                            onClick={() => this.props.eyeClicked(i)}>
                                                                        </i>
                                                                    }
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span>{item.absenceType}</span>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <span>{item.periods}</span>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    })
                                                }

                                                <br />
                                                <div className="row">
                                                    <div className="historyLabel">{}</div>
                                                </div>

                                                {absence && absence.absencesInSchool &&
                                                    absence.absencesInSchool.map((item, i) => {
                                                        return <Fragment key={i}>

                                                            {this.props.showVisibilitySettings &&
                                                                <i className={"fa fa-eye " + (this.eyeToggle('absencesInSchool'))}
                                                                    onClick={() => this.props.eyeClicked('absencesInSchool')}>
                                                                </i>
                                                            }
                                                            {/* <span className="persInfoPtag">{t('fwisd.absences.absencesInSchool.schoolName')} {item.schoolName}</span> */}
                                                            <h4 className="headName"> {item.schoolName} </h4>
                                                            <br />
                                                            <div className="row">
                                                                {item.totalDaysPossible &&
                                                                    <Fragment>
                                                                        {this.props.showVisibilitySettings &&
                                                                            <i className={"fa fa-eye " + (this.eyeToggle('totalDaysPossible'))}
                                                                                onClick={() => this.props.eyeClicked('totalDaysPossible')}>
                                                                            </i>
                                                                        }
                                                                        <span className="persInfoPtag">{t('fwisd.absences.totalFullDays.totalDaysPossible')} {item.totalDaysPossible}</span>
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                            <div className="row">
                                                                {item.totalDaysAttended &&
                                                                    <Fragment>
                                                                        {this.props.showVisibilitySettings &&
                                                                            <i className={"fa fa-eye " + (this.eyeToggle('totalDaysAttended'))}
                                                                                onClick={() => this.props.eyeClicked('totalDaysAttended')}>
                                                                            </i>
                                                                        }
                                                                        <span className="persInfoPtag">{t('fwisd.absences.totalFullDays.totalDaysAttended')} {item.totalDaysAttended}</span>
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                            <div className="row">
                                                                {item.totalDaysAbsent &&
                                                                    <Fragment>
                                                                        {this.props.showVisibilitySettings &&
                                                                            <i className={"fa fa-eye " + (this.eyeToggle('totalDaysAbsent'))}
                                                                                onClick={() => this.props.eyeClicked('totalDaysAbsent')}>
                                                                            </i>
                                                                        }
                                                                        <span className="persInfoPtag">{t('fwisd.absences.totalFullDays.totalDaysAbsent')} {item.totalDaysAbsent}</span>
                                                                    </Fragment>
                                                                }
                                                            </div>

                                                            <br />
                                                            {item.schoolSummaryTotalDays &&
                                                                <div className="historyLabel">{item.schoolSummaryTotalDays + ' ' + t('fwisd.absences.totalFullDays.schoolSummaryTotalDaysText') + ' ' + item.schoolName}</div>
                                                            }
                                                            <br />
                                                            <div className="row">
                                                                <div style={{ width: 25 }} className="col-sm-1" ></div>
                                                                <div className="col-md-3 customLabel">{t('fwisd.absences.absencesInSchoolDetails.date', true)}</div>
                                                                <div className="col-md-3 customLabel">{t('fwisd.absences.absencesInSchoolDetails.daily', true)}</div>
                                                                <div style={{ width: 40 }} className="col-sm-1 customLabel">00</div>
                                                                <div style={{ width: 40 }} className="col-sm-1 customLabel">01</div>
                                                                <div style={{ width: 40 }} className="col-sm-1 customLabel">02</div>
                                                                <div style={{ width: 40 }} className="col-sm-1 customLabel">03</div>
                                                                <div style={{ width: 40 }} className="col-sm-1 customLabel">04</div>
                                                                <div style={{ width: 40 }} className="col-sm-1 customLabel">05</div>
                                                                <div style={{ width: 40 }} className="col-sm-1 customLabel">06</div>
                                                                <div style={{ width: 40 }} className="col-sm-1 customLabel">07</div>
                                                                <div style={{ width: 40 }} className="col-sm-1 customLabel">08</div>
                                                                <div style={{ width: 40 }} className="col-sm-1 customLabel">09</div>
                                                            </div>
                                                            {item.absencesInSchoolDetails.map((item, i) => {
                                                                return <Fragment key={i}>
                                                                    <div className="row fileList">
                                                                        <div style={{ width: 25 }} className="col-sm-1" >
                                                                            {this.props.showVisibilitySettings &&
                                                                                <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                                                    onClick={() => this.props.eyeClicked(i)}>
                                                                                </i>
                                                                            }
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <span>{item.date}</span>
                                                                        </div>
                                                                        <div className="col-md-3">
                                                                            <span>{item.daily}</span>
                                                                        </div>
                                                                        <div style={{ width: 40 }} className="col-sm-1">
                                                                            <span>{item._00}</span>
                                                                        </div>
                                                                        <div style={{ width: 40 }} className="col-sm-1">
                                                                            <span>{item._01}</span>
                                                                        </div>
                                                                        <div style={{ width: 40 }} className="col-sm-1">
                                                                            <span>{item._02}</span>
                                                                        </div>
                                                                        <div style={{ width: 40 }} className="col-sm-1">
                                                                            <span>{item._03}</span>
                                                                        </div>
                                                                        <div style={{ width: 40 }} className="col-sm-1">
                                                                            <span>{item._04}</span>
                                                                        </div>
                                                                        <div style={{ width: 40 }} className="col-sm-1">
                                                                            <span>{item._05}</span>
                                                                        </div>
                                                                        <div style={{ width: 40 }} className="col-sm-1">
                                                                            <span>{item._06}</span>
                                                                        </div>
                                                                        <div style={{ width: 40 }} className="col-sm-1">
                                                                            <span>{item._07}</span>
                                                                        </div>
                                                                        <div style={{ width: 40 }} className="col-sm-1">
                                                                            <span>{item._08}</span>
                                                                        </div>
                                                                        <div style={{ width: 40 }} className="col-sm-1">
                                                                            <span>{item._09}</span>
                                                                        </div>

                                                                    </div>
                                                                </Fragment>
                                                            })
                                                            }
                                                        </Fragment>
                                                    })
                                                }
                                            </div>
                                            <br />
                                            <br />
                                        </Fragment>
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
export default Absences;