import fetchApi from "../utilities/fetch";
import fileTransferRemote from "./fileTransferRemote";

import { visits, people } from "../actions";

let visitReqHandler = {
  getVisitsList: (personId) => {
    return (dispatch) => {
      return fetchApi.get(`/visits?person=${personId}`).then((response) => {
        dispatch({
          type: visits.GET_VISITS_LIST,
          payload: {
            id: personId,
            visitsList: response,
          },
        });
      });
    };
  },

  getVisit: (data) => {
    return (dispatch) => {
      fetchApi.get(`/visit?id=${data.visitId}`).then((res) => {
        const responseData = {};
        
        responseData["visit"] = res;

        fetchApi.getFile(`/readFile?id=${res.content}`, true).then((file) => {
          
          const reader = new FileReader();

          reader.addEventListener('loadend', (e) => {
            const content = e.srcElement.result;
            responseData["visit"].htmlSource = content;
            console.log(content);

            dispatch({
              type: visits.GET_VISIT,
              payload: responseData,
              id: data.visitId,
            });
          });
          
          // Start reading the blob as text.
          reader.readAsText(file);

        });
      });
    };
  },

  getVisitFromTempLink: (data) => {
    return fetchApi.get(`/visit?id=${data.visitId}`)
  },

  updateVisitVisibilitySetting: (updateData) => {
    return (dispatch) => {
      return fetchApi
        .post("/updateVisitsVisibility", updateData)
        .then((response) => {
          if (response) {
            dispatch({
              type: visits.UPADATE_VISIT_VISIBILITY_SCHEMA,
              id: updateData.id,
              payload: response.isVisible,
            });
          }
        });
    };
  },
  deleteVisit: (visitObj) => {
    return fetchApi.post("/deleteVisit", visitObj);
  },
};

export default visitReqHandler;
