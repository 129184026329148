import React, { Component, Fragment } from 'react';

class PersonalInfo extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        const filedData = property.split('-');
        const fieldName = filedData[0];
        const fieldId = parseInt(filedData[1]);

        switch (fieldName) {

            case 'studentInfo':
                return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
            case 'itemStudentInfo':
                return this.props.visibilitySchema.studentInfo[fieldName][fieldId] ? 'eyeBlack' : 'eyeGray';
            default:
                return this.props.visibilitySchema.studentInfo[fieldName] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('studentInfo'))}
                                data-key="studentInfo"
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('education.personalInfo.personalInfoTitle')}</h4>
                        {this.props.profile.content &&
                            <div className="profileContent">
                                <div>
                                    {this.props.profile.content.studentInfo.firstName &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('firstName'))}
                                                    onClick={() => this.props.eyeClicked('firstName')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.firstName')} {this.props.profile.content.studentInfo.firstName}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.lastName &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('lastName'))}
                                                    onClick={() => this.props.eyeClicked('lastName')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.lastName')} {this.props.profile.content.studentInfo.lastName}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.gender &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('gender'))}
                                                    onClick={() => this.props.eyeClicked('gender')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.gender')} {this.props.profile.content.studentInfo.gender}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.age &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('age'))}
                                                    onClick={() => this.props.eyeClicked('age')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.age')} {this.props.profile.content.studentInfo.age}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.language &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('language'))}
                                                    onClick={() => this.props.eyeClicked('language')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.language')} {this.props.profile.content.studentInfo.language}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.graduationyear &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('graduationyear'))}
                                                    onClick={() => this.props.eyeClicked('graduationyear')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.graduationYear')} {this.props.profile.content.studentInfo.graduationyear}</span>
                                        </Fragment>
                                    }
                                </div>

                                <div>
                                    {this.props.profile.content.studentInfo.address &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('address'))}
                                                    onClick={() => this.props.eyeClicked('address')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.address')} {this.props.profile.content.studentInfo.address}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.otherid &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('otherid'))}
                                                    onClick={() => this.props.eyeClicked('otherid')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.otherId')} {this.props.profile.content.studentInfo.otherid}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.communityservice &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('communityservice'))}
                                                    onClick={() => this.props.eyeClicked('communityservice')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.communityService')} {this.props.profile.content.studentInfo.communityservice}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.second &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('second'))}
                                                    onClick={() => this.props.eyeClicked('second')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.second')} {this.props.profile.content.studentInfo.second}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.principal &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('principal'))}
                                                    onClick={() => this.props.eyeClicked('principal')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.principal')} {this.props.profile.content.studentInfo.principal}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.grade &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('grade'))}
                                                    onClick={() => this.props.eyeClicked('grade')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.grade')} {this.props.profile.content.studentInfo.grade}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.status &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('status'))}
                                                    onClick={() => this.props.eyeClicked('status')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.status')} {this.props.profile.content.studentInfo.status}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.homeRoom &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('homeRoom'))}
                                                    onClick={() => this.props.eyeClicked('homeRoom')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.homeRoom')} {this.props.profile.content.studentInfo.homeRoom}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.disciplineOfficer &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('disciplineOfficer'))}
                                                    onClick={() => this.props.eyeClicked('disciplineOfficer')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.disciplineOfficer')} {this.props.profile.content.studentInfo.disciplineOfficer}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.schoolMail &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('schoolMail'))}
                                                    onClick={() => this.props.eyeClicked('schoolMail')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.schoolMail')} {this.props.profile.content.studentInfo.schoolMail}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.studentInfo.call &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('call'))}
                                                    onClick={() => this.props.eyeClicked('call')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.call')} {this.props.profile.content.studentInfo.call}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div className="row">
                                    <br />
                                    <div>
                                        {this.props.profile.content && this.props.showVisibilitySettings &&
                                            <i className={"fa fa-eye " + (this.eyeToggle('parents'))}
                                                onClick={() => this.props.eyeClicked('parents')}>
                                            </i>
                                        }
                                        <h5 className="headName">{t('education.personalInfo.emerganceContactsTitle')}</h5>
                                        {this.props.profile.content &&
                                            <div className="profileContent">

                                                <div className="row">
                                                    <div className="col-md-1 customLabel"></div>
                                                    <div className="col-md-3 customLabel">{t('education.personalInfo.emerganceContacts', true)}</div>
                                                    <div className="col-md-2 customLabel">{t('education.personalInfo.primaryPhone', true)}</div>
                                                    <div className="col-md-2 customLabel">{t('education.personalInfo.secondPhone', true)}</div>
                                                    <div className="col-md-2 customLabel">{t('education.personalInfo.thirdPhone', true)}</div>
                                                    {/* <div className="col-md-2 customLabel">Employer Phone</div> */}
                                                    <div className="col-md-2 customLabel">{t('education.personalInfo.homeEmail', true)}</div>
                                                </div>

                                                {this.props.profile.content.studentInfo.itemStudentInfo &&

                                                    this.props.profile.content.studentInfo.itemStudentInfo.map((item, i) => {
                                                        return <Fragment key={i}>

                                                            <div className="row fileList">

                                                                <div className="col-md-1">
                                                                    {this.props.showVisibilitySettings &&
                                                                        <i className={"fa fa-eye " + (this.eyeToggle('itemStudentInfo-' + i))}
                                                                            onClick={() => this.props.eyeClicked('itemStudentInfo', i)}>
                                                                        </i>
                                                                    }
                                                                </div>

                                                                <div className="col-md-3">
                                                                    <span>{item.emergancecontacts}</span>
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <span>{item.primaryphone}</span>
                                                                </div>

                                                                <div className="col-md-2">
                                                                    <span>{item.secondphone}</span>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <span>{item.thirdphone}</span>
                                                                </div>
                                                                {/* <div className="col-md-2">
                                                                    <span>{item.employerphone}</span>
                                                                </div> */}
                                                                <div className="col-md-2">
                                                                    <span>{item.homeemail}</span>
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
export default PersonalInfo;