import React, { Fragment } from 'react';


export const Absences = ({ profiles, t, isGeneratedFromTempLink }) => {   

    const getExpanderStatus = () => {
        console.log(isGeneratedFromTempLink)
       if(isGeneratedFromTempLink){
           return "expand";
       }

       return "collapse";
    }

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('fwisd.absences.title')}</h4>
                <div className="profileContent">
                    {
                        profiles.map((currProfile, i) => {

                            if (currProfile.absences && currProfile.absences.length > 0) {
                                return <Fragment key={i}>
                                    <div className="row">
                                        <div className="col-md-6"> </div>
                                        <div className="col-md-3">{currProfile.exportPersonName}</div>
                                        <div className="col-md-3">{currProfile.providerName}</div>
                                    </div>

                                    {currProfile.absences &&
                                        currProfile.absences.map((absence, i) => {
                                            return <Fragment key={i}>
                                                <div className="row trows">
                                                    {absence.year}
                                                    {/* <div style={{ width: 200 }} className="col-md-1 labTestRowItem periodLabel" data-toggle={getExpanderStatus()} data-target={'#absence' + i}>{absence.year}
                                                    <span  class="glyphicon glyphicon-chevron-down customSpanExpander" />
                                                    </div> */}
                                                </div>

                                                <div id={'absence' + i} className="row">

                                                    <br />
                                                    <div className="row">
                                                        <div className="historyLabel">{t('fwisd.absences.absencesTitle')}</div>
                                                    
                                                    </div>

                                                    {absence && absence.absences &&
                                                        absence.absences.map((item, i) => {
                                                            return <Fragment key={i}>
                                                                <div className="row fileList">
                                                                    <div className="col-md-3">
                                                                        <span>{item.absenceType}</span>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <span>{item.periods}</span>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        })
                                                    }

                                                    <br />
                                                    <div className="row">
                                                        <div className="historyLabel">{t('fwisd.absences.otherMarksTitle')}</div>
                                                    </div>

                                                    {absence && absence.otherMarks &&
                                                        absence.otherMarks.map((item, i) => {
                                                            return <Fragment key={i}>
                                                                <div className="row fileList">

                                                                    <div className="col-md-3">
                                                                        <span>{item.absenceType}</span>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <span>{item.periods}</span>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        })
                                                    }

                                                    <br />
                                                    <div className="row">
                                                        <div className="historyLabel">{}</div>
                                                    </div>

                                                    {absence && absence.absencesInSchool &&
                                                        absence.absencesInSchool.map((item, i) => {
                                                            return <Fragment key={i}>
                                                                {/* <span className="persInfoPtag">{t('fwisd.absences.absencesInSchool.schoolName')} {item.schoolName}</span> */}
                                                                <h4 className="headName"> {item.schoolName} </h4>
                                                                <br />
                                                                <div className="row">
                                                                    {item.totalDaysPossible &&
                                                                        <Fragment>
                                                                            <span className="persInfoPtag">{t('fwisd.absences.totalFullDays.totalDaysPossible')} {item.totalDaysPossible}</span>
                                                                        </Fragment>
                                                                    }
                                                                </div>
                                                                <div className="row">
                                                                    {item.totalDaysAttended &&
                                                                        <Fragment>
                                                                            <span className="persInfoPtag">{t('fwisd.absences.totalFullDays.totalDaysAttended')} {item.totalDaysAttended}</span>
                                                                        </Fragment>
                                                                    }
                                                                </div>
                                                                <div className="row">
                                                                    {item.totalDaysAbsent &&
                                                                        <Fragment>
                                                                            <span className="persInfoPtag">{t('fwisd.absences.totalFullDays.totalDaysAbsent')} {item.totalDaysAbsent}</span>
                                                                        </Fragment>
                                                                    }
                                                                </div>

                                                                <br />
                                                                {item.schoolSummaryTotalDays &&
                                                                    <div className="historyLabel">{item.schoolSummaryTotalDays + ' ' + t('fwisd.absences.totalFullDays.schoolSummaryTotalDaysText') + ' ' + item.schoolName}</div>
                                                                }
                                                                <br />
                                                                <div className="row">
                                                                    <div style={{ width: 120 }} className="col-md-2 customLabel">{t('fwisd.absences.absencesInSchoolDetails.date', true)}</div>
                                                                    <div style={{ width: 120 }} className="col-md-2 customLabel">{t('fwisd.absences.absencesInSchoolDetails.daily', true)}</div>
                                                                    <div style={{ width: 40 }} className="col-md-1 customLabel">00</div>
                                                                    <div style={{ width: 40 }} className="col-md-1 customLabel">01</div>
                                                                    <div style={{ width: 40 }} className="col-md-1 customLabel">02</div>
                                                                    <div style={{ width: 40 }} className="col-md-1 customLabel">03</div>
                                                                    <div style={{ width: 40 }} className="col-md-1 customLabel">04</div>
                                                                    <div style={{ width: 40 }} className="col-md-1 customLabel">05</div>
                                                                    <div style={{ width: 40 }} className="col-md-1 customLabel">06</div>
                                                                    <div style={{ width: 40 }} className="col-md-1 customLabel">07</div>
                                                                    <div style={{ width: 40 }} className="col-md-1 customLabel">08</div>
                                                                    <div style={{ width: 40 }} className="col-md-1 customLabel">09</div>
                                                                </div>
                                                                {item.absencesInSchoolDetails.map((item, i) => {
                                                                    return <Fragment key={i}>
                                                                        <div className="row fileList">
                                        
                                                                            <div style={{ width: 120 }} className="col-md-2">
                                                                                <span>{item.date}</span>
                                                                            </div>
                                                                            <div style={{ width: 120 }} className="col-md-2">
                                                                                <span>{item.daily}</span>
                                                                            </div>
                                                                            <div style={{ width: 40 }} className="col-md-1">
                                                                                <span>{item._00}</span>
                                                                            </div>
                                                                            <div style={{ width: 40 }} className="col-md-1">
                                                                                <span>{item._01}</span>
                                                                            </div>
                                                                            <div style={{ width: 40 }} className="col-md-1">
                                                                                <span>{item._02}</span>
                                                                            </div>
                                                                            <div style={{ width: 40 }} className="col-md-1">
                                                                                <span>{item._03}</span>
                                                                            </div>
                                                                            <div style={{ width: 40 }} className="col-md-1">
                                                                                <span>{item._04}</span>
                                                                            </div>
                                                                            <div style={{ width: 40 }} className="col-md-1">
                                                                                <span>{item._05}</span>
                                                                            </div>
                                                                            <div style={{ width: 40 }} className="col-md-1">
                                                                                <span>{item._06}</span>
                                                                            </div>
                                                                            <div style={{ width: 40 }} className="col-md-1">
                                                                                <span>{item._07}</span>
                                                                            </div>
                                                                            <div style={{ width: 40 }} className="col-md-1">
                                                                                <span>{item._08}</span>
                                                                            </div>
                                                                            <div style={{ width: 40 }} className="col-md-1">
                                                                                <span>{item._09}</span>
                                                                            </div>

                                                                        </div>
                                                                    </Fragment>
                                                                })
                                                                }
                                                            </Fragment>
                                                        })
                                                    }
                                                </div>
                                                <br />
                                                <br />
                                            </Fragment>
                                        })
                                    }
                                </Fragment>
                            }
                        })
                    }
                </div>
            </div>
        </div>
    )
    
}