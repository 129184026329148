import React from 'react';
import { useTranslation } from 'react-i18next';

export const PersonalInfo = ({ formik }) => {
    const { t } = useTranslation();
    return (
        <div className="tab-warper">
            <h4 className="headName">{t('myChart.personalInfo.personalInfoTitle')}</h4>

            <div className="profileContent">

                {/* START ROW */}
                <div className="row">
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="firstName">{t('myChart.personalInfo.firstName')}</label>
                            <input
                                id="firstName"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.firstName')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="lastName">{t('myChart.personalInfo.lastName')}</label>
                            <input
                                id="lastName"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.lastName')}
                            />
                        </div>
                    </div>
                </div>
                {/* END ROW */}

                {/* START ROW */}
                <div className="row">
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="dateOfBirth">{t('myChart.personalInfo.dateOfBirth')}</label>
                            <input
                                id="dateOfBirth"
                                className="form-control"
                                type="date"
                                {...formik.getFieldProps('personalInfo.dateOfBirth')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="race">{t('myChart.personalInfo.demographicsRace')}</label>
                            <input
                                id="race"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.race')}
                            />
                        </div>
                    </div>
                </div>
                {/* END ROW */}

                {/* START ROW */}
                <div className="row">
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="phoneNumber">{t('myChart.personalInfo.phoneNumber')}</label>
                            <input
                                id="phoneNumber"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.phoneNumber')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="address">{t('myChart.personalInfo.address')}</label>
                            <textarea
                                id="address"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.address')}
                            ></textarea>
                        </div>
                    </div>
                </div>
                {/* END ROW */}

                {/* START ROW */}
                <div className="row">
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="zipCode">{t('myChart.personalInfo.zipCode')}</label>
                            <input
                                id="zipCode"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.zipCode')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="emergencyContactName">{t('myChart.personalInfo.emergencyContactName')}</label>
                            <input
                                id="emergencyContactName"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.emergencyContactName')}
                            />
                        </div>
                    </div>
                </div>
                {/* END ROW */}

                {/* START ROW */}
                <div className="row">
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="emergencyContactPhone">{t('myChart.personalInfo.emergencyContactPhoneNumber')}</label>
                            <input
                                id="emergencyContactPhone"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.emergencyContactPhone')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="insuranceName">{t('myChart.personalInfo.insuranceName')}</label>
                            <input
                                id="insuranceName"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.insuranceName')}
                            />
                        </div>
                    </div>
                </div>
                {/* END ROW */}

                {/* START ROW */}
                <div className="row">
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="insuranceType">{t('myChart.personalInfo.insuranceType')}</label>
                            <input
                                id="insuranceType"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.insuranceType')}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="insuranceNumber">{t('myChart.personalInfo.insuranceNumber')}</label>
                            <input
                                id="insuranceNumber"
                                className="form-control"
                                {...formik.getFieldProps('personalInfo.insuranceNumber')}
                            />
                        </div>
                    </div>
                </div>
                {/* END ROW */}

            </div>

        </div >
    )
}