import React, { Component, Fragment } from 'react';

class AssignmentsAndGrades extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {
        if (property === 'assignmentsAndGrades') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.assignmentsAndGrades[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('assignmentsAndGrades'))}
                                data-key="assignmentsAndGrades"
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('fwisd.assignmentsAndGrades.title')}</h4>
                        {this.props.profile.content &&
                            <div className="profileContent">

                                {this.props.profile.content.assignmentsAndGrades &&
                                    this.props.profile.content.assignmentsAndGrades.map((assignment, i) => {
                                        return <Fragment key={i}>
                                            <div className="row trows">
                                                <div style={{ width: 25 }} className="col-md-1">
                                                    {this.props.showVisibilitySettings &&
                                                        <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                            onClick={() => this.props.eyeClicked(i)}>
                                                        </i>
                                                    }
                                                </div>
                                                {!this.props.tempLinkCodeAccess &&
                                                    <div className="col-md-4 labTestRowItem periodLabel" data-toggle="collapse" data-target={'#assignment' + i}>{assignment.assignmentName}
                                                        <span class="glyphicon glyphicon-chevron-down customSpanExpander" />
                                                    </div>
                                                }
                                                {this.props.tempLinkCodeAccess &&
                                                    <div className="col-md-4 labTestRowItem periodLabel" data-toggle="expand" data-target={'#assignment' + i}>{assignment.assignmentName}
                                                    </div>
                                                }
                                            </div>

                                            <div id={'assignment' + i} className={this.props.tempLinkCodeAccess ? "row labTest-warper expand" : "row labTest-warper collapse"}>

                                                <div className="row">
                                                    <div className="col-md-1 customLabel"></div>
                                                    <div className="col-md-2 customLabel">{t('fwisd.assignmentsAndGrades.assigment', true)}</div>
                                                    <div className="col-md-1 customLabel">{t('fwisd.assignmentsAndGrades.points', true)}</div>
                                                    <div className="col-md-1 customLabel">{t('fwisd.assignmentsAndGrades.grade', true)}</div>
                                                    <div className="col-md-2 customLabel">{t('fwisd.assignmentsAndGrades.assigned', true)}</div>
                                                    <div className="col-md-2 customLabel">{t('fwisd.assignmentsAndGrades.due', true)}</div>
                                                    <div className="col-md-3 customLabel">{t('fwisd.assignmentsAndGrades.category', true)}</div>
                                                </div>

                                                {assignment && assignment.assignmentDetails.map((item, i) => {
                                                    return <Fragment key={i}>
                                                        <div className="row fileList">
                                                            <div className="col-md-1">
                                                                {this.props.showVisibilitySettings &&
                                                                    <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                                        onClick={() => this.props.eyeClicked(i)}>
                                                                    </i>
                                                                }
                                                            </div>
                                                            <div className="col-md-2">
                                                                <span>{item.assigment}</span>
                                                            </div>
                                                            <div className="col-md-1">
                                                                <span>{item.points}</span>
                                                            </div>
                                                            <div className="col-md-1">
                                                                <span>{item.grade}</span>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <span>{item.assigned}</span>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <span>{item.due}</span>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <span>{item.category}</span>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                })
                                                }

                                            </div>
                                            <br />
                                            <br />
                                        </Fragment>
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
export default AssignmentsAndGrades;