import React from 'react';
import { FieldArray } from 'formik';
import { FamilyHistory } from './FamilyHistory';
import { MedicalHistory } from './MedicalHistory';
import { SurgicalHistory } from './SurgicalHistory';
import { useTranslation } from 'react-i18next';

export const History = ({ formik }) => {
    const { t } = useTranslation();
    
    return (
        <div className="tab-warper">
            <h4 className="headName">{t('myChart.history.historyTitle')}</h4>
            <FieldArray
                name="history.familyHistory"
                render={arrayHelpers => <FamilyHistory formik={formik} arrayHelpers={arrayHelpers} />}
            />
            <FieldArray
                name="history.medicalHistory"
                render={arrayHelpers => <MedicalHistory formik={formik} arrayHelpers={arrayHelpers} />}
            />


            {/* START SOCIAL HISTORY */}
            <h5 className="headName">{t('myChart.history.socialHistoryTitle')}</h5>

            <div className="row">
                <div className="profileContent">
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="alcoholUse">{t('myChart.history.alcoholUse')}</label>
                            <input
                                id="alcoholUse"
                                className="form-control"
                                {...formik.getFieldProps(`history.socialHistory.alcoholUse.alcoholUse`)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="ouncesWeek">{t('myChart.history.ouncesWeek')}</label>
                            <input
                                id="ouncesWeek"
                                className="form-control"
                                {...formik.getFieldProps(`history.socialHistory.alcoholUse.ouncesWeek`)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="profileContent">
                    <div className="col-md-3 customLabel">
                        <div className="form-group">
                            <label htmlFor="smokingTobaccoUse">{t('myChart.history.smokingTobaccoUse')}</label>
                            <input
                                id="smokingTobaccoUse"
                                className="form-control"
                                {...formik.getFieldProps(`history.socialHistory.smokingTobacco.smokingTobaccoUse`)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 customLabel">
                        <div className="form-group">
                            <label htmlFor="yearsSmoked">{t('myChart.history.yearsSmoked')}</label>
                            <input
                                id="yearsSmoked"
                                className="form-control"
                                {...formik.getFieldProps(`history.socialHistory.smokingTobacco.yearsSmoked`)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 customLabel">
                        <div className="form-group">
                            <label htmlFor="smokingTobaccoTypes">{t('myChart.history.tobaccoTypes')}</label>
                            <input
                                id="smokingTobaccoTypes"
                                className="form-control"
                                {...formik.getFieldProps(`history.socialHistory.smokingTobacco.smokingTobaccoTypes`)}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 customLabel">
                        <div className="form-group">
                            <label htmlFor="packsDay">{t('myChart.history.packsPerDay')}</label>
                            <input
                                id="packsDay"
                                className="form-control"
                                {...formik.getFieldProps(`history.socialHistory.smokingTobacco.packsDay`)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="profileContent">
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="smokelessTobaccoUse">{t('myChart.history.smokelessTobacco')}</label>
                            <input
                                id="smokelessTobaccoUse"
                                className="form-control"
                                {...formik.getFieldProps(`history.socialHistory.smokelessTobacco.smokelessTobaccoUse`)}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 customLabel">
                        <div className="form-group">
                            <label htmlFor="smokelessTobaccoTypes">{t('myChart.history.smokelessTobaccoTypes')}</label>
                            <input
                                id="smokelessTobaccoTypes"
                                className="form-control"
                                {...formik.getFieldProps(`history.socialHistory.smokelessTobacco.smokelessTobaccoTypes`)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {/* END SOCIAL HISTORY */}

            {/* START SURGICAL HISTORY */}
            <FieldArray
                name="history.surgicalHistory"
                render={arrayHelpers => <SurgicalHistory formik={formik} arrayHelpers={arrayHelpers} />}
            />
            {/* END SURGICAL HISTORY */}
        </div>
    );
}