import React, { Component, Fragment } from 'react';

class ChildInfoGeneral extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        if (property === 'childInfoGeneral') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.childInfoGeneral[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('childInfoGeneral'))}
                                data-key="childInfoGeneral"
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('fwisd.childInfoGeneral.title')}</h4>
                        {this.props.profile.content &&
                            <div className="profileContent">
                                <div>
                                    {this.props.profile.content.childInfoGeneral.firstName &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('firstName'))}
                                                    onClick={() => this.props.eyeClicked('firstName')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoGeneral.firstName')} {this.props.profile.content.childInfoGeneral.firstName}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoGeneral.lastName &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('lastName'))}
                                                    onClick={() => this.props.eyeClicked('lastName')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoGeneral.lastName')} {this.props.profile.content.childInfoGeneral.lastName}</span>
                                        </Fragment>
                                    }
                                </div>

                                <div>
                                    {this.props.profile.content.childInfoGeneral.birthDate &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('birthDate'))}
                                                    onClick={() => this.props.eyeClicked('birthDate')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoGeneral.birthDate')} {this.props.profile.content.childInfoGeneral.birthDate}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoGeneral.localStudentId &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('localStudentId'))}
                                                    onClick={() => this.props.eyeClicked('localStudentId')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoGeneral.localStudentId')} {this.props.profile.content.childInfoGeneral.localStudentId}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoGeneral.tccId &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('tccId'))}
                                                    onClick={() => this.props.eyeClicked('tccId')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('education.personalInfo.tccId')} {this.props.profile.content.childInfoGeneral.tccId}</span>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
export default ChildInfoGeneral;