import React, { Fragment, Component } from 'react';
import { FieldArray } from 'formik';
import fileTypes from '../../../../utilities/fileTypes';
import fileTransferReqHandler from '../../../../api/fileTransferRemote';
import PdfViewer from '../../../PdfViewer';
import { withTranslation, useTranslation, Trans } from 'react-i18next';

export const Files = ({ formik, isNew }) => {

    const openFile = (fileId, fileType) => {
        state.openImg = ''
        state.openFileType = ''

        fileTransferReqHandler.openFile(fileId).then(res => {

            const fileUrl = URL.createObjectURL(res);

            switch (fileType) {

                case 'image/jpeg':
                case 'image/png':
                    state.openImg = fileUrl
                    state.openFileType = fileType
                    break;
                case 'application/pdf':
                    state.openPdf = fileUrl
                    state.openFileType = fileType
                    break;
                default: break;
            }
        });
    }

    const state = {
        tempFileSize: '',
        openFileType: '',
        openImg: null,
        allDeleted: false
    }

    const { t } = useTranslation();

    return (

        // render() {
        //     const { formik, isNew, t } = this.props;

        //     return (
        <Fragment>
            {
                !isNew && formik.values.editFiles && formik.values.editFiles.length !== 0 && formik.values.editFiles.filter(f => !f.deleted).length !== 0 &&
                <div className="tab-warper">
                    <h4 className="headName">{t('myChart.files.filesTitle')}</h4>
                    <div className="profileContent">

                        <div className="row">
                            <div className="col-md-4 customLabel">{t('myChart.files.filesTitle')}</div>
                            <div className="col-md-4 customLabel">{t('myChart.files.type')}</div>
                            <div className="col-md-2 customLabel">{t('myChart.files.open')}</div>
                            <div className="col-md-2 customLabel">{t('myChart.files.delete')}</div>
                        </div>
                        <FieldArray
                            name="editFiles"
                            render={arrayHelpers => (
                                formik.values.editFiles.map((file, i) => {
                                    if (file.deleted) return null;

                                    return <Fragment key={i}>
                                        <div className="row fileList">

                                            <div className="col-md-4">
                                                <input
                                                    className="form-control"
                                                    {...formik.getFieldProps(`editFiles[${i}].onlyName`)}
                                                    onChange={(event) => {
                                                        formik.setFieldValue(`editFiles[${i}]`, {
                                                            ...formik.values.editFiles[i],
                                                            onlyName: event.target.value,
                                                            changed: true
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-4">
                                                <span>{fileTypes.formatFileTypes(file.mimetype)}</span>
                                            </div>

                                            <div className="col-md-2">
                                                {!fileTypes.unreadableFormat(file.mimetype) &&
                                                    <button
                                                        type="button"
                                                        className="btn btn-default editRemove"
                                                        data-toggle="modal"
                                                        data-target="#fileModal"
                                                        onClick={() => openFile(file.id, file.mimetype)}
                                                    >{t('myChart.files.open')}</button>
                                                }

                                                {fileTypes.unreadableFormat(file.mimetype) &&
                                                    <button
                                                        type="button"
                                                        className="btn btn-default editRemove"
                                                    // onClick={this.saveFile.bind(this, this.state.currFileNames[i].id, i)}
                                                    >{t('myChart.files.save')}</button>
                                                }
                                            </div>

                                            <div className="col-md-2">
                                                <button type="button" className="btn btn-danger editRemove"
                                                    onClick={() => {
                                                        formik.values.editFiles[i] = {
                                                            ...formik.values.editFiles[i],
                                                            deleted: true
                                                        }

                                                        //patch for fireing formik changed event
                                                        formik.setFieldValue('', {});
                                                        // formik.setFieldValue(`editFiles[${i}]`,
                                                        //     {
                                                        //         ...formik.values.editFiles[i],
                                                        //         deleted: true
                                                        //     })
                                                    }}
                                                >
                                                    <span>&times;</span>
                                                </button>
                                            </div>

                                        </div>
                                    </Fragment>
                                })
                            )}
                        />
                    </div>

                </div>
            }
            <div className="tab-warper">
                <h4 className="headName">{t('myChart.files.filesTitle')}</h4>
                <div className="profileContent">
                    <FieldArray
                        name="files"
                        render={arrayHelpers => (
                            <Fragment>
                                <div className="row buttonFromHead">
                                    <div className="col-md-6 customLabel">
                                        <button type="button" className="btn" onClick={() => arrayHelpers.push({})}>
                                            <i className="fa fa-plus"></i> {t('myChart.files.addFileButton')}
                                        </button>
                                        <span className="input-group">{t('myChart.files.maxSizeUpload')}</span>
                                    </div>
                                </div>
                                {
                                    formik.values.files && formik.values.files.length !== 0 &&
                                    formik.values.files.map((file, i) => {
                                        return <Fragment key={i}>
                                            <div className="row">

                                                <div className="col-md-1">
                                                    <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelpers.remove(i)} >
                                                        <span>&times;</span>
                                                    </button>
                                                </div>

                                                <div className="col-md-6 input-group fileWrapper">
                                                    <span className="form-control">{formik.values.files[i].name}</span>
                                                    <div className="input-group-btn">
                                                        <label htmlFor={'file-' + i} className="btn btn-default">{t('myChart.files.browseButton')}</label>
                                                        <input
                                                            id={'file-' + i}
                                                            type="file"
                                                            onChange={event => {
                                                                const file = event.target.files[0];
                                                                const oneMBSize = 10048487;
                                                                if (file && file.size <= oneMBSize) {
                                                                    formik.setFieldValue(`files[${i}]`, event.currentTarget.files[0]);
                                                                } else {
                                                                    state.tempFileSize = (file.size / 1024 / 1024).toFixed(2)
                                                                    document.getElementById('fileSizeBtn').click();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-5"></div>

                                            </div>
                                        </Fragment>
                                    })
                                }
                                {/* <!-- Modal --> */}
                                <div className="modal fade" id="fileSizeModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                            <div className="modal-body">
                                                <Trans
                                                    i18nKey="myChart.files.largerThanMessage"
                                                    default="Selected file size ({{tempFileSize}}MB) is larger than allowed file size of 10MB."
                                                    values={{ tempFileSize: state.tempFileSize }}
                                                />
                                            </div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-default" data-dismiss="modal">OK</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Open File Modal --> */}
                                <div className="modal fade" id="fileModal" tabIndex="-1" role="dialog" aria-labelledby="myFileModalLabel">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                            </div>
                                            <div className="modal-body">
                                                {state.openFileType === 'application/pdf' &&
                                                    <PdfViewer file={state.openPdf}></PdfViewer>
                                                }
                                                {state.openFileType.startsWith('image') &&
                                                    // <object data={ this.state.openImg } type="image/jpg">
                                                    //     <embed src={ this.state.openImg } type="image/jpg" />
                                                    // </object>
                                                    <img src={state.openImg} id="imagePreview" />
                                                }

                                            </div>
                                            <div className="modal-footer">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    />
                </div>
            </div >
        </Fragment>
    );
}

export default withTranslation()(Files);