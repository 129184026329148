import toastr from 'toastr';
toastr.options.closeButton = true;
toastr.options.timeOut = 20000;

function printError(e) {

    const errorsArray = e.message.split(',');

    for(const err of errorsArray) {
        toastr.error(err);
    }
};

export default {
    printError,
};