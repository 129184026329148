import React, { Component } from 'react';
import providerReqHandler from '../../api/providerRemote';
import profileReqHandler from '../../api/profileRemote';
import providerConfigReqHandler from '../../api/providersConfigRemote';
import authentication from '../../utilities/authentication';
import toastr from 'toastr';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { CheckJobsDone } from '../../utilities/checkJobsDone';
import { connect } from 'react-redux';
toastr.options.closeButton = true;

class CreateProviderProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            providersType: [],
            providerType: {
                id: '',
                name: '',
            },
            fields: {},
            errors: {},
            providers: [],
            selectedProviderId: '',
            personId: '',
            isSelectType: true
        };

        this.providerSelect = this.providerSelect.bind(this);
    }

    componentDidMount() {
        this.loadProvidersList();
        this.loadProvidersTypeList();
    }

    providerSelect(event) {

        let providerId = event.target.value;
        if (providerId === 'default') {
            providerId = '';
        }
        this.setState({ selectedProviderId: providerId });
    }

    onFormSubmit(values, resetForm) {
        const data = {
            providerId: values.selectedProviderId,
            personId: authentication.getPerson().id,
            username: values.username,
            password: values.password
        };

        profileReqHandler.addProfileFromProvider(data).then(res => {

            if (res && res.success) {
                toastr.options.timeOut = 15000;
                toastr.success('Profile create request sent successful. You can check create status in Jobs.');
                CheckJobsDone.setJobs(authentication.getPerson().id);
                resetForm({})
            }
        });
    }

    onNewProviderFormSubmit(value, resetForm) {
        const providerData = {
            url: value.providerUrl,
            username: value.newProviderUsername,
            password: value.newProviderPassword
        };

        providerReqHandler.requestNewProvider(providerData).then(res => {

            if (res) {
                toastr.success('Provider request sent successful.');

                resetForm({});
            }
        });
    }

    filterActiveProviders(provider, i) {
        if (provider.status) {
            return <option key={i} value={provider.id} >{provider.name.toUpperCase()}</option>
        }
    }

    loadProvidersList = () => {
        providerReqHandler.getProviders(true).then(res => {
            if (res) {
                this.setState({ providers: res });
            }
        })

    }

    providersTypeSelect = (event) => {

        const providerType = event.target.value;
        if (providerType === 'default') {
            this.setState({
                isSelectType: false,
            })
        } else {
            const type = this.state.providersType.find((pr) => {
                return pr.id === providerType;
            })
            this.setState({
                providerType: {
                    id: providerType,
                    name: type.name,
                },
                isSelectType: true,
            })
        }

    }

    loadProvidersTypeList = () => {
        this.props.loadProvidersTypeList().then(res => {
            if (res) {
                this.setState({ providersType: res });
            }
        });
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <Formik
                    validateOnChange={false}
                    validateOnBlur={true}
                    initialValues={{
                        selectedProviderId: '',
                        username: '',
                        password: ''
                    }}
                    validationSchema={Yup.object({
                        selectedProviderId: Yup.string()
                            .required('Not Selected provider.'),
                        username: Yup.string()
                            .required('Field cannot be empty.'),
                        password: Yup.string()
                            .required('Field cannot be empty.')
                    })}
                    onSubmit={(values, { resetForm }) => {
                        this.onFormSubmit(values, resetForm)
                    }}

                >
                    {formik =>
                        <form className="form-horizontal" onSubmit={formik.handleSubmit} >
                            {this.state.isSelectType &&
                                <div className="container">
                                    <div className="container"></div>
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-6 col-xs-8">
                                            <label htmlFor="typeProviders">{t('addProfile.selectProviderField')}</label>
                                            <Field as="select" name="selectedProviderId" className="form-control">
                                                <option value="default" >{t('addProfile.dropDownDefaultValue')}</option>
                                                {this.state.providers.length > 0 &&
                                                    this.state.providers.map((provider, i) => {
                                                        return this.filterActiveProviders(provider, i);
                                                    })
                                                }
                                            </Field>
                                            {formik.touched.selectedProviderId && formik.errors.selectedProviderId ? (
                                                <span className="inputErrorText">{formik.errors.selectedProviderId}</span>
                                            ) : null}
                                        </div>
                                        <div className="col-lg-4 col-sm-6 col-xs-8"></div>
                                        <div className="col-lg-4 col-sm-4 col-xs-4"></div>
                                    </div>
                                </div>
                            }

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-xs-8" id="createProfProvFormWrapper">
                                    <div className="form-group">
                                        <label className="control-label col-sm-4" htmlFor="newProviderUsername">{t('addProfile.username')}</label>
                                        <div className="col-sm-8">
                                            <input
                                                className="form-control"
                                                {...formik.getFieldProps('username')}
                                            />
                                            {formik.touched.username && formik.errors.username ? (
                                                <span className="inputErrorText">{formik.errors.username}</span>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label className="control-label col-sm-4" htmlFor="newProviderPassword">{t('addProfile.password')}</label>
                                        <div className="col-sm-8">
                                            <input
                                                type="password"
                                                className="form-control"
                                                {...formik.getFieldProps('password')}
                                            />
                                            {formik.touched.password && formik.errors.password ? (
                                                <span className="inputErrorText">{formik.errors.password}</span>
                                            ) : null}
                                        </div>
                                    </div>



                                    <div className="text-right">
                                        <input type="submit" className="btn btn-primary" value={t('addProfile.createProfileButton')} />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-xs-8"></div>
                                <div className="col-lg-4 col-sm-4 col-xs-4"></div>
                            </div>
                        </form>
                    }
                </Formik>
                <div className="row marginTop150">
                    <h2>{t('addProfile.newProviderTitle')}</h2>
                    <p>{t('addProfile.newProviderDescription')}</p>
                    <button
                        type="button"
                        className="btn btn-default"
                        data-toggle="collapse"
                        data-target="#addProvider"
                    >{t('addProfile.providerDetailsButton')}</button>

                    <div id="addProvider" className="collapse">
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={true}
                            initialValues={{
                                providerUrl: '',
                                newProviderUsername: '',
                                newProviderPassword: ''
                            }}
                            validationSchema={Yup.object({
                                newProviderUsername: Yup.string()
                                    .required('Field cannot be empty.'),
                                newProviderPassword: Yup.string()
                                    .required('Field cannot be empty.'),
                                providerUrl: Yup.string()
                                    .required('Field cannot be empty.')
                            })}
                            onSubmit={(values, { resetForm }) => {
                                this.onNewProviderFormSubmit(values, resetForm)
                            }}
                        >
                            {
                                formik =>
                                    <form className="form-horizontal" onSubmit={formik.handleSubmit} >

                                        <div className="col-md-5">

                                            <div className="form-group">
                                                <label className="control-label col-sm-4" htmlFor="providerUrl">{t('addProfile.selectProviderField')}</label>
                                                <div className="col-sm-8">
                                                    <select className="form-control" id="providers" onChange={this.providersTypeSelect}>
                                                        <option value="default" >{t('addProfile.dropDownDefaultValue')}</option>
                                                        {this.state.providersType.length > 0 &&
                                                            this.state.providersType.map((providersType, i) => {
                                                                return <option key={providersType.id} value={providersType.id} >{providersType.name.toUpperCase()}</option>
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="control-label col-sm-4" htmlFor="providerUrl">{t('addProfile.website')}</label>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps('selectedProviderId')}
                                                    />
                                                    {formik.touched.selectedProviderId && formik.errors.selectedProviderId ? (
                                                        <span className="inputErrorText">{formik.errors.selectedProviderId}</span>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label col-sm-4" htmlFor="username">{t('addProfile.username')}</label>
                                                <div className="col-sm-8">
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps('newProviderUsername')}
                                                    />
                                                    {formik.touched.newProviderUsername && formik.errors.newProviderUsername ? (
                                                        <span className="inputErrorText">{formik.errors.newProviderUsername}</span>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="control-label col-sm-4" htmlFor="password">{t('addProfile.password')}</label>
                                                <div className="col-sm-8">
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        {...formik.getFieldProps('newProviderPassword')}
                                                    />
                                                    {formik.touched.newProviderPassword && formik.errors.newProviderPassword ? (
                                                        <span className="inputErrorText">{formik.errors.newProviderPassword}</span>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="text-right">
                                                <input type="submit" className="btn btn-primary" value={t('addProfile.sendRequestButton')} />
                                            </div>
                                        </div>

                                        <div className="col-md-5"></div>

                                    </form>
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        );
    }
};
const mapStateToProps = state => {
    return {
        providersType: state.providers.types,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProvidersTypeList: () => dispatch(providerConfigReqHandler.getTypeProviders()),
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CreateProviderProfile));