import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {
    GlobalSelectors,
} from './FwisdProviderForms';
import * as Yup from 'yup';


class MyChartProviderConfig extends Component {
    initValues = {
        name: '',
        providerType: '',
        id: '',
        configuration: {
            loginUrl: '',

            selectorUsername: '',
            selectorPassword: '',
            selectorSubmitBtn: '',

            loginErrorSelector: '',

            middleUrl: '',

            selectorCheck: '',
            selectorGetUsers: '',
            selectorGetChild: '',
            selectorAgree: '',

            selectorClickBasicUser: '',
            selectorPageWait: '',

            selectorLogOut: '',
        },
    }

    constructor(props) {
        super(props);
        this.state = {
            notName: 'true',
        }
    }

    componentDidMount() {
        if (this.props.provider.id && this.props.provider.name.indexOf('copy_') !== 0) {
            this.setState({
                notName: false
            });
        }
    }

    handleSubmitForm = (values) => {
        Object.keys(values.configuration).map((key) => {
            if (values.configuration[key] === '') {
                values.configuration[key] = null;
            }
        });

        if (values.name === '') {
            values.name = null;
        }

        if (values.providerType === '') {
            values.providerType = null;
        }

        if (values.id === '') {
            values.id = null;
        }

        this.props.handleSubmit(values);
    }


    render() {
        const initValue = { ...this.initValues, ...this.props.provider };
        const { t } = this.props;
        return (
            <Formik
                validateOnChange={false}
                validateOnBlur={true}
                initialValues={initValue}
                validationSchema={Yup.object({
                    configuration: Yup.object({
                        loginUrl: Yup.string()
                            .required(t('validationErrors.fieldEmpty'))
                            .matches(new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/), t('validationErrors.invalidField'))
                    })
                })}
                enableReinitialize={true}
                onSubmit={values => this.handleSubmitForm(values)}
            >
                {
                    formik => (
                        <form className='create-profile-form' onSubmit={formik.handleSubmit}>

                            {this.state.notName &&

                                <div className='tab-warper'>
                                    <h4 className='headName'>{t('myChartProviders.nameProviderTitle')}</h4>
                                    <div className='profileContent'>

                                        <div className='row'>
                                            <div className='col-md-6 customLabel'>
                                                <div className='form-group'>
                                                    <label htmlFor='nameProvider'>{t('myChartProviders.nameLabel')}</label>
                                                    <input
                                                        className='form-control'
                                                        {...formik.getFieldProps('name')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <GlobalSelectors formik={formik} t={t} />                          
                            <div className="text-right">
                                <input type="submit" className="btn btn-primary btnSaveClose" value={t('myChartProviders.saveButton')} />
                                <Link to='/admin' className='btn btn-default btnSaveClose' >{t('myChartProviders.cancelButton')}</Link>
                            </div>
                        </form>
                    )}
            </Formik>
        )
    }
}

export default MyChartProviderConfig;