import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const Tests = ({ formik, t }) => {
    return (
        <FieldArray
            name="healthInfo.tests"
            render={
                arrayHelper => (
                    <Fragment>
                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayHelper.push({
                                    schoolYear: '',
                                    date: '',
                                    school: '',
                                    examinedby: '',
                                    referredby: '',
                                    tests: []
                                })}>
                                    <i className="fa fa-plus"></i> {t('education.healthInfo.tests.addTestButton')}
                                </button>
                            </div>
                        </div>

                        <div className="profileContent">
                            {
                                formik.values.healthInfo.tests && formik.values.healthInfo.tests.map((test, i) => {
                                    return <FieldArray
                                        name={`healthInfo.tests[${i}].tests`}
                                        render={
                                            testsArrayHelper => (
                                                <Fragment key={i}>
                                                    <div className="row">
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'schoolyear-' + i}>{t('education.shared.schoolYear')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`healthInfo.medications[${i}].schoolyear`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'date-' + i}>{t('education.shared.date')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    type="date"
                                                                    {...formik.getFieldProps(`healthInfo.medications[${i}].date`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'school-' + i}>{t('education.shared.school')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`healthInfo.medications[${i}].school`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'examinedby-' + i}>{t('education.shared.examinedBy')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`healthInfo.medications[${i}].examinedby`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'referredby-' + i}>{t('education.shared.referredBy')}</label>
                                                                <input
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`healthInfo.medications[${i}].referredby`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="testDetailsDiv">
                                                                <button type="button" className="btn" onClick={() => testsArrayHelper.push({
                                                                    columnName1: '',
                                                                    val1: '',
                                                                    columnName2: '',
                                                                    val2: ''
                                                                })}>
                                                                    <i className="fa fa-plus"></i> {t('education.healthInfo.tests.addTestResultsButton')}
                                                            </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label>{t('education.shared.remove')}</label>
                                                            <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelper.remove(i)} >
                                                                <span>&times;</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        {
                                                            formik.values.healthInfo.tests[i].tests && formik.values.healthInfo.tests[i].tests.map((testDetail, j) => {
                                                                return <Fragment key={j}>
                                                                    <div className="row">
                                                                        <div className="col-md-4 customLabel">
                                                                            <div className="form-group">
                                                                                <label htmlFor={'columnName1-' + j}>{t('education.healthInfo.tests.testName')}</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    {...formik.getFieldProps(`healthInfo.medications[${i}].tests[${j}].columnName1`)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 customLabel">
                                                                            <div className="form-group">
                                                                                <label htmlFor={'val1-' + j}>{t('education.healthInfo.tests.testResult')}</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    {...formik.getFieldProps(`healthInfo.medications[${i}].tests[${j}].val1`)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 customLabel">
                                                                            <div className="form-group">
                                                                                <label htmlFor={'columnName2-' + j}>{t('education.healthInfo.tests.testName2')}</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    {...formik.getFieldProps(`healthInfo.medications[${i}].tests[${j}].columnName2`)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 customLabel">
                                                                            <div className="form-group">
                                                                                <label htmlFor={'val2-' + j}>{t('education.healthInfo.tests.testResult2')}</label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    {...formik.getFieldProps(`healthInfo.medications[${i}].tests[${j}].val2`)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                            <label>{t('education.shared.remove')}</label>
                                                                            <button type="button" className="btn btn-danger editRemove" onClick={() => testsArrayHelper.remove(j)} >
                                                                                <span>&times;</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            })
                                                        }
                                                    </div>
                                                </Fragment>
                                            )
                                        }
                                    />
                                })
                            }
                        </div>
                    </Fragment>

                )
            }
        />
    );
}