import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import providerReqHandler from '../../api/providerRemote';
import providerCredentialsReqHandler from '../../api/providerCredentialsRemote';
import toastr from 'toastr';
import profileReqHandler from '../../api/profileRemote';
import { Formik } from 'formik';
import * as Yup from 'yup';
toastr.options.closeButton = true;

class VendorUpdate extends Component {
    formValues;

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            providerCredentials: {
                id: '',
                username: ''
            },
            providers: [],
            btnUpdateDisabled: false,
        };

        this.updateProfileData = this.updateProfileData.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount() {
        console.log(this.props);
        this.setState({
            fields: {
                username: this.props.providerCredentials.username,
                password: ''
            }
        })

        providerReqHandler.getProviders().then(res => {

            if (res) {
                this.setState({ providers: res });
            }
        });
    }

    componentDidUpdate(prevProps) {

        const newProps = this.props;

        if (prevProps.providerCredentials.id !== newProps.providerCredentials.id) {

            this.setState({ providerCredentials: newProps.providerCredentials });
            let fields = this.state.fields;
            fields = { ...fields, username: newProps.providerCredentials.username };
            this.setState({ fields: fields });
        }

        if (prevProps.lastUpdate !== newProps.lastUpdate) {
            this.setState({ btnUpdateDisabled: false });
        }
    }

    updateProfileData() {

        const updateData = {            
            providerId: this.props.providerId,
            profileId: this.props.profileId,
            profileDetailId: this.props.profileDetailId,
            // personId: this.props.personId
        };

        document.getElementById('updCloseBtn').click();

        profileReqHandler.updateVendorProfile(updateData).then(res => {

            if (res.success) {
                this.setState({ btnUpdateDisabled: true });

                toastr.options.timeOut = 15000;
                toastr.success(this.props.t('myChart.vendor.success'));
            }
        });
    }

    onFormSubmit() {

        const credentialsData = {
            providerCredId: this.state.providerCredentials.id,
            username: this.formValues.username,
            password: this.formValues.password
        };

        providerCredentialsReqHandler.updateProviderCredentials(credentialsData).then(res => {

            if (res) {
                toastr.info(this.props.t('myChart.vendor.credentialsUpdated'));

                const fields = this.state.fields;
                fields.password = '';
                this.setState({ fields });

                document.getElementById('password').value = '';
                document.getElementById("hidePopUpBtn").click();
            }
        });
    }

    formatDate(dateString) {

        const date = new Date(dateString);

        const month = ((date.getMonth() + 1 < 10) ? "0" : "") + (date.getMonth() + 1);
        const day = ((date.getDate() < 10) ? "0" : "") + (date.getDate());
        const hours = ((date.getHours() < 10) ? "0" : "") + (date.getHours());
        const minutes = ((date.getMinutes() < 10) ? "0" : "") + (date.getMinutes());
        const seconds = ((date.getSeconds() < 10) ? "0" : "") + (date.getSeconds());

        return `${this.props.t('myChart.vendor.updatedOn')} ${date.getFullYear()}-${month}-${day} ${this.props.t('myChart.vendor.atTime')} ${hours}:${minutes}:${seconds}`;
    }

    printProfile() {
        window.print();
    }

    render() {
        let { username, password } = this.state.fields;
        const { t } = this.props;
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-2 customLabel">
                            {this.props.providerName === 'self provider' &&
                                <Link to={'/editPersonalProfile/' + this.props.profileId}>
                                    <button type="button" className="btn">{t('myChart.vendor.editProfileButton')}</button>
                                </Link>
                            }
                            {this.props.providerName !== 'self provider' && this.props.providerStatus &&
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    // onClick={ this.updateProfileData }
                                    data-toggle="modal" data-target="#updateProfileModal"
                                    disabled={this.state.btnUpdateDisabled}
                                >{t('myChart.vendor.updateProfileButton')}</button>
                            }

                        </div>
                        <div className="col-md-4">
                            <div className="updateDate">{this.formatDate(this.props.lastUpdate)}</div>
                        </div>

                        <div className="col-md-4">
                            {this.props.providerStatus &&
                                this.props.providerName !== 'self provider' &&
                                <Fragment>
                                    <button
                                        type="button"
                                        className="btn btn-link"
                                        // id="updCredBtn"
                                        data-toggle="collapse"
                                        data-target="#updCred"
                                    >{t('myChart.vendor.updateCredentialsButton')}</button>
                                    <div id="updCred" className="collapse">
                                        <Formik
                                            validateOnChange={false}
                                            validateOnBlur={true}
                                            enableReinitialize={true}
                                            initialValues={{
                                                username,
                                                password
                                            }}
                                            validationSchema={Yup.object({
                                                username: Yup.string()
                                                    .required(t('validationErrors.fieldEmpty')),
                                                password: Yup.string()
                                                    .required(t('validationErrors.fieldEmpty')),
                                            })}
                                            onSubmit={(values) => {
                                                this.formValues = values;
                                                document.getElementById('credentialsModal').click();
                                            }}>
                                            {formik =>
                                                <form className="form-horizontal" onSubmit={formik.handleSubmit} >

                                                    <div className="form-group">
                                                        <label className="control-label col-sm-4" htmlFor="username">{t('myChart.vendor.username')}</label>
                                                        <div className="col-sm-8">
                                                            <input
                                                                id="username"
                                                                className="form-control"
                                                                {...formik.getFieldProps('username')}
                                                            />
                                                            {formik.touched.username && formik.errors.username ? (
                                                                <span className="inputErrorText">{formik.errors.username}</span>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-label col-sm-4" htmlFor="password">{t('myChart.vendor.password')}</label>
                                                        <div className="col-sm-8">
                                                            <input
                                                                id="password"
                                                                className="form-control"
                                                                type="password"
                                                                {...formik.getFieldProps('password')}
                                                            />
                                                            {formik.touched.password && formik.errors.password ? (
                                                                <span className="inputErrorText">{formik.errors.password}</span>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="text-right">
                                                        <button
                                                            className="btn btn-primary"
                                                        >{t('myChart.vendor.updateCredentialsSubmitButton')}</button>
                                                        <button
                                                            className="hidden"
                                                            id="credentialsModal"
                                                            type="button"
                                                            data-toggle="modal"
                                                            data-target="#updateCredentialsModal"></button>
                                                    </div>
                                                </form>
                                            }
                                        </Formik>
                                    </div>
                                </Fragment>
                            }
                        </div>
                        {/* {this.props.tempLinkCodeAccess &&
                        
                        } */}
                        <div className="col-md-2">
                            <button type="button" className="btn btn-danger width-200" onClick={this.printProfile}>{t('profileTab.tempLink.print')}</button>
                        </div>
                    </div>
                </div>
                {/* <!-- Modal --> */}
                <div className="modal fade" id="updateProfileModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" id="updCloseBtn" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="myModalLabel">{t('myChart.vendor.updateProfile.confirmation')}</h4>
                            </div>
                            <div className="modal-body">
                                {t('myChart.vendor.updateProfile.confirmationMessage')}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default customModalBtnsWidth" data-dismiss="modal">{t('myChart.vendor.updateProfile.cencelButton')}</button>
                                <button type="button" className="btn btn-danger customModalBtnsWidth" onClick={this.updateProfileData}>{t('myChart.vendor.updateProfile.confirmButton')}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Modal --> */}
                <div className="modal fade" id="updateCredentialsModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" id="hidePopUpBtn" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="myModalLabel">{t('myChart.vendor.providerCredentialsUpdate.confirmation')}</h4>
                            </div>
                            <div className="modal-body">
                                {t('myChart.vendor.providerCredentialsUpdate.confirmationMessage')}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default customModalBtnsWidth" data-dismiss="modal">{t('myChart.vendor.providerCredentialsUpdate.cencelButton')}</button>
                                <button type="button" className="btn btn-danger customModalBtnsWidth" onClick={this.onFormSubmit}>{t('myChart.vendor.providerCredentialsUpdate.confirmButton')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
};

export default VendorUpdate;