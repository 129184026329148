import fetchApi from '../utilities/fetch';

let settingsReqHandler = {

    getSettings: () => {
        return fetchApi.get(`/settings`);
    },
    saveSettings: (settings) => {
        return fetchApi.post('/settings', settings);
    },
};

export default settingsReqHandler;