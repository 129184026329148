import React, { Fragment } from 'react';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

export const Laboratory = ({ formik }) => {
    const { t } = useTranslation();
    return (
        <div className="tab-warper">
            <h4 className="headName">{t('myChart.laboratory.laboratoryTitle')}</h4>
            <FieldArray
                name="laboratoryTests"
                render={arrayHelpers => (
                    <Fragment>
                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayHelpers.push({
                                    date: '',
                                    details: [],
                                    name: '',
                                    orderBy: '',
                                    studyResult: ''
                                })}>
                                    <i className="fa fa-plus"></i> {t('myChart.laboratory.addLaboratoryButton')}
                                </button>
                            </div>
                        </div>
                        <div className="profileContent">
                            {
                                formik.values.laboratoryTests && formik.values.laboratoryTests.length !== 0 &&
                                formik.values.laboratoryTests.map((test, i) => {
                                    return <FieldArray
                                        key={i}
                                        name={`laboratoryTests[${i}].details`}
                                        render={helper => {
                                            return <Fragment>
                                                <div className="row">
                                                    <div className="row">
                                                        <div className="col-md-4 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'name-' + i}>{t('myChart.shared.name')}</label>
                                                                <input
                                                                    id={'name-' + i}
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`laboratoryTests[${i}].name`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'date-' + i}>{t('myChart.shared.date')}</label>
                                                                <input
                                                                    id={'date-' + i}
                                                                    className="form-control"
                                                                    type="date"
                                                                    {...formik.getFieldProps(`laboratoryTests[${i}].date`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'orderBy-' + i}>{t('myChart.laboratory.orderBy')}</label>
                                                                <input
                                                                    id={'orderBy-' + i}
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`laboratoryTests[${i}].orderBy`)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-2">
                                                            <div className="testDetailsDiv">
                                                                <button type="button" className="btn" onClick={() => helper.push({
                                                                    component: '',
                                                                    range: '',
                                                                    value: ''
                                                                })}>
                                                                    <i className="fa fa-plus"></i> {t('myChart.laboratory.addTestDetailsButton')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-1">
                                                            <label>{t('myChart.shared.remove')}</label>
                                                            <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelpers.remove(i)} >
                                                                <span>&times;</span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-9 customLabel">
                                                            <div className="form-group">
                                                                <label htmlFor={'studyResult-' + i}>{t('myChart.laboratory.studyResult')}</label>
                                                                <textarea
                                                                    id={'studyResult-' + i}
                                                                    className="form-control"
                                                                    {...formik.getFieldProps(`laboratoryTests[${i}].studyResult`)}
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        {
                                                            formik.values.laboratoryTests[i].details && formik.values.laboratoryTests[i].details.length !== 0 &&
                                                            formik.values.laboratoryTests[i].details.map((testDetail, j) => {
                                                                return <Fragment key={j}>
                                                                    <div className="row">
                                                                        <div className="col-md-4 customLabel">
                                                                            <div className="form-group">
                                                                                <label htmlFor={'component-' + j}>{t('myChart.laboratory.component')}</label>
                                                                                <input
                                                                                    id={'component-' + j}
                                                                                    className="form-control"
                                                                                    {...formik.getFieldProps(`laboratoryTests[${i}].details[${j}].component`)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-3 customLabel">
                                                                            <div className="form-group">
                                                                                <label htmlFor={'value-' + j}>{t('myChart.laboratory.yourValue')}</label>
                                                                                <input
                                                                                    id={'value-' + j}
                                                                                    className="form-control"
                                                                                    {...formik.getFieldProps(`laboratoryTests[${i}].details[${j}].value`)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4 customLabel">
                                                                            <div className="form-group">
                                                                                <label htmlFor={'range-' + j}>{t('myChart.laboratory.standardRange')}</label>
                                                                                <input
                                                                                    id={'range-' + j}
                                                                                    className="form-control"
                                                                                    {...formik.getFieldProps(`laboratoryTests[${i}].details[${j}].range`)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-1">
                                                                            <label>{t('myChart.shared.remove')}</label>
                                                                            <button type="button" className="btn btn-danger editRemove" onClick={() => helper.remove(j)} >
                                                                                <span>&times;</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </Fragment>
                                        }}
                                    />
                                })
                            }
                        </div>
                    </Fragment>
                )}
            />
        </div>
    );
}