import React from 'react';

export const AttendanceSelectors = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('educationProviders.attendance.title')}</h4>
        <div className='profileContent'>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='attendanceGridSelector'>{t('educationProviders.attendance.attendanceMainGrid')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.attendanceGridSelector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.attendanceGridSelector && formik.errors.configuration && formik.errors.configuration.attendanceGridSelector ? (
                            <span className="inputErrorText">{formik.errors.configuration.attendanceGridSelector}</span>
                        ) : null}
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='attendanceGridCellSelector'>{t('educationProviders.attendance.attendanceMainGridCell')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.attendanceGridCellSelector')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.attendanceGridCellSelector && formik.errors.configuration && formik.errors.configuration.attendanceGridCellSelector ? (
                            <span className="inputErrorText">{formik.errors.configuration.attendanceGridCellSelector}</span>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    </div>
)