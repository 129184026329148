import { people, profiles as profilesActions } from '../actions';
import update from 'immutability-helper';

const profile = (state = {}, action) => {
    let profiles;
    switch (action.type) {
        case people.GET_PEOPLE:
            state = {
                ...state,
                profiles: action.payload
            }
            break;
        case people.EDIT_PERSON:
            profiles = state.profiles.map(profile => {
                if (profile.id === action.payload.id) {
                    profile = {
                        ...profile,
                        personName: action.payload.name,
                        photo: action.payload.photo,
                        fileURL: action.payload.fileURL
                    }
                }

                return profile;
            })

            state = {
                ...state,
                profiles
            }
            break;
        case people.DELETE_PERSON:
            profiles = state.profiles.filter(profile => profile.id !== action.payload.toString());

            state = {
                ...state,
                profiles
            }
            break;
        case profilesActions.GET_PROFILES_LIST:
            state = {
                ...state,
                profilesList: {
                    ...state.profilesList,
                    [action.payload.id]: action.payload.profilesList
                }
            }

            break;
        case profilesActions.GET_PROFILE:
            state = {
                ...state,
                profileData: {
                    ...state.profileData,
                    [action.id]: action.payload
                }
            }
            break;
        case profilesActions.UPADATE_VISIBILITY_SCHEMA:
            state = update(state, {
                profileData: {
                    [action.id]: {
                        profile: {
                            visibilitySchema: {
                                $set: action.payload
                            }
                        }
                    }
                }
            })
            break;
        default:
            break;
    }

    return state;
}

export default profile;