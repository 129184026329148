import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import profileReqHandler from '../../api/profileRemote';
import { Link } from 'react-router-dom';
import authentication from '../../utilities/authentication';
import cookies from '../../utilities/cookies';
import { withTranslation } from 'react-i18next';

const logo = require('../../person_image.png');
const add = require('../../add.png');

class People extends Component {

    componentDidMount() {
        cookies.remove("person");
        if(this.props.reload && this.props.reload === true){
            this.props.peopleList = undefined;
        }

        if (!this.props.peopleList) {
            const loggedData = authentication.loggedData();
            this.props.getPeopleList(loggedData.userId);
        }
    }

    editPeople() {
        this.props.history.push('/editPeople');
    }

    loadDashboard(person) {
        const personId = person.id;
        cookies.set("person", personId);
    }

    renderImage(delegateCall) {
        delegateCall.then((result) => {
            this.setState({});
        })
        return (
            <img className="imgBla" src={logo} />
        );
    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <h1>{t('dashboard.peopleTitle')}</h1>

                        {this.props.peopleList &&
                            this.props.peopleList.map((person, i) => {

                                return <Link key={i} to={'/dashboard'}>
                                    <button onClick={this.loadDashboard.bind(this, person)} type="button" className="btn divBla" >
                                        <img className="imgBla" src={person.fileURL === undefined ? logo : person.fileURL} />
                                        <br />
                                        {person.personName}
                                    </button>
                                </Link>
                            })

                        }
                        <Link to={'/newPerson'}>
                            <button type="button" className="btn divBla btn-default" >
                                <img className="imgBlaAdd" src={add} />
                            </button>
                        </Link>

                    </div>

                    <br />
                    <div className="col-md-4 text-center">
                        <button type="button" className="btn btn-primary"
                            onClick={this.editPeople.bind(this)}
                        >{t('dashboard.editPeopleButton')}</button>
                    </div>
                </div>
            </Fragment>
        );
    };
}

const mapStateToProps = state => {
    return {
        peopleList: state.profile.profiles
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPeopleList: (userId) => dispatch(profileReqHandler.getPeopleList(userId))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(People));