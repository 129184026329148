import React from 'react';

export const PatientInformation = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.patientInformation.title')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationUrl'>{t('myChartProviders.shared.url')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationUrl')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorName'>{t('myChartProviders.shared.name')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorName')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorBirth'>{t('myChartProviders.patientInformation.birth')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorBirth')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorRace'>{t('myChartProviders.patientInformation.race')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorRace')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorMedicalRecordNumbers'>{t('myChartProviders.patientInformation.medicalRecordNumbers')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorMedicalRecordNumbers')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorPrimaryProvider'>{t('myChartProviders.patientInformation.primaryProvider')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorPrimaryProvider')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorClinic'>{t('myChartProviders.patientInformation.clinic')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorClinic')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorAddress'>{t('myChartProviders.patientInformation.address')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorAddress')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorPhone'>{t('myChartProviders.patientInformation.phone')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorPhone')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorEmergencyContactName'>{t('myChartProviders.patientInformation.emergencyContactName')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorEmergencyContactName')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorEmergencyContactPhone'>{t('myChartProviders.patientInformation.emergencyContactPhone')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorEmergencyContactPhone')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorInshuranceName'>{t('myChartProviders.patientInformation.insuranceName')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorInshuranceName')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorInshuranceType'>{t('myChartProviders.patientInformation.insuranceType')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorInshuranceType')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='patientInformationSelectorInshuranceNumber'>{t('myChartProviders.patientInformation.insuranceNumber')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.patientInformationSelectorInshuranceNumber')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
)