import React, { Fragment } from 'react';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

export const Allergies = ({ formik }) => {
    const { t } = useTranslation();

    return (
        <div className="tab-warper">
            <h4 className="headName">{t('myChart.allergies.allergiesTitle')}</h4>

            <div className="profileContent">
                <FieldArray
                    name="allergies"
                    render={arrayHelpers => (
                        <Fragment>
                            <div className="row buttonFromHead">
                                <div className="col-md-6 customLabel">
                                    <button type="button" className="btn"
                                        onClick={() => arrayHelpers.push({
                                            name: '',
                                            type: '',
                                            severity: '',
                                            date: ''
                                        })}>
                                        <i className="fa fa-plus"></i> {t('myChart.allergies.addButton')}
                            </button>
                                </div>
                            </div>
                            <div>
                                {
                                    formik.values.allergies && formik.values.allergies.length !== 0 &&
                                    formik.values.allergies.map((allergy, i) => {
                                        return <Fragment key={i}>
                                            <div className="row">
                                                <div className="col-md-3 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'name-' + i}>{t('myChart.shared.name')}</label>
                                                        <input
                                                            id={'name-' + i}
                                                            className="form-control"
                                                            {...formik.getFieldProps(`allergies[${i}].name`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'type-' + i}>{t('myChart.allergies.type')}</label>
                                                        <input
                                                            id={'type-' + i}
                                                            className="form-control"
                                                            {...formik.getFieldProps(`allergies[${i}].type`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'severity-' + i}>{t('myChart.allergies.severity')}</label>
                                                        <input
                                                            id={'severity-' + i}
                                                            className="form-control"
                                                            {...formik.getFieldProps(`allergies[${i}].severity`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'date-' + i}>{t('myChart.shared.date')}</label>
                                                        <input
                                                            id={'date-' + i}
                                                            className="form-control"
                                                            type="date"
                                                            {...formik.getFieldProps(`allergies[${i}].date`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-1">
                                                    <label>{t('myChart.shared.remove')}</label>
                                                    <button type="button" className="btn btn-danger editRemove" onClick={() => {
                                                        arrayHelpers.remove(i)
                                                    }} >
                                                        <span>&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </Fragment>
                                    })
                                }
                            </div>
                        </Fragment>
                    )} />
            </div>
        </div>
    );
}