import React, { Component, Fragment } from 'react';

class ChildInfoProgressionPlan extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        if (property === 'childInfoProgressionPlan') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.childInfoProgressionPlan[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('childInfoProgressionPlan'))}
                                data-key="childInfoProgressionPlan"
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('fwisd.childInfoProgressionPlan.title')}</h4>
                        {this.props.profile.content && this.props.profile.content.childInfoProgressionPlan &&
                            <div className="profileContent">
                                <div>
                                    {this.props.profile.content.childInfoProgressionPlan.endorsements &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('endorsements'))}
                                                    onClick={() => this.props.eyeClicked('endorsements')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoProgressionPlan.endorsements')} {this.props.profile.content.childInfoProgressionPlan.endorsements}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoProgressionPlan.plansForTheFuture &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('plansForTheFuture'))}
                                                    onClick={() => this.props.eyeClicked('plansForTheFuture')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoProgressionPlan.plansForTheFuture')} {this.props.profile.content.childInfoProgressionPlan.plansForTheFuture}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoProgressionPlan.collegeReadinessTSI &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('collegeReadinessTSI'))}
                                                    onClick={() => this.props.eyeClicked('collegeReadinessTSI')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoProgressionPlan.collegeReadinessTSI')} {this.props.profile.content.childInfoProgressionPlan.collegeReadinessTSI}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoProgressionPlan.collegePreparatoryCourses &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('collegePreparatoryCourses'))}
                                                    onClick={() => this.props.eyeClicked('collegePreparatoryCourses')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoProgressionPlan.collegePreparatoryCourses')} {this.props.profile.content.childInfoProgressionPlan.collegePreparatoryCourses}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoProgressionPlan.financialAid &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('financialAid'))}
                                                    onClick={() => this.props.eyeClicked('financialAid')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoProgressionPlan.financialAid')} {this.props.profile.content.childInfoProgressionPlan.financialAid}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoProgressionPlan.parentCompletedHardCopy &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('parentCompletedHardCopy'))}
                                                    onClick={() => this.props.eyeClicked('parentCompletedHardCopy')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoProgressionPlan.parentCompletedHardCopy')} {this.props.profile.content.childInfoProgressionPlan.parentCompletedHardCopy}</span>
                                        </Fragment>
                                    }
                                </div>
                                <div>
                                    {this.props.profile.content.childInfoProgressionPlan.staarEOCChecklists &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('staarEOCChecklists'))}
                                                    onClick={() => this.props.eyeClicked('staarEOCChecklists')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoProgressionPlan.staarEOCChecklists')} {this.props.profile.content.childInfoProgressionPlan.staarEOCChecklists}</span>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
export default ChildInfoProgressionPlan;