import React, { Component, Fragment } from 'react';

class LaboratoryTests extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        if (property === 'laboratoryTests') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.laboratoryTests[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('laboratoryTests'))}
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('myChart.laboratory.laboratoryTitle')}</h4>
                        {this.props.profile.content &&
                            <div className="profileContent">

                                <div className="row">
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4 customLabel">{t('myChart.shared.name')}</div>
                                    <div className="col-md-3 customLabel">{t('myChart.shared.date')}</div>
                                    <div className="col-md-3 customLabel">{t('myChart.laboratory.orderBy')}</div>
                                    <div className="col-md-1"></div>
                                </div>

                                {this.props.profile.content.laboratoryTests &&

                                    this.props.profile.content.laboratoryTests.map((test, i) => {
                                        return <Fragment key={i}>
                                            <div className="row trows">
                                                <div className="col-md-1">
                                                    {this.props.showVisibilitySettings &&
                                                        <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                            onClick={() => this.props.eyeClicked(i)}>
                                                        </i>
                                                    }
                                                </div>
                                                <div className="col-md-4 labTestRowItem" data-toggle="collapse" data-target={'#test' + i}>{test.name}</div>
                                                <div className="col-md-3 labTestRowItem" data-toggle="collapse" data-target={'#test' + i}>{test.date}</div>
                                                <div className="col-md-3 labTestRowItem" data-toggle="collapse" data-target={'#test' + i}>{test.orderBy}</div>
                                                {!this.props.tempLinkCodeAccess &&
                                                    <div className="col-md-1 labTestRowItem glyphicon glyphicon-chevron-down customSpanExpander-color" data-toggle="collapse" data-target={'#test' + i}></div>
                                                }
                                            </div>

                                            <div id={'test' + i} className={this.props.tempLinkCodeAccess ? "row labTest-warper expand" : "row labTest-warper collapse"}>
                                                {test.studyResult &&
                                                    <div className="row">
                                                        <div className="row">
                                                            <div className="col-md-1"></div>
                                                            <div className="col-md-2 customLabel">{t('myChart.laboratory.studyResult')}</div>
                                                            <div className="col-md-9"></div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-1"></div>
                                                            <div className="col-md-11">
                                                                {test.studyResult}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col-md-1"></div>
                                                    <div className="col-md-3 customLabel">{t('myChart.laboratory.component')}</div>
                                                    <div className="col-md-3 customLabel">{t('myChart.laboratory.yourValue')}</div>
                                                    <div className="col-md-5 customLabel">{t('myChart.laboratory.standardRange')}</div>
                                                </div>
                                                {
                                                    test.details.map((detail, ind) => {
                                                        return <Fragment key={ind}>
                                                            <div className="row trows">
                                                                <div className="col-md-1"></div>
                                                                <div className="col-md-3">{detail.component}</div>
                                                                <div className="col-md-3">{detail.value}</div>
                                                                <div className="col-md-5">{detail.range}</div>
                                                            </div>
                                                        </Fragment>
                                                    })
                                                }
                                            </div>
                                        </Fragment>
                                    })

                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
};

export default LaboratoryTests;