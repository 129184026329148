import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const Discipline = ({ formik, t }) => {
    return (
        <div className="tab-warper">
            <h4 className="headName">{t('education.discipline.disciplineTitle')}</h4>
            <FieldArray
                name="disciplines"
                render={
                    arrayHelper => (
                        <Fragment>
                            <div className="row buttonFromHead">
                                <div className="col-md-6 customLabel">
                                    <button type="button" className="btn" onClick={() => arrayHelper.push({
                                        datetime: '',
                                        offense: '',
                                        location: '',
                                        officer: '',
                                        comment: ''
                                    })}>
                                        <i className="fa fa-plus"></i> {t('education.discipline.addDisciplineButton')}
                                    </button>
                                </div>
                            </div>
                            <div className="profileContent">
                                {
                                    formik.values.disciplines.map((discipline, i) => {
                                        return <Fragment key={i}>
                                            <div className="row">
                                                <div className="col-md-3 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'datetime-' + i}>{t('education.shared.dateTime')}</label>
                                                        <input
                                                            className="form-control"
                                                            type="date"
                                                            {...formik.getFieldProps(`disciplines[${i}].datetime`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'offense-' + i}>{t('education.discipline.offense')}</label>
                                                        <input
                                                            className="form-control"
                                                            {...formik.getFieldProps(`disciplines[${i}].offense`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-3 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'location-' + i}>{t('education.discipline.location')}</label>
                                                        <input
                                                            className="form-control"
                                                            {...formik.getFieldProps(`disciplines[${i}].location`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'officer-' + i}>{t('education.discipline.officer')}</label>
                                                        <input
                                                            className="form-control"
                                                            {...formik.getFieldProps(`disciplines[${i}].officer`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-2 customLabel">
                                                    <div className="form-group">
                                                        <label htmlFor={'comment-' + i}>{t('education.discipline.comment')}</label>
                                                        <input
                                                            className="form-control"
                                                            {...formik.getFieldProps(`disciplines[${i}].comment`)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-1">
                                                    <label>{t('education.shared.remove')}</label>
                                                    <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelper.remove(i)} >
                                                        <span>&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </Fragment>
                                    })
                                }
                            </div>
                        </Fragment>
                    )
                }
            />
        </div>
    )
}