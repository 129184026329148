import React, { Fragment } from 'react';

export const EducationalData = ({ profiles, t }) => {

    const studentInfoVisibility = (currProfile, index, property) => {
        if (currProfile.studentInfo[property]) {
            return <div key={index} className="row">
                <div className="col-md-6 firstName">{currProfile.studentInfo[property]}</div>
                <div className="col-md-3 firstName">{currProfile.exportPersonName}</div>
                <div className="col-md-3 firstName">{currProfile.providerName}</div>
            </div>;
        }
    }

    const childInfoVisibility = (currProfile, index, property) => {
        switch (currProfile.providerName) {
            case 'FORT WORTH':
                if (currProfile.childInfoGeneral && currProfile.childInfoGeneral[property]) {
                    return <div key={index} className="row">
                        <div className="col-md-6 firstName">{currProfile.childInfoGeneral[property]}</div>
                        <div className="col-md-3 firstName">{currProfile.exportPersonName}</div>
                        <div className="col-md-3 firstName">{currProfile.providerName}</div>
                    </div>;
                } else {
                    return <div />
                        
                }

            default:
                if (currProfile.studentInfo[property]) {
                    return <div key={index} className="row">
                        <div className="col-md-6 firstName">{currProfile.studentInfo[property]}</div>
                        <div className="col-md-3 firstName">{currProfile.exportPersonName}</div>
                        <div className="col-md-3 firstName">{currProfile.providerName}</div>
                    </div>;
                } else {
                    return <div />
                }
        }
    }

    return (
        <div className="row" >
            <div className="tab-warper" >
                <h4 className="headName">{t('summary.studentInfo')}</h4>

                <div className="profileContent">

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.firstName', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'firstName');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.lastName', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'lastName');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.gender', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'gender');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('fwisd.childInfoGeneral.birthDate', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'birthDate');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.age', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'age');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.language', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'language');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.graduationYear', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'graduationyear');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.otherId', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'otherid');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('fwisd.childInfoGeneral.localStudentId', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'localStudentId');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('fwisd.childInfoGeneral.tccId', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'tccId');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.communityService', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'communityservice');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.second', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'second');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.address', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'address');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.school', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'school');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.principal', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'principal');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.grade', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'grade');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.status', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'status');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.homeRoom', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'homeRoom');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.disciplineOfficer', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'disciplineOfficer');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.schoolMail', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'schoolMail');
                        })
                    }

                    <div className="row">
                        <div className="col-md-6 customLabel">{t('education.personalInfo.call', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return childInfoVisibility(currProfile, i, 'call');
                        })
                    }

                    <div className="profileContent">
                        <div className="historyLabel">{t('education.personalInfo.emerganceContactsTitle')}</div>
                        {
                            profiles.map((currProfile, i) => {
                                if (currProfile.studentInfo && currProfile.studentInfo.itemStudentInfo && currProfile.studentInfo.itemStudentInfo.length > 0) {
                                    return <Fragment key={i}>

                                        <div className="row">
                                            <div className="col-md-6"> </div>
                                            <div className="col-md-3">{currProfile.exportPersonName}</div>
                                            <div className="col-md-3">{currProfile.providerName}</div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-1 customLabel"></div>
                                            <div className="col-md-3 customLabel">{t('education.personalInfo.emerganceContacts', true)}</div>
                                            <div className="col-md-2 customLabel">{t('education.personalInfo.primaryPhone', true)}</div>
                                            <div className="col-md-2 customLabel">{t('education.personalInfo.secondPhone', true)}</div>
                                            <div className="col-md-2 customLabel">{t('education.personalInfo.thirdPhone', true)}</div>
                                            <div className="col-md-2 customLabel">{t('education.personalInfo.homeEmail', true)}</div>
                                        </div>

                                        {currProfile.studentInfo.itemStudentInfo.map((item, i) => {
                                            return <div key={i} className="row fileList">

                                                <div className="col-md-3">
                                                    <span>{item.emergancecontacts}</span>
                                                </div>

                                                <div className="col-md-2">
                                                    <span>{item.primaryphone}</span>
                                                </div>

                                                <div className="col-md-2">
                                                    <span>{item.secondphone}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.thirdphone}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.homeemail}</span>
                                                </div>
                                            </div>
                                        })
                                        }
                                    </Fragment>
                                }
                            })
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}