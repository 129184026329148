import React from 'react';

export const PlanOfCareSelectors = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.planOfCare.planOfCareTitle')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareUrl'>{t('myChartProviders.shared.url')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareUrl')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareAll'>{t('myChartProviders.planOfCare.planOfCareAll')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareSelectorAll')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareOverdueSelectorName'>{t('myChartProviders.planOfCare.overdue')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareOverdueSelectorName')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareNextSelectorName'>{t('myChartProviders.planOfCare.next')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareNextSelectorName')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareFutureSelectorName'>{t('myChartProviders.planOfCare.future')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareFutureSelectorName')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareOverdueTitleSelector'>{t('myChartProviders.planOfCare.title')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareOverdueTitleSelector')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareOverdueStatusSelector'>{t('myChartProviders.planOfCare.status')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareOverdueStatusSelector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareDateSelector'>{t('myChartProviders.planOfCare.date')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareDateSelector')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareOrderBySelector'>{t('myChartProviders.planOfCare.orderBy')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareOrderBySelector')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareLocationSelector'>{t('myChartProviders.planOfCare.location')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareLocationSelector')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='planOfCareOrderDetailsSelector'>{t('myChartProviders.planOfCare.orderDetais')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.planOfCareOrderDetailsSelector')}
                        />
                    </div>
                </div>                
            </div>
        </div>
    </div >

)