import React, { Component, Fragment } from 'react';

class ChildInfoAddressAndContacts extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        // const filedData = property.split('-');
        // const fieldName = filedData[0];
        // const fieldId = parseInt(filedData[1]);

        if (property === 'childInfoAddressAndContacts') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.childInfoAddressAndContacts[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('childInfoAddressAndContacts'))}
                                data-key="childInfoAddressAndContacts"
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('fwisd.childInfoAddressAndContacts.title')}</h4>
                        {this.props.profile.content &&
                            <div className="profileContent">
                                <div className="profileContent">

                                    <div className="row">
                                        <div className="col-md-1 customLabel"></div>
                                        <div className="col-md-2 customLabel">{t('fwisd.childInfoAddressAndContacts.mainAddress', true)}</div>
                                        <div className="col-md-4 customLabel">{t('fwisd.childInfoAddressAndContacts.mailingAddress', true)}</div>

                                    </div>

                                    {this.props.profile.content.childInfoAddressAndContacts &&
                                        this.props.profile.content.childInfoAddressAndContacts.map((item, i) => {
                                            return <Fragment key={i}>
                                                <div className="row fileList">
                                                    <div className="col-md-1">
                                                        {this.props.showVisibilitySettings &&
                                                            <i className={"fa fa-eye " + (this.eyeToggle(i))}
                                                                onClick={() => this.props.eyeClicked(i)}>
                                                            </i>
                                                        }
                                                    </div>
                                                    <div className="col-md-2">
                                                        <span>{item.mainAddress}</span>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <span>{item.mailingAddress}</span>
                                                    </div>                                                 
                                                </div>
                                            </Fragment>
                                        })
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
export default ChildInfoAddressAndContacts;