import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import profileReqHandler from '../../api/profileRemote';
import authentication from '../../utilities/authentication';
import ProfileTabMyChart from './MyChart/ProfileTab';
import ProfileTabEducation from './Education/ProfileTab';
import ProfileTabFwisd from './Fwisd/ProfileTab';
import tempLinksRemote from '../../api/tempLinksRemote';
import { boolean } from 'yup';

class ProfileTab extends Component {
    hideButton = false;

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {},
            profileData: {},
            updateVisibilitySchema: {},
            tempLinkCodeAccess: false,
            tempLinkPageAccess: false
        };
        this.checkAccessCode = this.checkAccessCode.bind(this);
        this.printProfile = this.printProfile.bind(this);
    }

    componentDidMount() {
        if (this.props.match && this.props.match.params && this.props.match.params.guid) {

            this.setState({ tempLinkCodeAccess: true });
            this.setState({ tempLinkPageAccess: true });
            return;
        }
    }

    checkAccessCode(code) {
        const guid = this.props.match.params.guid;
        tempLinksRemote.checkAccessCode(code, guid).then(response => {
            if (response) {
                this.setState({ tempLinkCodeAccess: false });
                this.loadProfileFromTempLink();
            }
            else {
                this.setState({ errors: 'Invalid access code' });
            }
        })
    }

    loadProfileFromTempLink() {
        tempLinksRemote.getTempLinks(this.props.match.params.guid).then(response => {
            const profileDashboard = {
                profileData: response.content.parentData,
                profile: response.content.responseData,
                updateVisibilitySchema: response.content.responseData.profile.visibilitySchema,
                adminMode: true
            }
            this.setState({ profileDashboard: profileDashboard });
            // window.print();
            // window.close();
        }).catch(error => console.log(error))

    }

    onFormSubmit(event) {
        event.preventDefault();
        this.checkAccessCode(this.state.fields["code"])
    }

    onChangeHandler(field, event) {
        const fields = this.state.fields;
        fields[field] = event.target.value;
        this.setState({ fields });
    }

    printProfile() {
        window.print();
    }

    render() {
        let profileDashboard = this.state.profileDashboard;
        const { t } = this.props;
        if (profileDashboard === undefined) {
            if (this.props.location && this.props.location.state && this.props.location.state.adminMode) {
                profileDashboard = this.props.location.state
            }
            else {
                profileDashboard = {
                    profileData: this.props.profileData,
                    profile: this.props.profile,
                    updateVisibilitySchema: this.props.updateVisibilitySchema,
                    adminMode: this.props.adminMode
                }
            }
        }


        return (
            <Fragment>
                <div className="container-fluid">
                    {this.state.tempLinkCodeAccess &&
                        <div className="container">
                            <div className="row">
                                {/* <div className="col-lg-6 col-sm-6 col-xs-6"> */}
                                <div className="center-screen">
                                    {/* <h2>{t('login.title')}</h2> */}
                                    <form className="login-form" onSubmit={this.onFormSubmit.bind(this)} >

                                        <div className="form-group">
                                            <label htmlFor="code">{t('profileTab.tempLink.accessCode')}</label>
                                            <input
                                                ref="code"
                                                id="code"
                                                className="form-control"
                                                type="text"
                                                placeholder="Code"
                                                name="code"
                                                onChange={this.onChangeHandler.bind(this, "code")}
                                            />
                                            {this.state.errors.length > 0 &&
                                                <span className="inputErrorText">{this.state.errors}</span>
                                            }
                                        </div>

                                        <input type="submit" className="btn btn-primary" value={t('profileTab.tempLink.checkCode')} />
                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                    {!this.state.tempLinkCodeAccess && this.state.tempLinkPageAccess &&
                        <div className="center-screen">
                            <button type="button" className="btn btn-danger width-300"  onClick={this.printProfile}>{t('profileTab.tempLink.print')}</button>
                        </div>
                    }

                    {!this.state.tempLinkCodeAccess && profileDashboard.profile && profileDashboard.profileData.providerTypeName.toLowerCase() === 'mychart' &&
                        <ProfileTabMyChart
                            parentData={profileDashboard.profileData}
                            responseData={profileDashboard.profile}
                            updateVisibilitySchema={profileDashboard.updateVisibilitySchema}
                            adminMode={profileDashboard.adminMode}
                            tempLinkCodeAccess={this.state.tempLinkCodeAccess}
                            tempLinkPageAccess={this.state.tempLinkPageAccess}
                        />}
                    {!this.state.tempLinkCodeAccess && profileDashboard.profile && profileDashboard.profileData.providerTypeName.toLowerCase() === 'education' &&
                        <ProfileTabEducation
                            parentData={profileDashboard.profileData}
                            responseData={profileDashboard.profile}
                            updateVisibilitySchema={profileDashboard.updateVisibilitySchema}
                            adminMode={profileDashboard.adminMode}
                            tempLinkCodeAccess={this.state.tempLinkCodeAccess}
                            tempLinkPageAccess={this.state.tempLinkPageAccess}
                        />}
                    {!this.state.tempLinkCodeAccess && profileDashboard.profile && profileDashboard.profileData.providerTypeName.toLowerCase() === 'fwisd' &&
                        <ProfileTabFwisd
                            parentData={profileDashboard.profileData}
                            responseData={profileDashboard.profile}
                            updateVisibilitySchema={profileDashboard.updateVisibilitySchema}
                            adminMode={profileDashboard.adminMode}
                            tempLinkCodeAccess={this.state.tempLinkCodeAccess}
                            tempLinkPageAccess={this.state.tempLinkPageAccess}
                        />}
                </div>
            </Fragment>
        );
    };
};


export default withTranslation()(ProfileTab);