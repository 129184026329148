import fetchApi from '../utilities/fetch';
import { providers } from '../actions';

let providerConfigReqHandler = {

  getTypeProviders: () => {
    return dispatch => {
      return fetchApi.get('/providersType').then(response => {
        if (response) {
          dispatch({
            type: providers.GET_PROVIDER_TYPE,
            payload: response
          })
        }

        return response;
      })
    }
  },

  getProvidersConfig: (typeIds) => {
    return dispatch => {
      let ids;

      if (typeIds && Array.isArray(typeIds) && typeIds.length !== 0) {
        ids = typeIds.map(typeId => fetchApi.get(`/providersConfigIds?providerType=${typeId.id}`));
      };

      if (ids && ids.length !== 0) {
        Promise.all(ids).then(response => {
          let data = {};

          typeIds.forEach((typeId, index) => {
            data[typeId.id] = response[index];
          })

          dispatch({
            type: providers.GET_PROVIDER_CONFIG,
            payload: data
          })
        })
      }
    }
  },

  getProviderId: (id) => {
    return fetchApi.get(`/providerConfig?providerConfig=${id}`)
  },

  addProviderConfig: (providerData) => {
    return dispatch => {
      return fetchApi.post('/providerConfiguration', providerData).then(response => {
        if (response && response.success) {
          dispatch({
            type: providers.CLEAN_PROVIDER_CONFIG,
            payload: response
          })
        }

        return response;
      })
    }
  },

  updateProviderConfig: (providerData) => {
    return fetchApi.post('/providerConfig', providerData)
  },

  deleteProviderConfig: (id, deleteItemIndex, providerTypeId) => {
    return dispatch => {
      return fetchApi.post('/deleteProviderConfig', id).then(response => {
        if (response) {
          dispatch({
            type: providers.DELETE_PROVIDER,
            deleteItemIndex,
            providerTypeId
          })
        }
      })
    }
  },
}

export default providerConfigReqHandler;