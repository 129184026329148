import { visits } from "../actions";
import update from "immutability-helper";

const visit = (state = {}, action) => {
  // let _visits;
  switch (action.type) {
    case visits.GET_VISITS_LIST:
      state = {
        ...state,
        visitsList: {
          ...state.visitsList,
          [action.payload.id]: action.payload.visitsList,
        },
      };

      break;
    case visits.GET_VISIT:
      state = {
        ...state,
        visitData: {
          ...state.visitData,
          [action.id]: action.payload,
        },
      };
      break;
    case visits.UPADATE_VISIT_VISIBILITY_SCHEMA:
      state = update(state, {
        visitData: {
          [action.id]: {
            visit: {
              isVisible: {
                $set: action.payload,
              },
            },
          },
        },
      });
      break;
    // case visits.DELETE_VISIT:
    //   _visits = state.visits.filter(
    //     (visit) => visit.id !== action.payload.toString()
    //   );

    //   state = {
    //     ...state,
    //     _visits,
    //   };
    //   break;
    default:
      break;
  }

  return state;
};

export default visit;
