import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const Medications = ({ formik, t }) => {
    return (
        <FieldArray
            name="healthInfo.medications"
            render={
                arrayHelper => (
                    <Fragment>
                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayHelper.push({
                                    schoolyear: '',
                                    school: '',
                                    medication: '',
                                    distribute: '',
                                    prescribedby: '',
                                    formsreceived: ''
                                })}>
                                    <i className="fa fa-plus"></i> {t('education.healthInfo.medications.addMedicationButton')}
                                </button>
                            </div>
                        </div>
                        <div className="profileContent">
                            {
                                formik.values.healthInfo.medications.map((medication, i) => {
                                    return <Fragment key={i}>
                                        <div className="row">
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'schoolyear-' + i}>{t('education.shared.schoolYear')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].SchoolYear`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'school-' + i}>{t('education.shared.school')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].School`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'medication-' + i}>{t('education.healthInfo.medications.medication')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].Medication`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'distribute-' + i}>{t('education.healthInfo.medications.distribute')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].Distribute`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'prescribedby-' + i}>{t('education.healthInfo.medications.prescribedBy')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].PrescribedBy`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'formsreceived-' + i}>{t('education.healthInfo.medications.formsReceived')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.medications[${i}].FormsReceived`)}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-1">
                                                <label>{t('education.shared.remove')}</label>
                                                <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelper.remove(i)} >
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Fragment>
                                })
                            }
                        </div>
                    </Fragment>
                )
            }
        />
    );
}