import React, { Component, Fragment } from "react";
import fetchApi from "../../utilities/fetch";
import { withTranslation } from "react-i18next";
import authentication from "../../utilities/authentication";
import profileReqHandler from "../../api/profileRemote";
import tempLinksRemote from "../../api/tempLinksRemote";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import toastr from "toastr";
import visitReqHandler from "../../api/visitRemote";

class ProfileTab extends Component {
  hideButton = false;

  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      errors: {},
      visitData: {},
      // updateVisibilitySchema: {},
      showVisibilitySettings: false,
      showHideBtnText: this.props.t("visibilitySettings.show"),
      tempLinkCodeAccess: false,
      tempLinkPageAccess: false,
      isVisible: true,
    };
    this.checkAccessCode = this.checkAccessCode.bind(this);
    this.updateProfileData = this.updateProfileData.bind(this);
    this.printProfile = this.printProfile.bind(this);
    this.deleteVisit = this.deleteVisit.bind(this);
  }

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.guid
    ) {
      this.setState({ tempLinkCodeAccess: true });
      this.setState({ tempLinkPageAccess: true });
      return;
    }

    this.setState({ isVisible: this.props.visit.isVisible });
  }

  checkAccessCode(code) {
    const guid = this.props.match.params.guid;
    tempLinksRemote.checkAccessCode(code, guid).then((response) => {
      if (response) {
        this.setState({ tempLinkCodeAccess: false });
        this.loadVisitFromTempLink();
      } else {
        this.setState({ errors: "Invalid access code" });
      }
    });
  }

  loadVisitFromTempLink() {
    tempLinksRemote
      .getTempLinks(this.props.match.params.guid)
      .then((response) => {
        const data = {
          visitId: response.profileId,
        };

        visitReqHandler.getVisitFromTempLink(data).then((res) => {
          fetchApi
            .getFile(`/readFileFromTempLink?id=${res.content}`, true)
            .then((file) => {
              const reader = new FileReader();

              reader.addEventListener("loadend", (e) => {
                const content = e.srcElement.result;

                const visitDashboard = {
                  visitData: {
                    content: res.content,
                    htmlSource: content,
                    adminMode: true,
                  },
                };
                this.setState({ visitDashboard: visitDashboard });
              });

              // Start reading the blob as text.
              reader.readAsText(file);
            });
        });
      })
      .catch((error) => console.log(error));
  }

  onFormSubmit(event) {
    event.preventDefault();
    this.checkAccessCode(this.state.fields["code"]);
  }

  onChangeHandler(field, event) {
    const fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  }

  printProfile() {
    window.print();
  }

  generateTempLink(values) {
    const loggedData = authentication.loggedData();

    const tempLink = {
      user: loggedData.userId,
      email: values.email,
      profileId: this.state.visitDashboard.visitData.id,
      url: "printVisit",
      content: {
        htmlSource: this.state.visitDashboard.visitData.content,
        adminMode: this.props.adminMode,
        tempLinkCodeAccess: this.props.tempLinkCodeAccess,
      },
    };

    tempLinksRemote.createTempLink(tempLink).then((res) => {
      if (res) {
        console.log("Success!");
      }
    });
  }

  eyeClicked(clickedEye) {
    this.setState({ isVisible: !this.state.isVisible });
    // const newState = this.props.eyeClicked(
    //   this.state,
    //   clickedEye
    // );
    // this.setState(newState);
  }

  eyeToggle(property) {
    return this.state.isVisible ? "eyeBlack" : "eyeGray";
  }

  showHideEye() {
    const currVisibility = !this.state.showVisibilitySettings;
    this.setState({ showVisibilitySettings: currVisibility });
    if (currVisibility) {
      this.setState({
        showHideBtnText: this.props.t("visibilitySettings.hide"),
      });
    } else {
      this.setState({
        showHideBtnText: this.props.t("visibilitySettings.show"),
      });
    }
  }

  updateVisibilitySettings() {
    const loggedData = authentication.loggedData();

    const updateData = {
      id: this.props.visit.id,
      user: loggedData.userId,
      isVisible: this.state.isVisible,
    };

    this.props.updateVisitVisibilitySetting(updateData).then(() => {
      toastr.success(this.props.t("visibilitySettings.seccess"));
    });
  }

  printProfile() {
    window.print();
  }

  deleteVisit() {
    // if (this.props.responseData.profile && this.props.responseData.profile.id) {

    const visitObj = {
      visitId: this.state.visitDashboard.visitData.id,
      visitName: this.state.visitDashboard.visitData.visitName,
    };

    visitReqHandler.deleteVisit(visitObj).then((res) => {
      if (res.success) {
        window.location.reload();
      }
    });
    // }
  }

  // updateProfileData() {
  //   const updateData = {
  //     providerId: this.props.visit.provider,
  //     profileId: this.props.visit.profileId,
  //     profileDetailId: this.props.profileDetailId,
  //     // personId: this.props.personId
  //   };

  //   // to do getprofile detail

  //   document.getElementById("updCloseBtn").click();

  //   profileReqHandler.updateVendorProfile(updateData).then((res) => {
  //     if (res.success) {
  //       this.setState({ btnUpdateDisabled: true });

  //       toastr.options.timeOut = 15000;
  //       toastr.success(this.props.t("myChart.vendor.success"));
  //     }
  //   });
  // }

  updateProfileData() {
    const updateData = {            
      providerId: this.props.visit.provider,
      profileId: this.props.visit.profileId,
      // profileDetailId: this.props.profileDetailId,
      // personId: this.props.personId
  };

  profileReqHandler.updateVendorProfile(updateData).then(res => {

      if (res.success) {
          this.setState({ btnUpdateDisabled: true });

          toastr.options.timeOut = 15000;
          toastr.success(this.props.t('myChart.vendor.success'));
      }
  });
  }

  render() {
    let visitDashboard = this.state.visitDashboard;
    const { t } = this.props;
    if (visitDashboard === undefined) {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.adminMode
      ) {
        visitDashboard = this.props.location.state;
      } else {
        visitDashboard = {
          visitData: this.props.visit,
          adminMode: this.props.adminMode,
        };
      }

      this.setState({ visitDashboard: visitDashboard });
    }

    return (
      <Fragment>
        <div className="container-fluid">
          {this.state.tempLinkCodeAccess && (
            <div className="container">
              <div className="row">
                <div className="center-screen">
                  <form
                    className="login-form"
                    onSubmit={this.onFormSubmit.bind(this)}
                  >
                    <div className="form-group">
                      <label htmlFor="code">
                        {t("profileTab.tempLink.accessCode")}
                      </label>
                      <input
                        ref="code"
                        id="code"
                        className="form-control"
                        type="text"
                        placeholder="Code"
                        name="code"
                        onChange={this.onChangeHandler.bind(this, "code")}
                      />
                      {this.state.errors.length > 0 && (
                        <span className="inputErrorText">
                          {this.state.errors}
                        </span>
                      )}
                    </div>

                    <input
                      type="submit"
                      className="btn btn-primary"
                      value={t("profileTab.tempLink.checkCode")}
                    />
                  </form>
                </div>
              </div>
            </div>
          )}
          {!this.state.tempLinkCodeAccess && this.state.tempLinkPageAccess && (
            <div className="center-screen">
              <button
                type="button"
                className="btn btn-danger width-300"
                onClick={this.printProfile}
              >
                {t("profileTab.tempLink.print")}
              </button>
            </div>
          )}
          <div className="row">
            <div className="tab-warper">
              <h2>
                {this.state.showVisibilitySettings && (
                  <i
                    className={"fa fa-eye " + this.eyeToggle("all")}
                    onClick={() => {
                      this.eyeClicked("all");
                    }}
                  ></i>
                )}
                {visitDashboard.visitData && visitDashboard.visitData.visitName}
              </h2>
              <button
                type="button"
                className="btn btn-default"
                onClick={this.showHideEye.bind(this)}
              >
                {this.state.showHideBtnText}
              </button>
              <button
                type="button"
                className="btn btn-primary marginLeft10"
                onClick={ this.updateProfileData }
                // data-toggle="modal"
                // data-target="#updateProfileModal"
              >
                {t("myChart.vendor.updateProfileButton")}
              </button>              
              {this.state.showVisibilitySettings && (
                <button
                  type="button"
                  id="saveVisibilitySett"
                  className="btn btn-primary"
                  onClick={this.updateVisibilitySettings.bind(this)}
                >
                  {t("visibilitySettings.update")}
                </button>
              )}
            </div>
          </div>

          {!this.state.tempLinkCodeAccess &&
            visitDashboard &&
            visitDashboard.visitData && (
              <div
                id="root"
                dangerouslySetInnerHTML={{
                  __html: visitDashboard.visitData.htmlSource,
                }}
              ></div>
            )}
        </div>
        <div className="row" id="deleteProfileRow">
          <div className="col-md-10"></div>
          <div className="col-md-5">
            {this.props.adminMode === false && (
              <button
                type="button"
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#tempLinkModal"
              >
                {t("profileTab.tempLink.popupButton")}
              </button>
            )}
            {this.props.adminMode === false && (
              <button
                type="button"
                className="btn btn-danger marginLeft10"
                data-toggle="modal"
                data-target="#deleteProfileModal"
              >
                {t("deleteProfile.button")}
              </button>
            )}

            {this.props.adminMode === false && (
              <button
                type="button"
                className="btn btn-danger marginLeft10"
                data-toggle="modal"
                data-target="#printProfileModal"
                onClick={this.printProfile}
              >
                {t("profileTab.tempLink.print")}
              </button>
            )}
            {/* {this.props.adminMode === true &&
                                    <Link className="btn btn-primary" to={'/admin/allProfiles'} > {t('profileTab.cancelButton')}</Link>
                                } */}
          </div>
        </div>
        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="deleteProfileModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h4 className="modal-title" id="myModalLabel">
                  {t("deleteProfile.confirmation")}
                </h4>
              </div>
              <div className="modal-body">
                {t("deleteProfile.confirmationMessage")}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                >
                  {t("deleteProfile.cencelButton")}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.deleteVisit}
                >
                  {t("deleteProfile.confirmButton")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .required(t("profileTab.tempLink.required"))
              .email(t("profileTab.tempLink.invalid")),
          })}
          onSubmit={(values) => {
            this.generateTempLink(values);
          }}
        >
          {(formik) => (
            <div
              className="modal fade"
              id="tempLinkModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    {t("profileTab.tempLink.popupTitle")}
                  </div>
                  <div>
                    <form>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-4 col-sm-6 col-xs-8">
                            <div className="form-group">
                              <label htmlFor="typeProviders">
                                {t("profileTab.tempLink.emailLabel")}
                              </label>
                              <Field
                                as="input"
                                name="email"
                                className="form-control"
                              />
                              {formik.touched.email && formik.errors.email ? (
                                <span className="inputErrorText">
                                  {formik.errors.email}
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={formik.submitForm}
                      data-dismiss="modal"
                    >
                      {t("profileTab.tempLink.sendButton")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      {t("profileTab.tempLink.cancelButton")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Fragment>
    );
  }
}

export default withTranslation()(ProfileTab);
