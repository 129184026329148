import React from 'react';

export const Diagnosis = ({ profiles, t }) => {

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('myChart.diagnosis.diagnosisTitle')}</h4>

                <div className="profileContent">
                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.shared.name', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return currProfile.diagnosis.map((diagnose, j) => {
                                return <div key={j} className="row sub-tab-warper">
                                    <div className="col-md-6">{diagnose.name}</div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                    <div className="row">
                                        <div className="row">
                                            <div className="col-md-6 customLabel">{t('myChart.shared.date', true)}</div>
                                            <div className="col-md-6 customLabel">{t('myChart.diagnosis.code', true)}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">{diagnose.date}</div>
                                            <div className="col-md-6">{diagnose.code}</div>
                                        </div>
                                    </div>
                                </div>
                            })
                        })
                    }
                </div>
            </div>
        </div>
    )
}