import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const HealthCondition = ({ formik, t }) => {
    return (
        <FieldArray
            name="healthInfo.healthconditions"
            render={
                arrayhelper => (
                    <Fragment>
                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayhelper.push({
                                    healthcondition: '',
                                    schoolyear: '',
                                    school: '',
                                    status: '',
                                    treatment: '',
                                    examinedby: '',
                                    referredby: ''
                                })}>
                                    <i className="fa fa-plus"></i> {t('education.healthInfo.healthCondition.addHealthConditionButton')}
                            </button>
                            </div>
                        </div>

                        <div className="profileContent">
                            {
                                formik.values.healthInfo.healthconditions.map((healthcondition, i) => {
                                    return <Fragment key={i}>
                                        <div className="row">
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'healthcondition-' + i}>{t('education.healthInfo.healthCondition.healthCondition')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.healthconditions[${i}].healthcondition`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'schoolyear-' + i}>{t('education.shared.schoolYear')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.healthconditions[${i}].schoolyear`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'school-' + i}>{t('education.shared.school')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.healthconditions[${i}].school`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'status-' + i}>{t('education.healthInfo.healthCondition.status')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.healthconditions[${i}].status`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'treatment-' + i}>{t('education.shared.treatment')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.healthconditions[${i}].treatment`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'examinedby-' + i}>{t('education.shared.examinedBy')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.healthconditions[${i}].examinedby`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'referredby-' + i}>{t('education.shared.referredBy')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.healthconditions[${i}].referredby`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <label>{t('education.shared.remove')}</label>
                                                <button type="button" className="btn btn-danger editRemove" onClick={() => arrayhelper.remove(i)} >
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Fragment>
                                })
                            }
                        </div>
                    </Fragment>
                )
            }
        />
    );
}