import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const Injuries = ({ formik, t }) => {
    return (
        <FieldArray
            name="healthInfo.injuries"
            render={
                arrayHelper => (
                    <Fragment>

                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayHelper.push({
                                    schoolyear: '',
                                    dateandtime: '',
                                    school: '',
                                    location: '',
                                    immediatecare: '',
                                    bodypartinjuryobservationtreatment: '',
                                    daysmissed: '',
                                    referredby: ''
                                })}>
                                    <i className="fa fa-plus"></i> {t('education.healthInfo.injuries.addInjuryButton')}
                                </button>
                            </div>
                        </div>

                        <div className="profileContent">
                            {
                                formik.values.healthInfo.injuries.map((injury, i) => {
                                    return <Fragment key={i}>
                                        <div className="row">
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'schoolyear-' + i}>{t('education.shared.schoolYear')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.injuries[${i}].schoolyear`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'dateandtime-' + i}>{t('education.shared.date')}</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        {...formik.getFieldProps(`healthInfo.injuries[${i}].dateandtime`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'school-' + i}>{t('education.shared.school')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.injuries[${i}].school`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'location-' + i}>{t('education.healthInfo.injuries.location')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.injuries[${i}].location`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'immediatecare-' + i}>{t('education.healthInfo.injuries.immediateCare')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.injuries[${i}].immediatecare`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'bodypartinjuryobservationtreatment-' + i}>{t('education.healthInfo.injuries.bodyPartTreatment')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.injuries[${i}].bodypartinjuryobservationtreatment`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'daysmissed-' + i}>{t('education.healthInfo.injuries.daysMissed')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.injuries[${i}].daysmissed`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'referredby-' + i}>{t('education.shared.referredBy')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.injuries[${i}].referredby`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <label>{t('education.shared.remove')}</label>
                                                <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelper.remove(i)} >
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Fragment>
                                })
                            }
                        </div>
                    </Fragment>
                )
            }
        />
    );
}