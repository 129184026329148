import React, { Fragment } from 'react';

export const HealthData = ({ profiles, t }) => {
    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('summary.healthData')}</h4>
                <div className="profileContent">
                    {
                        profiles.map((currProfile, i) => {
                            if(currProfile.healthInfo && currProfile.healthInfo.healthconditions && currProfile.healthInfo.healthconditions.length){                       
                                return <Fragment key={i}>
                                    <div className="row">
                                        <div className="col-md-6"> </div>
                                        <div className="col-md-3">{currProfile.exportPersonName}</div>
                                        <div className="col-md-3">{currProfile.providerName}</div>
                                    </div>
                                    <div className="row">
                                        <div className="historyLabel">{t('education.healthInfo.healthCondition.healthConditionTitle')}</div>
                                        <div className="col-md-2 customLabel">{t('education.healthInfo.healthCondition.healthCondition', true)}</div>
                                        <div className="col-md-1 customLabel">{t('education.shared.schoolYear', true)}</div>
                                        <div className="col-md-1 customLabel">{t('education.shared.school', true)}</div>
                                        <div className="col-md-1 customLabel">{t('education.healthInfo.healthCondition.status', true)}</div>
                                        <div className="col-md-2 customLabel">{t('education.shared.treatment', true)}</div>
                                        <div className="col-md-1 customLabel">{t('education.shared.examinedBy', true)}</div>
                                        <div className="col-md-1 customLabel">{t('education.shared.referredBy', true)}</div>
                                    </div>

                                    {currProfile.healthInfo.healthconditions.map((item, i) => {
                                            return <div key={i} className="row fileList">
                                                <div className="row fileList">
                                                    <div className="col-md-2">{item.healthcondition}</div>
                                                    <div className="col-md-1">{item.schoolyear}</div>
                                                    <div className="col-md-1">{item.school}</div>
                                                    <div className="col-md-1">{item.status}</div>
                                                    <div className="col-md-2">{item.treatment}</div>
                                                    <div className="col-md-1">{item.examinedby}</div>
                                                    <div className="col-md-1">{item.referredby}</div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </Fragment>
                            }
                        })
                    }
                    {profiles.map((currProfile, i) => {
                        if(currProfile.healthInfo && currProfile.healthInfo.diabetescarelog && currProfile.healthInfo.diabetescarelog.length){                       
                            return <Fragment key={i}>
                                <br />
                                <div className="row">
                                    <div className="col-md-6"> </div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                                <div className="row">
                                    <div className="historyLabel">{t('education.healthInfo.diabeteScarelog.diabeteScarelogTitle')}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.schoolYear', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.date', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.diabeteScarelog.bloodGlucoseAbb')}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.diabeteScarelog.IOB', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.diabeteScarelog.carbIntake', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.diabeteScarelog.insulinDose', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.diabeteScarelog.injectionSite', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.diabeteScarelog.ketoneResultsAbb', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.treatment', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.school', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.examinedBy', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.diabeteScarelog.comment', true)}</div>
                                </div>

                                {currProfile.healthInfo.diabetescarelog.map((item, i) => {
                                        return <div key={i} className="row fileList">
                                            <div className="row fileList">
                                                <div className="col-md-1">
                                                    <span>{item.schoolyear}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.bloodglucose}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.iob}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.carbintake}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.insulindose}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.injectionsite}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.ketoneresults}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.treatment}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.school}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.examinedby}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.comment}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        }
                    })
                    }
                    {profiles.map((currProfile, i) => {
                        if(currProfile.healthInfo && currProfile.healthInfo.disabilities && currProfile.healthInfo.disabilities.length){                       
                            return <Fragment key={i}>
                                <br />
                                <div className="row">
                                    <div className="col-md-6"> </div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                                <div className="row">
                                    <div className="historyLabel">{t('education.healthInfo.disability.disabilityTitle')}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.schoolYear', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.date', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.school', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.examinedBy', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.disability.disability', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.disability.specialNeeds', true)}</div>
                                </div>

                                {currProfile.healthInfo.disabilities.map((item, i) => {
                                        return <div key={i} className="row fileList">
                                            <div className="row fileList">
                                                <div className="col-md-2">
                                                    <span>{item.schoolyear}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.school}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.examinedby}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.disability}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.specialneeds}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        }
                    })
                    }
                    {profiles.map((currProfile, i) => {
                        if(currProfile.healthInfo && currProfile.healthInfo.officevisits && currProfile.healthInfo.officevisits.length){                                                   
                            return <Fragment key={i}>
                                <br />
                                <div className="row">
                                    <div className="col-md-6"> </div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                                <div className="row">
                                    <div className="historyLabel">{t('education.healthInfo.officevisits.officeVisitTitle')}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.schoolYear', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.date', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.school', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.officevisits.checkIn', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.officevisits.timeOut', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.examinedBy', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.officevisits.reason', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.treatment', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.officevisits.disposition', true)}</div>

                                </div>

                                {currProfile.healthInfo.officevisits.map((item, i) => {
                                        return <div key={i} className="row fileList">
                                            <div className="row fileList">
                                                <div className="col-md-1">
                                                    <span>{item.schoolyear}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.school}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.checkin}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.timeout}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.examinedby}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.reasonforvisit}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.treatment}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.disposition}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        }
                    })
                    }
                    {profiles.map((currProfile, i) => {
                        if(currProfile.healthInfo && currProfile.healthInfo.ihpforms && currProfile.healthInfo.ihpforms.length){                       
                            return <Fragment key={i}>
                                <br />
                                <div className="row">
                                    <div className="col-md-6"> </div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                                <div className="row">
                                    <div className="historyLabel">{t('education.healthInfo.ihpForms.ihpFormsTitle')}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.ihpForms.print', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.schoolYear', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.date', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.time', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.ihpForms.formName', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.ihpForms.personEntered', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.ihpForms.docType', true)}</div>
                                </div>

                                {currProfile.healthInfo.ihpforms.map((item, i) => {
                                        return <div key={i} className="row fileList">
                                            <div className="row fileList">
                                                <div className="col-md-1">
                                                    <span>{item.print}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.schoolyear}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.time}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.formname}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.personentered}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.doctype}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        }
                    })
                    }
                    {profiles.map((currProfile, i) => {
                        if(currProfile.healthInfo && currProfile.healthInfo.childhoodillnesses && currProfile.healthInfo.childhoodillnesses.length){                       
                            return <Fragment key={i}>
                                <br />
                                <div className="row">
                                    <div className="col-md-6"> </div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                                <div className="row">
                                    <div className="historyLabel">{t('education.healthInfo.childhoodIllnesses.childhoodIllnessTitle')}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.schoolYear', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.date', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.school', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.childhoodIllnesses.illness', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.examinedBy', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.childhoodIllnesses.ageDiagnosed', true)}</div>
                                </div>

                                {currProfile.healthInfo.childhoodillnesses.map((item, i) => {
                                        return <div key={i} className="row fileList">
                                            <div className="row fileList">
                                                <div className="col-md-2">
                                                    <span>{item.schoolyear}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.school}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.illness}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.examinedby}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.agediagnosed}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        }
                    })
                    }
                    {profiles.map((currProfile, i) => {
                        if(currProfile.healthInfo && currProfile.healthInfo.injuries && currProfile.healthInfo.injuries.length){                       
                            return <Fragment key={i}>
                                <br />
                                <div className="row">
                                    <div className="col-md-6"> </div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                                <div className="row">
                                    <div className="historyLabel">{t('education.healthInfo.injuries.injuryTitle')}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.schoolYear', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.dateTime', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.school', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.injuries.location', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.injuries.immediateCare', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.injuries.bodyPartTreatmentAbb')}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.injuries.daysMissed', true)}</div>
                                </div>

                                {currProfile.healthInfo.injuries &&
                                    currProfile.healthInfo.injuries.map((item, i) => {
                                        return <div key={i} className="row fileList">
                                            <div className="row fileList">
                                                <div className="col-md-1">
                                                    <span>{item.schoolyear}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.dateandtime}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.school}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.location}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.immediatecare}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.bodypartinjuryobservationtreatment}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.daysmissed}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        }
                    })
                    }
                    {profiles.map((currProfile, i) => {
                        if(currProfile.healthInfo && currProfile.healthInfo.medications && currProfile.healthInfo.medications.length){                       
                            return <Fragment key={i}>
                                <br />
                                <div className="row">
                                    <div className="col-md-6"> </div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                                <div className="row">
                                    <div className="historyLabel">{t('education.healthInfo.medications.medicationTitle')}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.schoolYear', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.school', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.medications.medication', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.medications.distribute', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.medications.prescribedBy', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.medications.formsReceived', true)}</div>
                                </div>

                                {currProfile.healthInfo.medications.map((item, i) => {
                                        return <div key={i} className="row fileList">
                                            <div className="row fileList">
                                                <div className="col-md-2">
                                                    <span>{item.schoolYear}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.school}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.medication}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.distribute}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.prescribedby}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.formsreceived}</span>
                                                </div>

                                            </div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        }
                    })
                    }
                    {profiles.map((currProfile, i) => {
                        if(currProfile.healthInfo && currProfile.healthInfo.physicalexams && currProfile.healthInfo.physicalexams.length){                       
                            return <Fragment key={i}>
                                <br />
                                <div className="row">
                                    <div className="col-md-6"> </div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                                <div className="row">
                                    <div className="historyLabel">{t('education.healthInfo.physicalExams.physicalExamTitle')}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.schoolYear', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.date', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.school', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.physicalExams.height', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.physicalExams.weight', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.physicalExams.bloodPressure', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.examinedBy', true)}</div>
                                </div>

                                {currProfile.healthInfo.physicalexams.map((item, i) => {
                                        return <div key={i} className="row fileList">
                                            <div className="row fileList">
                                                <div className="col-md-2">
                                                    <span>{item.schoolyear}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.school}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.height}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.weight}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.bloodpressure}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.examinedby}</span>
                                                </div>

                                            </div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        }
                    })
                    }
                    {profiles.map((currProfile, i) => {
                        if(currProfile.healthInfo && currProfile.healthInfo.tests && currProfile.healthInfo.tests.length){                       
                            return <Fragment key={i}>
                                <br />
                                <div className="row">
                                    <div className="col-md-6"> </div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                                <div className="row">
                                    <div className="historyLabel">{t('education.healthInfo.tests.testTitle')}</div>
                                    <div className="col-md-2 customLabel">{t('education.shared.schoolYear', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.shared.date', true)}</div>
                                    <div className="col-md-3 customLabel">{t('education.shared.school', true)}</div>
                                    {/* <div className="col-md-2 customLabel">Test</div> */}
                                    <div className="col-md-2 customLabel">{t('education.shared.examinedBy', true)}</div>
                                </div>

                                {currProfile.healthInfo.tests.map((item, i) => {
                                        return <div key={i} className="row fileList">
                                            <div className="col-md-2">
                                                <span>{item.schoolyear}</span>
                                            </div>
                                            <div className="col-md-1">
                                                <span>{item.date}</span>
                                            </div>
                                            <div className="col-md-3">
                                                <span>{item.school}</span>
                                            </div>
                                            <div className="col-md-2">
                                                <span>{item.examinedby}</span>
                                            </div>

                                            <div id={'item' + i} className="row labTest-warper-bottom-border">
                                                <div className="row">
                                                    <div className="row">
                                                        <div className="col-md-1"></div>
                                                        <div className="col-md-4 customLabel">
                                                            <span>{item.test}</span>
                                                        </div>
                                                        <div className="col-md-5"></div>
                                                    </div>
                                                </div>

                                                {item.tests.map((test, j) => {
                                                    return <Fragment key={j}>
                                                        <div className="row trows ">
                                                            <div className="col-md-1"></div>
                                                            <div className="col-md-2">
                                                                <span>{test.columnName1}</span>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <span>{test.val1}</span>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <span>{test.columnName2}</span>
                                                            </div>
                                                            <div className="col-md-2">
                                                                <span>{test.val2}</span>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                })
                                                }
                                            </div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        }
                    })
                    }                    
                    {profiles.map((currProfile, i) => {
                        if(currProfile.healthInfo && currProfile.healthInfo.vaccinations && currProfile.healthInfo.vaccinations.length){                                                   
                            return <Fragment key={i}>
                                <br />
                                <div className="row">
                                    <div className="col-md-6"> </div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                </div>
                                <div className="row">
                                    <div className="historyLabel">{t('education.healthInfo.vaccinations.vaccinationTitle')}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.vaccinations.vaccination', true)}</div>
                                    <div className="col-md-2 customLabel">{t('education.healthInfo.vaccinations.compliance', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.vaccinations.waiver', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.vaccinations.type', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.vaccinations.date1', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.vaccinations.date2', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.vaccinations.date3', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.vaccinations.date4', true)}</div>
                                    <div className="col-md-1 customLabel">{t('education.healthInfo.vaccinations.date5', true)}</div>
                                </div>

                                {currProfile.healthInfo.vaccinations.map((item, i) => {
                                        return <div key={i} className="row fileList">
                                            <div className="row fileList">
                                                <div className="col-md-2">
                                                    <span>{item.vaccination}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.compliance}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.waiver}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.type}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date1}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date2}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date3}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date4}</span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span>{item.date5}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        }
                    })
                    }
                </div>
            </div>
        </div>
    )
}