import React, { Component, Fragment } from 'react';

class ChildInfoPeims extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        if (property === 'childInfoPeims') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.childInfoPeims[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('childInfoPeims'))}
                                data-key="childInfoPeims"
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('fwisd.childInfoPeims.title')}</h4>
                        {this.props.profile.content && this.props.profile.content.childInfoPeims && 
                            <div className="profileContent">
                                <div>
                                    {this.props.profile.content.childInfoPeims.homelessIndicator &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('homelessIndicator'))}
                                                    onClick={() => this.props.eyeClicked('homelessIndicator')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoPeims.homelessIndicator')} {this.props.profile.content.childInfoPeims.homelessIndicator}</span>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
export default ChildInfoPeims;