import { providers as providersAction } from '../actions';

const providers = (state = {}, action) => {
    switch(action.type) {
        case providersAction.GET_PROVIDER_TYPE:
            return {
                ...state,
                types: action.payload
            };
        case providersAction.GET_PROVIDER_CONFIG:
            return {
                ...state,
                configProviders: action.payload
            };
        case providersAction.DELETE_PROVIDER:
            return {
                ...state,
                configProviders: {
                    ...state.configProviders,
                    [action.providerTypeId]: [
                        ...state.configProviders[action.providerTypeId].slice(0, action.deleteItemIndex),
                        ...state.configProviders[action.providerTypeId].slice(action.deleteItemIndex + 1)
                    ]
                }
            }
        case providersAction.CLEAN_PROVIDER_CONFIG:
            return {
                ...state,
                configProviders: undefined
            }
        default:
            return state;
    }
};

export default providers;