import React from 'react';

export const PlanOfCare = ({ profiles, t }) => {
    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('myChart.planOfCare.planOfCareTitle')}</h4>

                <div className="profileContent">

                    {/* START OVERDUE */}
                    <div className="row">
                        <div className="historyLabel">{t('myChart.planOfCare.planOfCareOverdueTitle', true)}</div>
                        <div className="col-md-3 customLabel">{t('myChart.planOfCare.title')}</div>
                        <div className="col-md-3 customLabel">{t('myChart.planOfCare.status')}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            if (currProfile.planOfCare.planOfCareOverdue) {
                                return currProfile.planOfCare.planOfCareOverdue.map((overdue, j) => {
                                    return <div key={j} className="row">
                                        <div className="col-md-3">{overdue.title}</div>
                                        <div className="col-md-3">{overdue.status}</div>
                                        <div className="col-md-3">{currProfile.exportPersonName}</div>
                                        <div className="col-md-3">{currProfile.providerName}</div>                                  
                                    </div>
                                })
                            }
                        })
                    }
                    {/* END OVERDUE */}

                    {/* START NEXT */}
                    <div className="row">
                        <div className="historyLabel">{t('myChart.planOfCare.planOfCareNextTitle', true)}</div>
                        <div className="col-md-6 customLabel">{t('myChart.planOfCare.title')}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            if (currProfile.planOfCare.planOfCareNext) {
                                return currProfile.planOfCare.planOfCareNext.map((next, j) => {
                                    return <div key={j} className="row">
                                        <div className="col-md-6 healthIssueName">{next.title}</div>                    
                                        <div className="col-md-3">{currProfile.exportPersonName}</div>
                                        <div className="col-md-3">{currProfile.providerName}</div>                                  
                                        <div className="sub-tab-warper his-sub-elem-wrap">
                                            <div className="row">
                                                <div className="col-md-2 customLabel">{t('myChart.planOfCare.date', true)}</div>
                                                <div className="col-md-3 customLabel">{t('myChart.planOfCare.location', true)}</div>
                                                <div className="col-md-3 customLabel">{t('myChart.planOfCare.orderBy', true)}</div>
                                                <div className="col-md-3 customLabel">{t('myChart.planOfCare.orderDetails', true)}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-2">{next.date}</div>
                                                <div className="col-md-3">{next.location}</div>
                                                <div className="col-md-3">{next.orderBy}</div>
                                                <div className="col-md-3">{next.orderDetails}</div>        
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        })
                    }
                    {/* END NEXT */}

                    {/* START FUTURE */}
                    <div className="row">
                        <div className="historyLabel">{t('myChart.planOfCare.planOfCareFutureTitle', true)}</div>
                        <div className="col-md-6 customLabel">{t('myChart.planOfCare.title')}</div>             
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            if (currProfile.planOfCare.planOfCareFuture) {
                                return currProfile.planOfCare.planOfCareFuture.map((future, j) => {
                                    return <div key={j} className="row">
                                        <div className="col-md-6 healthIssueName">{future.title}</div>                    
                                        <div className="col-md-3">{currProfile.exportPersonName}</div>
                                        <div className="col-md-3">{currProfile.providerName}</div>                                  
                                        <div className="sub-tab-warper his-sub-elem-wrap">
                                            <div className="row">
                                                <div className="col-md-2 customLabel">{t('myChart.planOfCare.date', true)}</div>
                                                <div className="col-md-3 customLabel">{t('myChart.planOfCare.location', true)}</div>
                                                <div className="col-md-3 customLabel">{t('myChart.planOfCare.orderBy', true)}</div>
                                                <div className="col-md-3 customLabel">{t('myChart.planOfCare.orderDetails', true)}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-2">{future.date}</div>
                                                <div className="col-md-3">{future.location}</div>
                                                <div className="col-md-3">{future.orderBy}</div>
                                                <div className="col-md-3">{future.orderDetails}</div>        
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        })
                    }
                    {/* END FUTURE */}

                </div>

            </div>
        </div>

    )
}