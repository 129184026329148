import React from 'react';

export const GlobalSelectors = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.global.title')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='loginUrl'>{t('myChartProviders.global.loginUrl')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.loginUrl')}
                        />
                        {formik.touched.configuration && formik.touched.configuration.loginUrl && formik.errors.configuration && formik.errors.configuration.loginUrl ? (
                            <span className="inputErrorText">{formik.errors.configuration.loginUrl}</span>
                        ) : null}
                    </div>
                </div>
            </div>


            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorUsername'>{t('myChartProviders.global.userName')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorUsername')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorPassword'>{t('myChartProviders.global.password')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorPassword')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorSubmitBtn'>{t('myChartProviders.global.submitButton')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorSubmitBtn')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='loginErrorSelector'>{t('myChartProviders.global.loginError')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.loginErrorSelector')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='middleUrl'>{t('myChartProviders.global.middleUrl')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.middleUrl')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorCheck'>{t('myChartProviders.global.check')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorCheck')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorGetUsers'>{t('myChartProviders.global.getUsers')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorGetUsers')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorGetChild'>{t('myChartProviders.global.getChild')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorGetChild')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorAgree'>{t('myChartProviders.global.agree')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorAgree')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorClickBasicUser'>{t('myChartProviders.global.clickBasicUser')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorClickBasicUser')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorPageWait'>{t('myChartProviders.global.pageWait')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorPageWait')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='selectorLogOut'>{t('myChartProviders.global.logOut')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.selectorLogOut')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
) 