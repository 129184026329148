import host from './host';
import headers from '../utilities/headers';
import authentication from '../utilities/authentication';
import notifications from '../utilities/notifications';
import fetchApi from '../utilities/fetch';

let providerReqHandler = {

    
    getProvider: (providerId)  => {
        return fetchApi.get(`/provider?id=${providerId}`)
    },

    getProviders: (setQuery) => {

        let providersFilter = '';
        let providerType = '';

        if (setQuery) {
            providersFilter = '?excludeSelf=true'
        }

        return fetch(host + '/providers' + providersFilter, {
            method: 'GET',
            credentials: 'include',
            headers: headers.basic
        }).then(resp => {
            return handleResponse(resp);
        }).catch(e => {
            
            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    },
    getAllProviders: (setQuery) => {

        let providersFilter = '';
        let providerType = '';

        if (setQuery) {
            providersFilter = '?excludeSelf=true'
        }

        return fetch(host + '/allproviders' + providersFilter, {
            method: 'GET',
            credentials: 'include',
            headers: headers.basic
        }).then(resp => {
            return handleResponse(resp);
        }).catch(e => {
            
            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    },
    requestNewProvider: (providerObj) => {
        return fetchApi.post('/requestProvider', providerObj)
    }
};

function handleResponse(resp) {

    if (resp.ok) {
        return resp.json();
    } else {
        return resp.json().then(e =>{
            throw new Error(e);
        });
    }
}

export default providerReqHandler;