import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';


export const PlanOfCareOverdue = ({ formik, arrayHelpers }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="row buttonFromHead">
                <div className="col-md-6 customLabel">
                    <button type="button" className="btn" onClick={() => arrayHelpers.push({ title: '', status: '' })}>
                        <i className="fa fa-plus"></i> {t('myChart.planOfCare.addPlanOfCareOverdueButton')}
                    </button>
                </div>
            </div>
            <div className="profileContent">
                {   formik.values.planOfCare &&
                    formik.values.planOfCare.planOfCareOverdue && formik.values.planOfCare.planOfCareOverdue.length !== 0 && 
                    formik.values.planOfCare.planOfCareOverdue.map((mh, i) => {
                        return <Fragment key={i}>
                            <div className="row">
                                <div className="col-md-6 customLabel">
                                    <div className="form-group">
                                        <label htmlFor={'title-' + i}>{t('myChart.planOfCare.title')}</label>
                                        <input
                                            id={'title-' + i}
                                            className="form-control"
                                            {...formik.getFieldProps(`planOfCare.planOfCareOverdue[${i}].title`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-5 customLabel">
                                    <div className="form-group">
                                        <label htmlFor={'status-' + i}>{t('myChart.planOfCare.status')}</label>
                                        <input
                                            id={'status-' + i}
                                            className="form-control"
                                            {...formik.getFieldProps(`planOfCare.planOfCareOverdue[${i}].status`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <label>{t('myChart.shared.remove')}</label>
                                    <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelpers.remove(i)} >
                                        <span>&times;</span>
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    })
                }
            </div>
        </Fragment>
    );
}
