import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import providerConfigReqHandler from '../../api/providersConfigRemote';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            providerType: {
                id: '',
                name: '',
            },
            isSelectType: false,
            deleteItemId: null,
            deleteItemIndex: null,
        }
    }

    componentDidMount() {
        if (!this.props.providersType) {
            this.props.loadProvidersTypeList().then(res => this.props.getProvidersConfig(res));
        } else if (!this.props.providers) {
            this.props.getProvidersConfig(this.props.providersType)
        }
    }

    componentDidUpdate(prevProps) {
        if (!this.props.providersType) {
            this.props.loadProvidersTypeList().then(res => this.props.getProvidersConfig(res));
        } else if (!this.props.providers) {
            this.props.getProvidersConfig(this.props.providersType)
        }
    }

    providersTypeSelect = (event) => {

        const providerType = event.target.value;
        if (providerType === 'default') {
            this.setState({
                isSelectType: false,
            })
        } else {
            const type = this.props.providersType.find((pr) => {
                return pr.id === providerType;
            })
            this.setState({
                providerType: {
                    id: providerType,
                    name: type.name,
                },
                isSelectType: true,
            })
        }

    }

    handleDeleteProvider = (event) => {
        let id = this.state.deleteItemId;
        let data = { id };
        this.props.deleteProviderConfig(data, this.state.deleteItemIndex, this.state.providerType.id).then(res => {
            document.getElementById('closeModal').click();
        });
    }

    openModalDelete = (index, id) => {

        this.setState({
            deleteItemId: id,
            deleteItemIndex: index
        })
    }


    copyProvider = (provider) => {
        const id = provider.id;
        providerConfigReqHandler.getProviderId(id).then(res => {
            if (res) {
                const newProvider = {
                    name: "copy_" + res.name,
                    providerTypeId: res.providerType,
                    configuration: res.configuration
                }
                this.props.addProviderConfig(newProvider).then(res => {
                    if (res && res.success) {
                        toastr.success(this.props.t('providers.createSuccess'));
                        this.props.history.push('/admin');
                    }
                })
            }
        });

    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <h1>{t('providers.title')}</h1>
                        <label htmlFor="typeProviders">{t('providers.selectTypeField')}</label>
                        <select className="form-control" id="typeProviders" onChange={this.providersTypeSelect}>
                            <option value="default" >{t('providers.defaultValue')}</option>
                            {this.props.providersType && this.props.providersType.length > 0 &&
                                this.props.providersType.map((providersType) => {
                                    return <option key={providersType.id} value={providersType.id} >{providersType.name.toUpperCase()}</option>
                                })
                            }
                        </select>
                    </div>
                    {this.state.isSelectType &&
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <td>
                                                        <h3>{t('providers.configExistProviders')}</h3>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.props.providers && this.props.providers[this.state.providerType.id] && this.props.providers[this.state.providerType.id].length > 0 &&
                                                    this.props.providers[this.state.providerType.id].map((provider, i) => {
                                                        return <tr className="customTableRow" key={provider.id} >
                                                            <td className="customTableTd"><h4>{provider.name.toUpperCase()}</h4></td>
                                                            <td className="customTableTd">
                                                                <Link className="btn btn-primary" to={'/admin/providerUpdate/' + this.state.providerType.name + '/' + provider.id} >{t('providers.updateButton')}</Link>

                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary marginLeft10"
                                                                    onClick={() => { this.copyProvider(provider) }}
                                                                > {t('providers.copyButton')}
                                                                </button>
                                                            </td>
                                                            <td className="customTableTd" id="deleteProviderConfig">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger"
                                                                    data-toggle="modal"
                                                                    data-target="#deleteProviderModal"
                                                                    onClick={() => { this.openModalDelete(i, provider.id) }}
                                                                > {t('providers.deleteButton')}
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-xs-12">
                                    <Link type="button" className="btn btn-default" to={'/admin/providerCreate/' + this.state.providerType.name + '/' + this.state.providerType.id} >{t('providers.createButton')}</Link>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                {/* <!-- Modal --> */}
                <div className="modal fade" id="deleteProviderModal" tabIndex="-1" role="dialog" aria-labelledby="myModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => this.setState({
                                        deleteItemId: null,
                                        deleteItemIndex: null,
                                    })}
                                ><span aria-hidden="true">&times;</span></button>
                                <h4 className="modal-title" id="myModalLabel" >{t('providers.deletePopupTitle')}</h4>
                            </div>
                            <div className="modal-body">
                                {t('providers.deletePopupMessage')}
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-default"
                                    data-dismiss="modal"
                                    id='closeModal'
                                    onClick={() => this.setState({
                                        deleteItemId: null,
                                        deleteItemIndex: null,
                                    })}
                                >{t('providers.cancelButton')}
                                </button>
                                <button type="button" className="btn btn-danger" onClick={this.handleDeleteProvider}>{t('providers.deletePopupButton')}</button>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        providersType: state.providers.types,
        providers: state.providers.configProviders
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProvidersTypeList: () => dispatch(providerConfigReqHandler.getTypeProviders()),
        getProvidersConfig: typeIds => dispatch(providerConfigReqHandler.getProvidersConfig(typeIds)),
        addProviderConfig: (providerData) => dispatch(providerConfigReqHandler.addProviderConfig(providerData)),
        deleteProviderConfig: (data, deleteItemIndex, providerTypeId) => dispatch(providerConfigReqHandler.deleteProviderConfig(data, deleteItemIndex, providerTypeId))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Admin));