import React from 'react';

export const TestsSelectors = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.tests.title')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='laboratoryTestsUrl'>{t('myChartProviders.shared.url')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.laboratoryTestsUrl')}
                        />
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='laboratoryTestsSelectorAll'>{t('myChartProviders.shared.date')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.laboratoryTestsSelectorAll')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='laboratoryTestsSelectorUrls'>{t('myChartProviders.tests.detailUrls')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.laboratoryTestsSelectorUrls')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

)