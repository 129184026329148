import React, { Fragment } from 'react';

export const Gradebook = ({ profiles, t }) => {

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('education.gradebooks.gradebooksTitle')}</h4>
                <div className="profileContent">
                    {
                        profiles.map((currProfile, i) => {
                            if(currProfile.gradebooks && currProfile.gradebooks.length > 0){                       
                                return <Fragment key={i}>                                                                   
                                    <div className="row">
                                        <div className="col-md-6"> </div>
                                        <div className="col-md-3">{currProfile.exportPersonName}</div>
                                        <div className="col-md-3">{currProfile.providerName}</div>
                                    </div>
                                    
                                    <div className="row">
                                        <div className="col-md-3 customLabel">{t('education.shared.className', true)}</div>
                                        <div className="col-md-2 customLabel">{t('education.shared.period', true)}</div>
                                        <div className="col-md-2 customLabel">{t('education.gradebooks.teacher', true)}</div>
                                        <div className="col-md-4 customLabel">{t('education.gradebooks.marks', true)}</div>
                                    </div>

                                    {currProfile.gradebooks &&
                                        currProfile.gradebooks.map((item, k) => {
                                            return <div key={k} className="row">
                                                <div className="col-md-3">
                                                    <span>{item.className}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.period}</span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span>{item.teacher}</span>
                                                </div>
                                                <div className="col-md-4">
                                                    {item.marks.map((s, i) => {
                                                        return <Fragment key={i}>
                                                            <span className="persInfoPtag"> {s.mark} </span>
                                                        </Fragment>
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }
                                </Fragment>
                            }
                        })
                    }
                </div>
            </div>
        </div>

    )
}