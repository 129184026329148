import React, { Component, Fragment } from 'react';
import MyChartCreateProfile from './MyChart/CreateProfile';
import EducationCreateProfile from './Education/CreateProfile';
import providerConfigReqHandler from '../../api/providersConfigRemote';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
toastr.options.closeButton = true;

class PersonalProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            providerType:{
                id:'',
                name:'',
            },
            person: '',
            selectedProviderId: '',
            isSelectType: true
        };

        this.providersTypeSelect = this.providersTypeSelect.bind(this);
    }

    componentDidMount() {
        if (!this.props.providersType) {
            this.props.loadProvidersTypeList()
        }
    }

    providersTypeSelect = (event) => {

        const providerType = event.target.value;
        if(providerType==='default') {
            this.setState ({
                isSelectType: false,
            })
        } else {
            const type = this.props.providersType.find((pr)=> {
                return pr.id===providerType;
            })
            this.setState ({
                providerType: {
                    id:providerType,
                    name: type.name,
                },
                isSelectType:true,
            })
        }

    }

    render() {
        const { t } = this.props;

        return (
            <Fragment>
                <div className="container">
                    {this.state.isSelectType &&
                        <div className="container">
                            <div className="container"></div>
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-xs-8">
                                    <label htmlFor="typeProviders">{t('addProfile.selectProviderField')}</label>
                                    <select className="form-control" id="providers" onChange={this.providersTypeSelect}>
                                        <option value="default" >{t('addProfile.dropDownDefaultValue')}</option>
                                        {this.props.providersType && this.props.providersType.length > 0 &&
                                            this.props.providersType.map((providersType, i) => {
                                                return <option key={ providersType.id } value={ providersType.id } >{ providersType.name.toUpperCase() }</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-lg-4 col-sm-6 col-xs-8"></div>
                                <div className="col-lg-4 col-sm-4 col-xs-4"></div>
                            </div>

                            <div className="container">
                                {this.state.providerType.name &&
                                    <div className="row">

                                        {this.state.providerType.name.toUpperCase() === 'MYCHART' &&
                                            <MyChartCreateProfile {...this.props} />}
                                        {this.state.providerType.name.toUpperCase() === 'EDUCATION' &&
                                            <EducationCreateProfile  {...this.props} />}
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </Fragment>
        );
    }
};

const mapStateToProps = state => {
    return {
        providersType: state.providers.types
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loadProvidersTypeList: () => dispatch(providerConfigReqHandler.getTypeProviders())
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PersonalProfile));