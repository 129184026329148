import React, { Component, Fragment } from 'react';
import userReqHandler from '../../api/userRemote';
import toastr from 'toastr';

class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: {},
            errors: {}
        };
    }

    onChangeHandler(field, event) {
        const fields = this.state.fields;
        fields[field] = event.target.value;        
        this.setState({ fields });
    }

    onFormSubmit(event) {
        event.preventDefault();

        if(this.handleValidation()) {

            const passwordData = {
                pw: this.state.fields["password"],
                rpw: this.state.fields["repPassword"],
                token: this.props.match.params.token
            };

            userReqHandler.changePassword(passwordData).then(res => {

                if (res) {
                    this.props.history.push('/login');
                    toastr.success('Password changed successful.');
                }
            });
        }
    }

    handleValidation() {

        const fields = this.state.fields;
        const errors = {};
        const strongPassRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/);
        let formIsValid = true;

        // Password
        if(!fields["password"]){
            formIsValid = false;
            errors["password"] = "Field cannot be empty.";
        }

        if(typeof fields["password"] !== "undefined"){  

            if (!strongPassRegex.test(fields["password"])) {
                formIsValid = false;
                errors["password"] = "Password must contains at least 1 lowercase, 1 uppercase alphabetical character, 1 numeric character, one special character and eight characters or longer.";
            }
        } 

        // Repeat Password
        if(!fields["repPassword"]){
            formIsValid = false;
            errors["repPassword"] = "Field cannot be empty.";
        }

        if(typeof fields["repPassword"] !== "undefined"){  

            if (!strongPassRegex.test(fields["repPassword"])) {
                formIsValid = false;
                errors["repPassword"] = "Password must contains at least 1 lowercase, 1 uppercase alphabetical character, 1 numeric character, one special character and eight characters or longer.";
            }
        }

        // Compare Password and Repeat Password
        if (formIsValid) {
            formIsValid = this.state.fields["password"] === this.state.fields["repPassword"];

            if (!formIsValid) {
                errors["password"] = "Passwords not match.";
                errors["repPassword"] = "Passwords not match.";
            }
        }
       
        this.setState({ errors: errors });

        return formIsValid;
    }

    render() {
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-xs-6">
                            <h2>Enter your new login credentials.</h2>
                            <form className="login-form" onSubmit={ this.onFormSubmit.bind(this) } >

                                <div className="form-group">
                                    <label htmlFor="password">Password:</label>
                                    <input
                                        ref="password"
                                        id="password"
                                        className="form-control"
                                        type="password"
                                        name="password"
                                        onChange={ this.onChangeHandler.bind(this, "password") } 
                                    />
                                    <span className="inputErrorText">{ this.state.errors["password"] }</span>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="repPassword">Repeat Password:</label>
                                    <input
                                        ref="repPassword"
                                        id="repPassword"
                                        className="form-control"
                                        type="password"
                                        name="repPassword"
                                        onChange={ this.onChangeHandler.bind(this, "repPassword") }
                                    />
                                    <span className="inputErrorText">{ this.state.errors["repPassword"] }</span>
                                </div>

                                <input type="submit" className="btn btn-primary" value="Change" />
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };
};

export default ResetPassword;