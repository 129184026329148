import React, { Component, Fragment } from "react";
import profileReqHandler from "../../api/profileRemote";
import exportReqHandler from "../../api/exportRemote";
import authentication from "../../utilities/authentication";
import tempLinksRemote from "../../api/tempLinksRemote";
import toastr from "toastr";
import Loader from "react-loader-spinner";
import {
  PersonalInfo,
  Allergies,
  Diagnosis,
  History,
  Immunizations,
  PlanOfCare,
  LaboratoryTests,
  Medications,
  Files,
  Visits,
} from "./MyChartComponents";
import {
  EducationalData,
  HealthData,
  Gradebook,
  Attendance,
  Discipline,
} from "./EducationComponents";
import {
  ChildInfoAddressAndContacts,
  Grades,
  AssignmentsAndGrades,
  Absences,
} from "./FwisdComponents";

import { withTranslation } from "react-i18next";
import { tWrapper } from "../../utilities/translationWrapper";
import { Formik } from "formik";
import * as Yup from "yup";

class Summary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profiles: [],
      files: [],
      showTables: {
        personalInfo: {
          dateOfBirth: true,
        },
      },
      isGeneratedFromTempLink: false,
      errors: {},
      fields: {},
      tempLinkCodeAccess: false,
    };
    this.printProfile = this.printProfile.bind(this);
  }

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.guid
    ) {
      this.setState({ tempLinkCodeAccess: true });
      return;
    }

    this.loadProfiles(undefined, false);
  }

  printProfile() {
    window.print();
  }

  loadTempLink() {
    tempLinksRemote
      .getTempLinks(this.props.match.params.guid)
      .then((response) => {
        const personId = response.content.parentData;
        this.loadProfileFromTempLink(personId, true);
      })
      .catch((error) => console.log(error));
  }

  checkAccessCode(code) {
    const guid = this.props.match.params.guid;
    tempLinksRemote.checkAccessCode(code, guid).then((response) => {
      if (response) {
        this.setState({ tempLinkCodeAccess: false });
        this.loadTempLink();
      } else {
        this.setState({ errors: "Invalid access code" });
      }
    });
  }

  loadProfiles(personId, isTempLink) {
    //if we have personId it means it is generated from templink and we should hide buttons.
    //Otherwise we show them. Visibility is controlled by isGeneratedFromTempLink
    if (personId === undefined) {
      personId = authentication.getPerson().id;
    }

    this.setState({ isGeneratedFromTempLink: isTempLink });

    this.setState({ personId: personId });

    profileReqHandler
      .summaryProfilesList(personId)
      .then(async (res) => {
        this.setState({ ...res });
        await this.SetEducationVisibility(res.profiles);
        await this.SetMyChartVisibility(res.profiles);
        // await this.SetVisitsVisibility(res.visits);
        await this.SetMedicalQuestions(res.profiles);
      })
      .then(() => {
        if (isTempLink) {
          // window.print();
          // window.close();
        }
      });

      profileReqHandler
      .summaryVisitsList(personId)
      .then(async (res) => {
        this.setState({ ...res });
        await this.SetVisitsVisibility(res.visits);
      })
      .then(() => {
        if (isTempLink) {
          // window.print();
          // window.close();
        }
      });
  }

  loadProfileFromTempLink(personId, isTempLink) {
    this.setState({ isGeneratedFromTempLink: isTempLink });

    this.setState({ personId: personId });

    profileReqHandler
      .summaryProfilesListFromTempLink(personId)
      .then(async (res) => {
        this.setState({ ...res });
        await this.SetEducationVisibility(res.profiles);
        await this.SetMyChartVisibility(res.profiles);
        // await this.SetVisitsVisibility(res.visits);
        await this.SetMedicalQuestions(res.profiles);
      })
      .then(() => {
        if (isTempLink) {
          // window.print();
          // window.close();
        }
      });

      profileReqHandler
      .summaryVisitsListFromTempLink(personId)
      .then(async (res) => {
        this.setState({ ...res });
        await this.SetVisitsVisibility(res.visits);
      })
      .then(() => {
        if (isTempLink) {
          // window.print();
          // window.close();
        }
      });
  }

  generateTempLink(formik, recipient) {
    if (window.confirm(this.props.t("summary.confirmAccessCode"))) {
      formik.validateForm().then((err) => {
        if (Object.keys(err).length === 0 && err.constructor === Object) {
          const loggedData = authentication.loggedData();
          const tempLink = {
            user: loggedData.userId,
            email: recipient.value,
            profileId: null,
            content: {
              parentData: this.state.personId,
            },
          };

          tempLinksRemote.createTempLinkSummary(tempLink).then((res) => {
            if (res) {
              toastr.success(this.props.t("summary.templinkGenerated"));
            }
          });
        } else {
          formik.setFieldTouched("email");
        }
      });
    }
  }

  async SetMedicalQuestions(profiles) {
    let subjectList = [];
    let recipientList = [];
    for (const profile of profiles) {
      if (
        (this.state.subjectList && this.state.subjectList.length > 0) ||
        (this.state.recipientList && this.state.recipientList.length > 0)
      )
        return;
      for (const subject of profile.medicalQuestions.subjects) {
        subjectList.push(subject);
      }
      for (const recipient of profile.medicalQuestions.recipients) {
        recipientList.push(recipient);
      }
    }
    this.setState({ subjectList: subjectList });
    this.setState({ recipientList: recipientList });
  }

  async SetEducationVisibility(profiles) {
    this.checkIfEducationProfileHasAnyData(profiles);
    this.checkIfGradebookHasAnyData(profiles);
    this.checkIfAttendanceHasAnyData(profiles);
    this.checkIfDisciplineHasAnyData(profiles);
    this.checkIfHealthHasAnyData(profiles);
    this.checkIfChildInfoAddressAndContactsHasAnyData(profiles);
    this.checkIfAssignemntsAndGradesHasAnyData(profiles);
    this.checkIfGradesHasAnyData(profiles);
    this.checkIfAbsencesHasAnyData(profiles);
  }

  checkIfEducationProfileHasAnyData(profiles) {
    for (const profile of profiles) {
      for (var property in profile.studentInfo) {
        if (profile.studentInfo[property] !== null) {
          if (profile.studentInfo[property].length > 0) {
            this.setState({ studentInfoVisibility: true });
            return;
          }
        }
      }

      for (var property in profile.childInfoGeneral) {
        if (profile.childInfoGeneral[property] !== null) {
          if (profile.childInfoGeneral[property].length > 0) {
            console.log("//child info");
            this.setState({ studentInfoVisibility: true });
            return;
          }
        }
      }

      for (var property in profile.healthInfo) {
        if (profile.healthInfo[property].length > 0) {
          this.setState({ studentInfoVisibility: true });
          return;
        }
      }

      if (profile.gradebooks.length > 0) {
        this.setState({ studentInfoVisibility: true });
        return;
      }
      if (profile.attendances.length > 0) {
        this.setState({ studentInfoVisibility: true });
        return;
      }
      if (profile.disciplines.length > 0) {
        this.setState({ studentInfoVisibility: true });
        return;
      }
    }

    this.setState({ studentInfoVisibility: false });
  }

  checkIfGradebookHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.gradebooks.length > 0) {
        this.setState({ gradeBookVisibility: true });
        return;
      }
    }

    this.setState({ gradeBookVisibility: false });
  }

  checkIfDisciplineHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.disciplines.length > 0) {
        this.setState({ disciplineVisibility: true });
        return;
      }
    }

    this.setState({ disciplineVisibility: false });
  }

  checkIfAttendanceHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.attendances.length > 0) {
        this.setState({ attendanceVisibility: true });
        return;
      }
    }

    this.setState({ attendanceVisibility: false });
  }

  checkIfHealthHasAnyData(profiles) {
    for (const profile of profiles) {
      for (var property in profile.healthInfo) {
        if (profile.healthInfo[property].length > 0) {
          this.setState({ healthVisibility: true });
          return;
        }
      }
    }

    this.setState({ healthVisibility: false });
  }

  checkIfChildInfoAddressAndContactsHasAnyData(profiles) {
    for (const profile of profiles) {
      console.log(profile);
      if (
        profile.childInfoAddressAndContacts &&
        profile.childInfoAddressAndContacts.length > 0
      ) {
        this.setState({ childInfoAddressAndContactsVisibility: true });
        return;
      }
    }

    this.setState({ childInfoAddressAndContactsVisibility: false });
  }

  checkIfAssignemntsAndGradesHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.assignmentsAndGrades.length > 0) {
        this.setState({ assignmentsAndGradesVisibility: true });
        return;
      }
    }

    this.setState({ assignmentsAndGradesVisibility: false });
  }

  checkIfGradesHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.grades.length > 0) {
        this.setState({ gradesVisibility: true });
        return;
      }
    }

    this.setState({ gradesVisibility: false });
  }

  checkIfAbsencesHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.absences.length > 0) {
        this.setState({ absencesVisibility: true });
        return;
      }
    }

    this.setState({ absencesVisibility: false });
  }

  // Mychart visibility
  async SetMyChartVisibility(profiles) {
    this.checkIfAllergiesHasAnyData(profiles);
    this.checkIfDiagnosisHasAnyData(profiles);
    this.checkIfHistoryHasAnyData(profiles);
    this.checkIfPlanOfCareHasAnyData(profiles);
    this.checkIfLaboratoryTestsHasAnyData(profiles);
    this.checkIfImmunizationHasAnyData(profiles);
    this.checkIfMedicationsHasAnyData(profiles);
    this.checkIfFilesHasAnyData(profiles);
  }

  async SetVisitsVisibility(visits) {
    debugger;
    if (visits && visits.length > 0) {
        visits.forEach(visit => {
            if(visit.isVisible === true){
                this.setState({ visitsVisibility: true });
                return;
            }
        });
      
      return;
    }

    this.setState({ visitsVisibility: false });
  }

  checkIfAllergiesHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.allergies.length > 0) {
        this.setState({ allergiesVisibility: true });
        return;
      }
    }

    this.setState({ allergiesVisibility: false });
  }

  checkIfDiagnosisHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.diagnosis.length > 0) {
        this.setState({ diagnosisVisibility: true });
        return;
      }
    }

    this.setState({ diagnosisVisibility: false });
  }

  checkIfHistoryHasAnyData(profiles) {
    for (const profile of profiles) {
      for (var property in profile.history) {
        if (profile.history[property].length > 0) {
          this.setState({ historyVisibility: true });
          return;
        }
      }
    }

    this.setState({ historyVisibility: false });
  }

  checkIfPlanOfCareHasAnyData(profiles) {
    for (const profile of profiles) {
      for (var property in profile.planOfCare) {
        if (profile.planOfCare[property].length > 0) {
          this.setState({ planOfCareVisibility: true });
          return;
        }
      }
    }

    this.setState({ planOfCareVisibility: false });
  }

  checkIfLaboratoryTestsHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.laboratoryTests.length > 0) {
        this.setState({ laboratoryTestsVisibility: true });
        return;
      }
    }

    this.setState({ laboratoryTestsVisibility: false });
  }

  checkIfImmunizationHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.immunizations.length > 0) {
        this.setState({ immunizationVisibility: true });
        return;
      }
    }

    this.setState({ immunizationVisibility: false });
  }

  checkIfMedicationsHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.medications.length > 0) {
        this.setState({ medicationsVisibility: true });
        return;
      }
    }

    this.setState({ medicationsVisibility: false });
  }

  checkIfFilesHasAnyData(profiles) {
    for (const profile of profiles) {
      if (profile.files.length > 0) {
        this.setState({ filesVisibility: true });
        return;
      }
    }

    this.setState({ filesVisibility: false });
  }

  recipientSelect(event) {}
  subjectSelect(event) {}

  onFormSubmitAccessCode(event) {
    event.preventDefault();
    this.checkAccessCode(this.state.fields["code"]);
  }

  onFormSubmit(value, resetForm) {
    const formData = {
      title: value.title,
      email: value.email,
      personId: authentication.getPerson().id,
    };

    if (window.confirm(this.props.t("summary.confirm"))) {
      exportReqHandler.export(formData).then((res) => {
        if (res) {
          toastr.success(this.props.t("summary.success"));

          resetForm({});
        }
      });
    }
  }

  onChangeHandler(field, event) {
    const fields = this.state.fields;
    fields[field] = event.target.value;
    this.setState({ fields });
  }

  render() {
    const t = tWrapper(this.props.t);

    console.log(this.state.isGeneratedFromTempLink);

    if (this.state.tempLinkCodeAccess) {
      return (
        <div className="container">
          <div className="row">
            <div className="access-code-container">
              {/* <h2>{t('login.title')}</h2> */}
              <form
                className="login-form"
                onSubmit={this.onFormSubmitAccessCode.bind(this)}
              >
                <div className="form-group">
                  <label htmlFor="code">
                    {t("profileTab.tempLink.accessCode")}
                  </label>
                  <input
                    ref="code"
                    id="code"
                    className="form-control"
                    type="text"
                    placeholder="Code"
                    name="code"
                    onChange={this.onChangeHandler.bind(this, "code")}
                  />
                  {this.state.errors.length > 0 && (
                    <span className="inputErrorText">{this.state.errors}</span>
                  )}
                </div>

                <input
                  type="submit"
                  className="btn btn-primary"
                  value={t("profileTab.tempLink.checkCode")}
                />
              </form>
            </div>
          </div>
        </div>
      );
    } else {
      if (this.state.profiles && this.state.profiles.length > 0) {
        return (
          <div className="container">
            {this.state.isGeneratedFromTempLink && (
              <div className="center-screen">
                <button
                  type="button"
                  className="btn btn-danger width-300"
                  onClick={this.printProfile}
                >
                  {t("profileTab.tempLink.print")}
                </button>
              </div>
            )}
            {!this.state.tempLinkCodeAccess && (
              <div className="row">
                <Fragment>
                  <div className="col-lg-12 col-sm-10 col-xs-12">
                    <h2>{t("summary.title")}</h2>

                    <PersonalInfo profiles={this.state.profiles} t={t} />
                    {this.state.allergiesVisibility === true && (
                      <Allergies profiles={this.state.profiles} t={t} />
                    )}
                    {this.state.diagnosisVisibility === true && (
                      <Diagnosis profiles={this.state.profiles} t={t} />
                    )}
                    {this.state.historyVisibility === true && (
                      <History profiles={this.state.profiles} t={t} />
                    )}
                    {this.state.planOfCareVisibility === true && (
                      <PlanOfCare profiles={this.state.profiles} t={t} />
                    )}
                    {this.state.immunizationVisibility === true && (
                      <Immunizations profiles={this.state.profiles} t={t} />
                    )}
                    {this.state.laboratoryTestsVisibility === true && (
                      <LaboratoryTests profiles={this.state.profiles} t={t} />
                    )}
                    {this.state.medicationsVisibility === true && (
                      <Medications profiles={this.state.profiles} t={t} />
                    )}
                    {this.state.filesVisibility === true && (
                      <Files
                        profiles={this.state.profiles}
                        files={this.state.files}
                        t={t}
                      />
                    )}
                    {/* <Visits visits={this.state.visits} t={t} /> */}

                    <br />

                    {(this.state.studentInfoVisibility === true ||
                      this.state.childGeneralInfoVisibility === true) && (
                      <div className="profileContent">
                        <div className="row">
                          <h3 className="headName">
                            {t("summary.educationalTitle")}
                          </h3>
                        </div>
                        <EducationalData profiles={this.state.profiles} t={t} />

                        {this.state.healthVisibility === true && (
                          <HealthData profiles={this.state.profiles} t={t} />
                        )}
                        {this.state.gradeBookVisibility === true && (
                          <Gradebook profiles={this.state.profiles} t={t} />
                        )}

                        {this.state.attendanceVisibility === true && (
                          <Attendance profiles={this.state.profiles} t={t} />
                        )}

                        {this.state.disciplineVisibility === true && (
                          <Discipline profiles={this.state.profiles} t={t} />
                        )}

                        {this.state.childInfoAddressAndContactsVisibility ===
                          true && (
                          <ChildInfoAddressAndContacts
                            profiles={this.state.profiles}
                            t={t}
                          />
                        )}

                        {this.state.assignmentsAndGradesVisibility === true && (
                          <AssignmentsAndGrades
                            profiles={this.state.profiles}
                            t={t}
                          />
                        )}

                        {this.state.gradesVisibility === true && (
                          <Grades profiles={this.state.profiles} t={t} />
                        )}

                        {this.state.absencesVisibility === true && (
                          <Absences
                            profiles={this.state.profiles}
                            t={t}
                            isGeneratedFromTempLink={
                              this.state.isGeneratedFromTempLink
                            }
                          />
                        )}
                      </div>
                    )}

                    <br />
                    <div className="profileContent">
                      <div className="row">
                        <h3 className="headName">
                          {t("summary.visitsTitle")}
                        </h3>
                      </div>
                      {this.state.visitsVisibility === true && (
                        <Visits visits={this.state.visits} t={t} />
                      )}
                    </div>
                    {!this.state.isGeneratedFromTempLink && (
                      <div className="row">
                        <h2>{t("summary.exportToMail")}</h2>
                        {/* <div className="col-md-2"></div> */}
                        <div className="col-md-10" id="exportDiv">
                          <Formik
                            validateOnChange={false}
                            validateOnBlur={true}
                            initialValues={{
                              title: "",
                              email: "",
                            }}
                            validationSchema={Yup.object({
                              title: Yup.string().required(
                                t("validationErrors.fieldEmpty")
                              ),
                              email: Yup.string()
                                .required(t("validationErrors.fieldEmpty"))
                                .matches(
                                  new RegExp(
                                    /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
                                  ),
                                  t("validationErrors.invalidField")
                                ),
                            })}
                            onSubmit={(values, { resetForm }) => {
                              this.onFormSubmit(values, resetForm);
                            }}
                          >
                            {(formik) => (
                              <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                  <div className="col-md-5">
                                    <input
                                      className="form-control"
                                      placeholder={t(
                                        "summary.emailPlaceholder"
                                      )}
                                      {...formik.getFieldProps("title")}
                                    />
                                    {formik.touched.title &&
                                    formik.errors.title ? (
                                      <span className="inputErrorText">
                                        {formik.errors.title}
                                      </span>
                                    ) : null}
                                  </div>
                                  <div className="col-md-5">
                                    <input
                                      className="form-control"
                                      placeholder={t(
                                        "summary.emailRecipientPlaceholder"
                                      )}
                                      {...formik.getFieldProps("email")}
                                    />
                                    {formik.touched.email &&
                                    formik.errors.email ? (
                                      <span className="inputErrorText">
                                        {formik.errors.email}
                                      </span>
                                    ) : null}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12">
                                    <input
                                      type="submit"
                                      className="btn btn-primary summary-buttons-export"
                                      value={t("summary.exportButton")}
                                    />
                                    <button
                                      type="button"
                                      className="btn btn-primary summary-buttons-export"
                                      onClick={this.generateTempLink.bind(
                                        this,
                                        formik,
                                        formik.getFieldProps("email")
                                      )}
                                    >
                                      {t("profileTab.tempLink.popupButton")}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    )}

                    {!this.state.isGeneratedFromTempLink && (
                      <div className="row">
                        <h2>{t("summary.requestForMedicalAdvice")}</h2>

                        {/* <div className="col-lg-4 col-sm-6 col-xs-8"> */}
                        <div className="col-md-10" id="exportDiv">
                          <div className="col-md-5">
                            <select
                              className="form-control"
                              id="recipients"
                              onChange={(event) => this.recipientSelect(event)}
                            >
                              <option value="default">
                                {t("summary.selectRecipientField")}
                              </option>

                              {this.state.recipientList &&
                                this.state.recipientList.map((recipient, i) => {
                                  return (
                                    <option key={i} value={recipient}>
                                      {recipient.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-md-5">
                            <select
                              className="form-control"
                              id="objects"
                              onChange={(event) => this.subjectSelect(event)}
                            >
                              <option value="default">
                                {t("summary.selectObjectField")}
                              </option>
                              {this.state.subjectList &&
                                this.state.subjectList.map((object, i) => {
                                  return (
                                    <option key={i} value={object}>
                                      {object.name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <div className="col-md-2">
                            <input
                              type="submit"
                              className="btn btn-primary"
                              value={t("summary.sendRequestButton")}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Fragment>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div className="conteiner-fixed-height">
            <div className="row">
              <div className="col-lg-12 col-sm-10 col-xs-6 ">
                <h2>{t("summary.title")}</h2>
                <div className="center-screen">
                  <Loader
                    type="Oval"
                    color="#00BFFF"
                    height={80}
                    width={80}
                  ></Loader>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }
}

export default withTranslation()(Summary);
