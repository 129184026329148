import React from 'react';

export const ImmunizationsSelectors = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.immunizationsTitle')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='immunizationsUrl'>{t('myChartProviders.shared.url')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.immunizationsUrl')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='immunizationsSelectorAll'>{t('myChartProviders.shared.all')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.immunizationsSelectorAll')}
                        />
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='immunizationsSelectorName'>{t('myChartProviders.shared.name')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.immunizationsSelectorName')}
                        />
                    </div>
                </div>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='immunizationsSelectorDate'>{t('myChartProviders.shared.date')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.immunizationsSelectorDate')}
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>

)