import React, { Component, Fragment } from 'react';

class History extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        const filedData = property.split('-');
        const fieldName = filedData[0];
        const fieldId = parseInt(filedData[1]);

        switch (fieldName) {

            case 'history':
                return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';

            case 'familyHistory':
            case 'medicalHistory':
            case 'surgicalHistory':

                return this.props.visibilitySchema.history[fieldName][fieldId] ? 'eyeBlack' : 'eyeGray';

            case 'alcoholUse':
            case 'smokingTobacco':
            case 'smokelessTobacco':

                return this.props.visibilitySchema.history.socialHistory[fieldName] ? 'eyeBlack' : 'eyeGray';

            default: break; 
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                <div>
                    { this.props.profile.content && this.props.showVisibilitySettings &&
                        <i className={"fa fa-eye " + ( this.eyeToggle('history') )}
                            onClick={ () => this.props.eyeClicked() }>
                        </i>
                    }
                    <h4 className="headName">{t('myChart.history.historyTitle')}</h4>
                    { this.props.profile.content &&

                        <div className="profileContent">

                            <div className="row">
                                <div className="historyLabel">{t('myChart.history.familyHistoryTitle')}</div>
                                <div className="col-md-1"></div>
                                <div className="col-md-4 customLabel">{t('myChart.history.healthIssue')}</div>
                                <div className="col-md-3 customLabel">{t('myChart.history.relationship')}</div>
                                <div className="col-md-4 customLabel">{t('myChart.history.comment')}</div>
                            </div>

                            { this.props.profile.content.history &&

                                this.props.profile.content.history.familyHistory.map((fh, i) => {
                                    return <Fragment key={ i }>
                                        <div className="row trows ">
                                            <div className="col-md-1">
                                                { this.props.showVisibilitySettings &&
                                                    <i className={"fa fa-eye " + ( this.eyeToggle( 'familyHistory-' + i ) )}
                                                        onClick={ () => this.props.eyeClicked('familyHistory', i) }>
                                                    </i>
                                                }
                                            </div>
                                            <div className="col-md-4">{ fh.healthIssue }</div>
                                            <div className="col-md-3">{ fh.relationship }</div>
                                            <div className="col-md-4">{ fh.comment }</div>
                                        </div>
                                    </Fragment>
                                })
                            }

                            <div className="row">
                                <div className="historyLabel">{t('myChart.history.medicalHistoryTitle')}</div>
                                <div className="col-md-1"></div>
                                <div className="col-md-5 customLabel">{t('myChart.shared.name')}</div>
                                <div className="col-md-6 customLabel">{t('myChart.shared.when')}</div>
                            </div>

                            { this.props.profile.content.history &&

                                this.props.profile.content.history.medicalHistory.map((mh, i) => {
                                    return <Fragment key={ i }>
                                        <div className="row trows ">
                                            <div className="col-md-1">
                                                { this.props.showVisibilitySettings &&
                                                    <i className={"fa fa-eye " + ( this.eyeToggle( 'medicalHistory-' + i ) )}
                                                        onClick={ () => this.props.eyeClicked('medicalHistory', i) }>
                                                    </i>
                                                }
                                            </div>
                                            <div className="col-md-5">{ mh.name }</div>
                                            <div className="col-md-6">{ mh.when }</div>
                                        </div>
                                    </Fragment>
                                })
                            }

                            <div className="row">
                                <div className="historyLabel">{t('myChart.history.socialHistoryTitle')}</div>
                            </div>

                            {/* Alcohol Use */}
                            <div className="row trows ">
                                <div className="col-md-1">
                                    { this.props.showVisibilitySettings &&
                                        <i className={"fa fa-eye " + ( this.eyeToggle( 'alcoholUse' ) )}
                                            onClick={ () => this.props.eyeClicked('socialHistory.alcoholUse') }>
                                        </i>
                                    }
                                </div>
                                <div className="col-md-5">{t('myChart.history.alcoholUse')} { this.props.profile.content.history.socialHistory.alcoholUse.alcoholUse }</div>
                                <div className="col-md-5">{t('myChart.history.ouncesWeek')} { this.props.profile.content.history.socialHistory.alcoholUse.ouncesWeek }</div>
                            </div>
                            {/* Alcohol Use */}

                            {/* Smoking Tobacco */}
                            <div className="row trows ">
                                <div className="col-md-1">
                                    { this.props.showVisibilitySettings &&
                                        <i className={"fa fa-eye " + ( this.eyeToggle( 'smokingTobacco' ) )} 
                                            data-key={ 'smokingTobacco' } 
                                            onClick={ () => this.props.eyeClicked('socialHistory.smokingTobacco') }>
                                        </i>
                                    }
                                </div>
                                <div className="col-md-4">{t('myChart.history.smokingTobaccoUse')} { this.props.profile.content.history.socialHistory.smokingTobacco.smokingTobaccoUse }</div>
                                <div className="col-md-2">{t('myChart.history.yearsSmoked')} { this.props.profile.content.history.socialHistory.smokingTobacco.yearsSmoked }</div>
                                <div className="col-md-3">{t('myChart.history.tobaccoTypes')} { this.props.profile.content.history.socialHistory.smokingTobacco.smokingTobaccoTypes }</div>
                                <div className="col-md-2">{t('myChart.history.packsPerDay')} { this.props.profile.content.history.socialHistory.smokingTobacco.packsDay }</div>
                            </div>
                            {/* Smoking Tobacco */}

                            {/* Smokeless Tobacco */}
                            <div className="row trows ">
                                <div className="col-md-1">
                                    { this.props.showVisibilitySettings &&
                                        <i className={"fa fa-eye " + ( this.eyeToggle( 'smokelessTobacco' ) )}
                                        onClick={ () => this.props.eyeClicked('socialHistory.smokelessTobacco') }>
                                        </i>
                                    }
                                </div>
                                <div className="col-md-5">{t('myChart.history.smokelessTobacco')} { this.props.profile.content.history.socialHistory.smokelessTobacco.smokelessTobaccoUse }</div>
                                <div className="col-md-6">{t('myChart.history.smokelessTobaccoTypes')} { this.props.profile.content.history.socialHistory.smokelessTobacco.smokelessTobaccoTypes }</div>
                            </div>
                            {/* Smokeless Tobacco */}

                            <div className="row">
                                <div className="historyLabel">{t('myChart.history.surgicalHistoryTitle')}</div>
                                <div className="col-md-1"></div>
                                <div className="col-md-5 customLabel">{t('myChart.shared.name')}</div>
                                <div className="col-md-6 customLabel">{t('myChart.shared.when')}</div>
                            </div>

                            { this.props.profile.content.history &&

                                this.props.profile.content.history.surgicalHistory.map((sh, i) => {
                                    return <Fragment key={ i }>
                                        <div className="row trows ">
                                            <div className="col-md-1">
                                                { this.props.showVisibilitySettings &&
                                                    <i className={"fa fa-eye " + ( this.eyeToggle( 'surgicalHistory-' + i ) )} 
                                                        data-key={ 'surgicalHistory-' + i } 
                                                        onClick={ () => this.props.eyeClicked('surgicalHistory', i) }>
                                                    </i>
                                                }
                                            </div>
                                            <div className="col-md-5">{ sh.name }</div>
                                            <div className="col-md-6">{ sh.when }</div>
                                        </div>
                                    </Fragment>
                                })
                            }

                        </div>
                    }
                </div>
                </div>
            </div>
        );
    };
};

export default History;