import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import authentication from '../../utilities/authentication';

const PrivateRoute = ({ component: Component, ...rest }) => {

  const loggedObject = authentication.loggedData();
  const isLoggedIn = loggedObject.loggedIn;

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect to={
                        { pathname: '/login', state: { from: props.location } }
                       }
          />
        )
      }
    />
  )
}

export default PrivateRoute;