import cookies from './cookies';

function loggedData() {

    const userName = parseSignedCookie(cookies.get('uname'));
    const userId = parseSignedCookie(cookies.get('uid'));
    const userType = parseSignedCookie(cookies.get('utype'));

    // const userName = cookies.get('uname');
    // const userId = cookies.get('uid');
    // const userType = cookies.get('utype');
    
    let loggedIn = false;

    if (userName !== undefined && userId !== undefined) {
        loggedIn = true;
    }

    const data = {
        loggedIn: loggedIn,
        username: userName,
        userId: userId,
        userType: userType,
    };

    return data;
}

function getPerson(){
    const person = {
        id: cookies.get('person')
    }
    return person;
}

function logout() {

    cookies.remove('uname');    
    cookies.remove('uid');
    cookies.remove('utype');
    window.location = '/login';
}

function parseSignedCookie(cookie) {

    //cookie comes in format => s:Ivan Ivanov.uZz80tYUImjagwTI7aOvYWNfOsxYIZCR3ieXn8dLYE8
    if (cookie !== undefined) {
        return cookie.substring(2, cookie.indexOf('.'))
    }

    return cookie;
}

export default {
    loggedData,
    logout,
    getPerson
}