import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import fileTransferReqHandler from '../../api/fileTransferRemote';
import settingsReqHandler from '../../api/settingsRemote';
import PdfViewer from '../PdfViewer';
import { Formik, Field } from 'formik';
// const  { DOM: { input } } = React;

class Consent extends Component {

    intervalID;
    settings = {
        forthWorthSharingConsent: true,
        timeElapsed: 20,
    }

    constructor(props) {
        super(props);

        this.state = {
            settings: {},
            openPdf: null,
        };

        this.getConsents = this.getConsents.bind(this);
    }

    componentDidMount() {
        
        this.getConsents();
    }

    getConsents() {
        settingsReqHandler.getSettings().then((res) => {
            console.log(res);
            if(res){                
                const settings = res;
                this.settings.forthWorthSharingConsent = settings.value.forthWorthSharingConsent;
                this.settings.timeElapsed = settings.value.timeElapsed;
               
                this.setState({settings: settings.value})
            }
            else{
                this.setState({settings: this.settings})
            }
        })   
    }

    openFile() {
        const consentsConst = 'Consents';
        fileTransferReqHandler.openFile(consentsConst).then(res => {
            const fileUrl = URL.createObjectURL(res);
            this.setState({ openPdf: fileUrl });
        });
    }

    onChangeHandler(event, value) {
        const consents = this.state.consents.map(consent => {
            if (consent.name === value.name) {
                return {
                    ...consent,
                    value: event.target.checked
                }
            }
        });

        this.setState({ consents: consents });
    }

    onFormSubmit(values) {
        const settings = values;
        console.log(values);
        settingsReqHandler.saveSettings(settings).then(res => {
            if(res){
                console.log(res);
            }
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <h2>{t('settings.title')}</h2>
                        <br />
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={true}
                            initialValues={this.settings}
                            enableReinitialize={true}
                            onSubmit={(values) => this.onFormSubmit(values)}
                        >
                            {formik => (
                                <form onSubmit={formik.handleSubmit}>
                                    <h4>{t('settings.consent.title')}</h4>
                                    <br />
                                    <div className='row no-padding'>
                                        <div className='col-md-12'>
                                            <div className='form-group'>
                                                <div className='col-md-6'>
                                                    <label htmlFor='forthWorthSharingConsent'>ISD Forth Worth Data Sharing Consent</label>
                                                </div>
                                                <div className='col-md-3 '>
                                                    <button
                                                        type="button"
                                                        class="btn btn-link editRemove textLeft paddingLeft4px"
                                                        data-toggle="modal"
                                                        data-target="#fileModal"
                                                        onClick={this.openFile.bind(this)}
                                                    >{t("settings.consent.clickHereLink")}</button>
                                                </div>
                                                <div className='col-md-3'>
                                                    <label class="switch">
                                                        <Field type="checkbox" id="forthWorthSharingConsent" name="forthWorthSharingConsent" />
                                                        <div class="slider round">
                                                            <span class="on">YES</span>
                                                            <span class="off">NO</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <h4>{t('settings.templink.title')}</h4>
                                    <br />
                                    <div className='row no-padding'>
                                        <div className='col-md-6 customLabel'>
                                            <div className='form-group'>
                                                <label htmlFor='timeElapsed'>{t('settings.templink.templinkDuration')}</label>
                                                <Field className="form-control" type="number" name="timeElapsed" id="timeElapsed" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-7">
                                        </div>
                                        <div className="col-md-5">
                                            <button type="submit" className="btn btn-primary buttonDefaultStyle" >{t('myChart.files.save')}</button>
                                            <Link className="btn btn-default buttonDefaultStyle" to={'/'} > {t('myChartProviders.cancelButton')}</Link>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>



                {/* <!-- Open File Modal --> */}
                <div className="modal fade" id="fileModal" tabIndex="-1" role="dialog" aria-labelledby="myFileModalLabel">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                            </div>
                            <div className="modal-body">
                                <PdfViewer file={this.state.openPdf}></PdfViewer>
                            </div>
                            <div className="modal-footer">
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
};

export default withTranslation()(Consent);