import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import profileReqHandler from '../../../api/profileRemote';
import providerReqHandler from '../../../api/providerRemote';
import profileHelper from '../Helpers/ProfileHelper';
import toastr from 'toastr';
import authentication from '../../../utilities/authentication';
import { Formik } from 'formik';
import { PersonalInfo, Gradebooks, Attendances, Discipline, HealthInfo } from './EducationForms';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Loader from 'react-loader-spinner'

class BaseProfile extends Component {
    profileId;
    formValues;

    constructor(props) {
        super(props);

        this.state = {
            isNew: false,
            profileLoaded: false,
            personId: null,
            disabled: false
        };
        this.disposeHelperObject = this.disposeHelperObject.bind(this);
    }

    setProfileToState(profile) {
        this.setState({
            profileLoaded: true,
            profileId: profile.id,
            profileData: profile.content,
            visibilitySchema: profile.visibilitySchema
        });
    }

    componentDidMount() {
        if (this.props.isNew === true) {
            this.setState({ personId: authentication.getPerson() });
            providerReqHandler.getAllProviders().then(res => {
                if (res) {
                    const provider = res.find((pr) => {
                        return pr.name === 'self provider' && pr.providerTypeName === 'education';
                    })
                    this.setState({ providerId: provider.id });
                }
            })

            this.formValues = profileHelper.education;
            this.setState({ isNew: true, profileLoaded: true });

            return;
        }

        this.profileId = this.props.match.params.id;

        this.formValues = this.props.profile && this.props.profile[this.profileId] ?
            this.props.profile[this.profileId].profile.content :
            profileHelper.myChart;

        this.formValues = {
            ...this.formValues,
            files: [],
            editFiles: this.props.profile[this.profileId].files || []
        }

        this.setState({ profileLoaded: true });
    }

    update(values) {
        const profile = {
            id: this.profileId,
            content: {
                studentInfo: values.studentInfo,
                gradebooks: values.gradebooks,
                attendances: values.attendances,
                disciplines: values.disciplines,
                healthInfo: values.healthInfo
            },
            visibilitySchema: this.props.profile[this.profileId].profile.visibilitySchema,
            fileNameChanges: [],
            deletedFiles: []
        };

        this.props.updateSelfProfile(profile).then(() => {
            toastr.success(this.props.t('editPeople.profileUpdatedSuccess'));
        })
    }

    addNew(values) {
        const userId = authentication.loggedData().userId;
        const personId = this.state.personId;
        const profile = {
            studentInfo: values.studentInfo,
            disciplines: values.disciplines,
            gradebooks: values.gradebooks,
            attendances: values.attendances,
            healthInfo: values.healthInfo
        };

        const providerId = this.state.providerId;
        const profileData = {
            userId,
            providerId,
            personId,
            profile
        }

        this.props.addProfile(profileData).then(() => {
            toastr.success(this.props.t('addPeople.profileSavedSuccess'));
        });
    }

    onFormSubmit(values) {
        this.setState({ disabled: true })
        if (this.state.isNew) {
            this.addNew(values);
        }
        else {
            this.update(values);
        }
        this.disposeHelperObject();
    }

    disposeHelperObject() {
        profileHelper.education = {

            gradebooks: [],
            attendances: [],
            disciplines: [],
            studentInfo: {
                firstName: '',
                lastName: '',
                schoolMail: '',
                address: '',
                call: '',
                address: '',
                gender: '',
                age: '',
                language: '',
                graduationyear: '',
                otherid: '',
                communityservice: '',
                second: '',
                school: '',
                principal: '',
                grade: '',
                status: '',
                homeRoom: '',
                disciplineOfficer: '',
                itemStudentInfo: []
            },
            healthInfo: {
                healthconditions: [],
                diabetescarelog: [],
                disabilities: [],
                officevisits: [],
                ihpforms: [],
                childhoodillnesses: [],
                injuries: [],
                medications: [],
                physicalexams: [],
                tests: [],
                vaccinations: []
            }
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                {this.state.profileLoaded &&
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={true}
                        initialValues={this.formValues}
                        enableReinitialize={true}
                        onSubmit={values => {
                            this.onFormSubmit(values)
                        }}
                    >
                        {
                            formik =>

                                <form className="create-profile-form" onSubmit={formik.handleSubmit} >
                                    <Fragment>
                                        <PersonalInfo formik={formik} t={t} />
                                        <Gradebooks formik={formik} t={t} />
                                        <Attendances formik={formik} t={t} />
                                        <Discipline formik={formik} t={t} />
                                        <HealthInfo formik={formik} t={t} />

                                        {/* START Health info */}
                                        {this.state.isNew &&
                                            <div className="text-right">
                                                <button disabled={this.state.disabled} type="submit" className="btn btn-primary button-update" id="btnSaveCloseFixWidth" >
                                                    <Loader
                                                        type="Oval"
                                                        color="#ffffff"
                                                        height={20}
                                                        width={20}
                                                        visible={this.state.disabled}>
                                                    </Loader>
                                                    {!this.state.disabled && <span>{t('education.saveProfileButton')}</span>}
                                                </button>
                                                <Link to='/' className='btn btn-default  btnSaveCloseFixWidth' >{t('education.cancelButton')}</Link>
                                            </div>
                                        }
                                        {!this.state.isNew &&
                                            <div className="text-right">
                                                <button disabled={this.state.disabled} type="submit" className="btn btn-primary button-update" id="btnSaveCloseFixWidth" >
                                                    <Loader
                                                        type="Oval"
                                                        color="#ffffff"
                                                        height={20}
                                                        width={20}
                                                        visible={this.state.disabled}>
                                                    </Loader>
                                                    {!this.state.disabled && <span>{t('education.updateProfileButton')}</span>}
                                                </button>
                                                <Link to='/' className='btn btn-default  btnSaveCloseFixWidth' >{t('education.cancelButton')}</Link>
                                            </div>
                                        }
                                    </Fragment>
                                </form>
                        }
                    </Formik>
                }
            </div>
        )
    };
}

const mapDispatchToProps = dispatch => {
    return {
        addProfile: (profileData, formData) => dispatch(profileReqHandler.addProfile(profileData, formData)),
        updateSelfProfile: (profileData, formData) => dispatch(profileReqHandler.updateSelfProfile(profileData, formData))
    }
}

export default withTranslation()(connect(null, mapDispatchToProps)(BaseProfile));