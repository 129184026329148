import host from './host';
import headers from '../utilities/headers';
import authentication from '../utilities/authentication';
import notifications from '../utilities/notifications';
import fetchApi from '../utilities/fetch';

let adminUserReqHandler = {

    getUsers: (param) => {

        return new Promise(resolve => {
            fetchApi.get(encodeURI(`/users?searchTags=${param.searchTags}`)).then(res => {
                if (res) {
                    const result = {
                        users: res,
                    };

                    resolve(result);
                }
            });
        })
    },
    updateUserType: (userData) => {

        return fetch(host + '/updateUserType', {
            method: 'POST',
            credentials: 'include',
            headers: headers.basic,
            body: JSON.stringify(userData)
        }).then(resp => {
            return handleResponse(resp);
        }).catch(e => {

            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    }
};

function handleResponse(resp) {

    if (resp.ok) {

        return resp.json();
    } else {

        return resp.json().then(e => {
            throw new Error(e);
        });
    }
}

export default adminUserReqHandler;