import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers/reducer'
import { composeWithDevTools } from 'redux-devtools-extension'

export const history = createBrowserHistory()

export function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history),
    preloadedState,
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        thunk
      ),
    ),
  )

  return store
}