import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import HttpsRedirect from 'react-https-redirect'
import App from './App';
import * as serviceWorker from './serviceWorker'
import { configureStore, history } from './store'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

const initialState = {};
const store = configureStore(initialState);

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <HttpsRedirect>
                <App />
            </HttpsRedirect>
        </ConnectedRouter>
    </Provider>, document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
