import React from "react";

export const Visits = ({ visits, t }) => {
  return (
    <div className="row">
      <div className="tab-warper">
        {/* <h4 className="headName">{t("myChart.visits.visitsTitle")}</h4> */}

        <div className="profileContent">
          <div className="row">
            <div className="col-md-6 customLabel">
              {t("myChart.shared.name", true)}
            </div>
            {/* <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div> */}
          </div>
          {visits.map((currVisit, i) => {
            
              return (
                currVisit.isVisible && 
                <div key={i} className="row sub-tab-warper">
                  <div className="col-md-6">{currVisit.visitName}</div>
                  {/* <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div> */}
                  <div className="row">
                    <div
                      id="root"
                      dangerouslySetInnerHTML={{
                        __html: currVisit.htmlSource,
                      }}
                    ></div>
                  </div>
                </div>
                    
              );
            })
          }
        </div>
      </div>
    </div>
  );
};
