import React from 'react';

export const Immunizations = ({ profiles, t }) => {

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('myChart.immunizations.immunizationsTitle')}</h4>

                <div className="profileContent">
                    <div className="row">
                        <div className="col-md-4 customLabel">{t('myChart.shared.name', true)}</div>
                        <div className="col-md-4 customLabel">{t('summary.person')}</div>
                        <div className="col-md-4 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return currProfile.immunizations.map((immunization, j) => {
                                return <div key={j} className="row sub-tab-warper">
                                    <div className="col-md-4">{immunization.name}</div>
                                    <div className="col-md-4">{currProfile.exportPersonName}</div>
                                    <div className="col-md-4">{currProfile.providerName}</div>
                                    <div className="row">
                                        <div className="row">
                                            <div className="col-md-6 customLabel">{t('myChart.shared.date', true)}</div>
                                            <div className="col-md-6 customLabel">{t('myChart.immunizations.code', true)}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">{immunization.date}</div>
                                            <div className="col-md-6">{immunization.code}</div>
                                        </div>
                                    </div>
                                </div>
                            })
                        })
                    }
                </div>
            </div>
        </div>

    )
}