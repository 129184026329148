import React, { Component } from 'react';
import toastr from 'toastr';
import MyChartProviderConfig from './MyChartProviderConfig';
import EducationProviderConfig from './EducationProviderConfig';
import FwisdProviderConfig from './FwisdProviderConfig';
import providerConfigReqHandler from '../../api/providersConfigRemote';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class ProviderCreate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            provider: {
                id: '',
                providerType: '',
            }
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const provider = { ...this.state.provider };
        provider.providerType = this.props.match.params.typeId;

        this.setState({
            provider,
        });
    }

    handleSubmit(data) {
        const providerData = {
            providerTypeId: this.props.match.params.typeId,
            name: data.name,
            configuration: data.configuration,
        };

        if (providerData.name.trim().length > 0) {

            this.props.addProviderConfig(providerData).then(res => {
                if (res.success) {
                    toastr.success(this.props.t('providers.createSuccess'));
                    this.props.history.push('/admin');
                }
            });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className='container'>
                <div className='row'>
                    <div className='"col-lg-12 col-sm-12 col-xs-6'>
                        <h2> Create new provider of type: {this.props.match.params.typeName.toUpperCase()}</h2>
                        {this.props.match.params.typeName === 'MyChart' &&
                            <MyChartProviderConfig t={t} provider={this.state.provider} providerTypeName={this.props.match.params.typeName} handleSubmit={this.handleSubmit} />}
                        {this.props.match.params.typeName === 'Education' &&
                            <EducationProviderConfig t={t} provider={this.state.provider} providerTypeName={this.props.match.params.typeName} handleSubmit={this.handleSubmit} />}
                        {this.props.match.params.typeName === 'FWISD' &&
                            <FwisdProviderConfig t={t} provider={this.state.provider} providerTypeName={this.props.match.params.typeName} handleSubmit={this.handleSubmit} />}
                    </div>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addProviderConfig: (providerData) => dispatch(providerConfigReqHandler.addProviderConfig(providerData))
    }
}

export default withTranslation()(connect(null, mapDispatchToProps)(ProviderCreate));