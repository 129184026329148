function formatFileTypes(rawType) {

    switch(rawType) {

        case 'application/pdf':
            return 'PDF Document';
        case 'text/plain':
        case 'application/octet-stream':
            return 'Document';
        case 'image/jpeg':
        case 'image/png':
            return 'Image';
        default: break;
    }
}

function unreadableFormat(rawType) {

    switch(rawType) {

        case 'application/pdf':
        case 'image/jpeg':
        case 'image/png':
            return false;
        default: return true;
    }
}

export default {
    formatFileTypes,
    unreadableFormat
};