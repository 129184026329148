import React, { Component } from 'react';
import toastr from 'toastr';
import providerConfigReqHandler from '../../api/providersConfigRemote';
import MyChartProviderConfig from './MyChartProviderConfig';
import EducationProviderConfig from './EducationProviderConfig';
import FwisdProviderConfig from './FwisdProviderConfig';
import { withTranslation } from 'react-i18next';

class ProviderUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            provider: {},
            providerTypeName: '',
            providerTypeId: ''
        }
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        const { typeName } = this.props.match.params;
        providerConfigReqHandler.getProviderId(id).then(res => {
            if (res) {
                this.setState({ 
                    provider: res,
                    providerTypeName: typeName,
                    providerTypeId: id
                });
            }
        });
    }


    handleSubmit = (data) => {
        const providerData = {
            id: data.id,
            name: data.name,
            providerType: data.providerType,
            configuration: data.configuration,
        }

        providerConfigReqHandler.updateProviderConfig(providerData).then(res => {
            if (res) {
                toastr.success(this.props.t('providers.updateSuccess'));
                this.props.history.push('/admin');
            }
        })

    }

    render() {
        const { t } = this.props;
        return (

            <div className='container'>
                <div className='row'>
                    <div className='"col-lg-12 col-sm-12 col-xs-6'>
                        <h2> {this.state.provider.name ? this.state.provider.name.toUpperCase() : ''}</h2>
                        {this.state.providerTypeName === 'MyChart' && this.state.provider &&
                            <MyChartProviderConfig t={t} provider={this.state.provider} handleSubmit={this.handleSubmit} />}
                        {this.state.providerTypeName === 'Education' && this.state.provider &&
                            <EducationProviderConfig t={t} provider={this.state.provider} handleSubmit={this.handleSubmit} />}
                        {this.state.providerTypeName === 'FWISD' && this.state.provider &&
                            <FwisdProviderConfig t={t} provider={this.state.provider} handleSubmit={this.handleSubmit} />}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ProviderUpdate);