import React, { Component } from 'react';
import jobsReqHandler from '../../api/jobsRemote';
import { withTranslation } from 'react-i18next';

class ProfileJobs extends Component {

    intervalID;

    constructor(props) {
        super(props);

        this.state = {
            jobs: []
        };

        this.getJobs = this.getJobs.bind(this);
    }   

    componentDidMount() {

        this.getJobs();
    }

    componentWillUnmount() {
        /*
          stop getData() from continuing to run even
          after unmounting this component. Notice we are calling
          'clearTimeout()` here rather than `clearInterval()` as
          in the previous example.
        */
        clearTimeout(this.intervalID);
      }

    getJobs() {

        jobsReqHandler.getJobs().then(res => {

            if (res) {
                this.setState({ jobs: res });

                this.intervalID = setTimeout(this.getJobs.bind(this), 5000);
            }
        });
    }

    deleteJob(arrayIndex, jobId, event) {

        const jobObj = {
            jobId: jobId
        };

        jobsReqHandler.deleteJob(jobObj).then(res => {

            if (res && res.success) {
                
                const currJobs = this.state.jobs;
                currJobs.splice(arrayIndex, 1);
                this.setState({ jobs: currJobs });
            }
        });
    }

    formatDateTime(jobTime) {

        const dateTime = new Date(jobTime);
        const month = ((dateTime.getMonth() + 1 < 10) ? "0" : "") + (dateTime.getMonth() + 1);
        const day = ((dateTime.getDate() < 10) ? "0" : "") + (dateTime.getDate());
        const hours = ((dateTime.getHours() < 10) ? "0" : "") + (dateTime.getHours());
        const minutes = ((dateTime.getMinutes() < 10) ? "0" : "") + (dateTime.getMinutes());
        const seconds = ((dateTime.getSeconds() < 10) ? "0" : "") + (dateTime.getSeconds());

        return `${dateTime.getFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <h2>{t('jobs.title')}</h2>

                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={ this.getJobs }
                        ><i className="fa fa-refresh" aria-hidden="true"></i> {t('jobs.refreshButton')}</button>

                        <div className="table-responsive">
                            <table className="table" >
                                <thead>
                                    <tr>
                                        <th className="col-md-2">{t('jobs.provider')}</th>
                                        <th className="col-md-1">{t('jobs.type')}</th>
                                        <th className="col-md-2">{t('jobs.startTime')}</th>
                                        <th className="col-md-2">{t('jobs.status')}</th>
                                        <th className="col-md-4">{t('jobs.error')}</th>
                                        <th className="col-md-1">{t('jobs.actions')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { this.state.jobs.length > 0 &&
                                        this.state.jobs.map((job, i) => {
                                            return <tr key={ i } className="customTableRow">
                                                <td className="customTableTd">{ job.providerName }</td>
                                                <td className="customTableTd">{ job.type }</td>
                                                <td className="customTableTd">{ this.formatDateTime(job.time) }</td>
                                                <td className="customTableTd">{ job.status }</td>
                                                <td className="customTableTd">{ job.error[0] }</td>
                                                <td className="customTableTd">
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger"
                                                            onClick={ this.deleteJob.bind(this, i, job.id) }
                                                        >{t('jobs.deleteButton')}</button>
                                                </td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
};

export default withTranslation()(ProfileJobs);