import React from 'react';
import update from 'immutability-helper';

export const updateEyesHelper = (Component) => {
    return class extends React.Component {

        markAll(values, markValue) {
            switch (true) {
                case typeof values === "boolean":
                    return markValue;
                case Object.prototype.toString.call(values) === '[object Array]':
                    values.forEach((value, index, theArray) => theArray[index] = this.markAll(value, markValue));
                    break;
                case typeof values === 'object':
                    Object.keys(values).forEach(key => {
                        if (values[key] !== null) {
                            values[key] = this.markAll(values[key], markValue)
                        }
                    });
                    break;
            }

            return values;
        }

        updateEyes(values, clickedEye, subClickedEye, subIndex) {
            let newValue;
            if (subClickedEye !== undefined) {
                if (Object.prototype.toString.call(values.visibilitySchema[clickedEye]) === '[object Array]') {
                    newValue = update(values, {
                        visibilitySchema: {
                            [clickedEye]: {
                                [subClickedEye]: {
                                    $set: !values.visibilitySchema[clickedEye][subClickedEye]
                                }
                            }
                        }
                    })
                } else {
                    if (subIndex !== undefined) {
                        newValue = update(values, {
                            visibilitySchema: {
                                [clickedEye]: {
                                    [subClickedEye]: {
                                        [subIndex]: {
                                            $set: !values.visibilitySchema[clickedEye][subClickedEye][subIndex]
                                        }
                                    }
                                }
                            }
                        })
                    } else {
                        if (subClickedEye.indexOf('.') !== -1) {
                            const subClickedEyeValues = subClickedEye.split('.');
                            newValue = update(values, {
                                visibilitySchema: {
                                    [clickedEye]: {
                                        [subClickedEyeValues[0]]: {
                                            [subClickedEyeValues[1]]: {
                                                $set: !values.visibilitySchema[clickedEye][subClickedEyeValues[0]][subClickedEyeValues[1]]
                                            }
                                        }
                                    }
                                }
                            })
                        } else {
                            newValue = update(values, {
                                visibilitySchema: {
                                    [clickedEye]: {
                                        [subClickedEye]: {
                                            $set: !values.visibilitySchema[clickedEye][subClickedEye]
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
            } else {
                newValue = update(values, {
                    visibilitySchema: {
                        _globalSettings: {
                            elements: {
                                [clickedEye]: {
                                    $set: !values.visibilitySchema._globalSettings.elements[clickedEye]
                                }
                            }
                        }
                    }
                });
            }

            return newValue;
        }

        eyeClicked(values, clickedEye, subClickedEye, subIndex) {
            let newValues;
            if (clickedEye === 'all') {
                newValues = update(values, {
                    visibilitySchema: {
                        _globalSettings: {
                            [clickedEye]: {
                                $set: !values.visibilitySchema._globalSettings.all
                            }
                        }
                    }
                })
            } else {
                newValues = this.updateEyes(values, clickedEye, subClickedEye, subIndex);
            }

            return newValues;
        }

        render() {
            return <Component
                {...this.props}
                eyeClicked={(values, clickedEye, subClickedEye, subIndex) => { return this.eyeClicked(values, clickedEye, subClickedEye, subIndex) }}
                markAll={(values, markValue) => this.markAll(values, markValue)}
            />
        }
    }
}