import host from '../api/host';
import headers from '../utilities/headers';
import authentication from '../utilities/authentication';
import notifications from '../utilities/notifications';
import cookies from '../utilities/cookies';

const fetchGet = (url) => {
    return fetch(host + url, {
        method: 'GET',
        credentials: 'include',
        headers: headers.basic
    })
}

const fetchPost = (url, data) => {
    return fetch(host + url, {
        method: 'POST',
        credentials: 'include',
        headers: headers.basic,
        body: JSON.stringify(data)
    })
}

const fetchPostFile = (url, data) => {
    return fetch(host + url, {
        method: 'POST',
        credentials: 'include',
        body: data
    })
}

const refreshToken = (callback) => {
    return fetch(host + '/refreshToken', {
        method: 'POST',
        credentials: 'include',
        headers: headers.basic,
        body: JSON.stringify({ 'refresh_token': cookies.get('refreshToken') })
    }).then(resp => {
        if (resp.status !== 200) {
            throw resp.status;
        }
        return callback();
    }).catch(e => {
        if (e === 401) {
            authentication.logout();
        } else {
            notifications.printError(e);
        }
    });
}

const handleResponse = (resp) => {
    if (resp.ok) {
        return resp.json();
    } else {
        return resp.json().then(e => {
            throw new Error(e);
        });
    }
}

const handleFileResponse = (resp) => {
    if (resp.ok) {
        return resp.blob();
    } else {
        return resp.json().then(e => {
            throw new Error(e);
        });
    }
}

const fetchApi = {
    get: (url) => {
        return fetchGet(url).then(resp => {
            return handleResponse(resp);
        }).catch(e => {

            if (e.message === 'jwt expired') {
                return refreshToken(() => fetchApi.get(url))
            } else {
                notifications.printError(e);
            }
        });
    },
    post: (url, data) => {
        return fetchPost(url, data).then(resp => {
            return handleResponse(resp);
        }).catch(e => {

            if (e.message === 'jwt expired') {
                return refreshToken(() => fetchApi.post(url, data))
            } else {
                notifications.printError(e);
            }
        });
    },
    postFile:(url, data) => {
        return fetchPostFile(url, data).then(resp => {
            return handleResponse(resp);
        }).catch(e => {

            if (e.message === 'jwt expired') {
                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    },
    getFile: (url) => {
        return fetchGet(url).then(resp => {
            return handleFileResponse(resp);
        }).catch(e => {

            if (e.message === 'jwt expired') {
                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    }
}

export default fetchApi;