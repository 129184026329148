import host from './host';
import headers from '../utilities/headers';
import authentication from '../utilities/authentication';
import notifications from '../utilities/notifications';
import fetchApi from '../utilities/fetch';

let fileTransferReqHandler = {

    fileUpload: (files) => {
        return fetchApi.postFile('/fileUpload', files);
    },
    photoUpload: (file) => {
        return fetchApi.postFile('/photoUpload', file);
    },
    getUserFiles: (profileId) => {
        
        return fetch(host + '/userFiles' + `?pid=${profileId}`, {
            method: 'GET',
            credentials: 'include',
            headers: headers.basic
        }).then(resp => {
            return handleResponse(resp);
        }).catch(e => {
            
            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    },
    getFileNamesByIds: (fileIds) => {
        return fetchApi.get(`/getFileNamesByIds?ids=${fileIds}`);
    },
    getFileNameById: (fileId) => {
        
        return fetch(host + '/getFileNameById' + `?id=${fileId}`, {
            method: 'GET',
            credentials: 'include',
            headers: headers.basic
        }).then(resp => {
            return handleResponse(resp);
        }).catch(e => {
            
            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    },
    openFile: (fileId) => {
        
        return fetch(host + '/openFile' + `?id=${fileId}`, {
            method: 'GET',
            credentials: 'include',
            headers: headers.basic
        }).then(resp => {
            return handleFileResponse(resp);
        }).catch(e => {
            
            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    },
    readFile: (fileId) => {
        
        return fetch(host + '/readFile' + `?id=${fileId}`, {
            method: 'GET',
            credentials: 'include',
            headers: headers.basic
        }).then(resp => {
            return handleFileResponse(resp);
        }).catch(e => {
            
            if (e.message === 'jwt expired') {

                authentication.logout();
            } else {
                notifications.printError(e);
            }
        });
    }
};

function handleFileResponse(resp) {

    if (resp.ok) {

        return resp.blob();
    } else {

        return resp.json().then(e =>{
            throw new Error(e);
        });
    }
}

function handleResponse(resp) {

    if (resp.ok) {

        return resp.json();
    } else {

        return resp.json().then(e =>{
            throw new Error(e);
        });
    }
}

export default fileTransferReqHandler;