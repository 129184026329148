import './i18n';
import React, { Component, Fragment, Suspense } from 'react';
import Header from './components/common/Header';
import Routes from './components/common/Routes';
import Footer from './components/common/Footer';
import './App.css';
import "toastr/build/toastr.min.css"
import './index.css';
import authentication from './utilities/authentication';
import { Switch, Route } from 'react-router';
import ProfileTab from './components/dashboard/ProfileTab';
import Summary from './components/summary/Summary';
import VisitTab from './components/dashboard/VisitTab';

class App extends Component {

  render() {
    return (
      <Fragment>
        <Suspense fallback={<Fragment></Fragment>}>
          <Switch>
            <Route path="/printProfile/:guid" component={ProfileTab}></Route>
            <Route path="/printVisit/:guid" component={VisitTab}></Route>
            <Route path="/printSummary/:guid" component={Summary}></Route>
            <Route path="/" render={() => (
              <div className="container">
                <Header loggedInData={authentication.loggedData()} />
                <main>
                  <Routes />
                </main>
                <Footer />
              </div>
            )} />
          </Switch>
        </Suspense>
      </Fragment>
    );
  }
}

export default App;