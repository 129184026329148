import React, { Fragment } from 'react';

export const Files = ({ profiles, files, t }) => {

    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('myChart.files.filesTitle')}</h4>

                <div className="profileContent">
                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.shared.name', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return <Fragment key={i}>
                                {currProfile.files && files[i] &&
                                    files[i].profileFiles.map((file, k) => {
                                        return <div key={k} className="row">
                                            <div className="col-md-6">{file.filename}</div>
                                            <div className="col-md-3">{currProfile.exportPersonName}</div>
                                            <div className="col-md-3">{currProfile.providerName}</div>
                                        </div>
                                    })
                                }
                            </Fragment>
                        })
                    }
                </div>
            </div>
        </div>
    )
}