import React, { Component, Fragment } from 'react';

class PlanOfCare extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        const filedData = property.split('-');
        const fieldName = filedData[0];
        const fieldId = parseInt(filedData[1]);

        switch (fieldName) {

            case 'planOfCare':
                return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';

            case 'planOfCareOverdue':
            case 'planOfCareNext':
            case 'planOfCareFuture':

                return this.props.visibilitySchema.planOfCare[fieldName][fieldId] ? 'eyeBlack' : 'eyeGray';

            default: break;
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('planOfCare'))}
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('myChart.planOfCare.planOfCareTitle')}</h4>
                        {this.props.profile.content &&
                            this.props.profile.content.planOfCare &&

                            <div className="profileContent">

                                <div className="row">
                                    <div className="historyLabel">{t('myChart.planOfCare.planOfCareOverdueTitle')}</div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-4 customLabel">{t('myChart.planOfCare.title')}</div>
                                    <div className="col-md-4 customLabel">{t('myChart.planOfCare.status')}</div>
                                </div>

                                {
                                    this.props.profile.content.planOfCare.planOfCareOverdue &&
                                    this.props.profile.content.planOfCare.planOfCareOverdue.map((pc, i) => {
                                        return <Fragment key={i}>
                                            <div className="row trows ">
                                                <div className="col-md-1">
                                                    {this.props.showVisibilitySettings &&
                                                        <i className={"fa fa-eye " + (this.eyeToggle('planOfCareOverdue-' + i))}
                                                            onClick={() => this.props.eyeClicked('planOfCareOverdue', i)}>
                                                        </i>
                                                    }
                                                </div>
                                                <div className="col-md-4">{pc.title}</div>
                                                <div className="col-md-3">{pc.status}</div>
                                            </div>
                                        </Fragment>
                                    })
                                }

                                <div className="row">
                                    <div className="historyLabel">{t('myChart.planOfCare.planOfCareNextTitle')}</div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 customLabel">{t('myChart.planOfCare.title')}</div>
                                    <div className="col-md-2 customLabel">{t('myChart.planOfCare.date')}</div>
                                    <div className="col-md-2 customLabel">{t('myChart.planOfCare.location')}</div>
                                    <div className="col-md-2 customLabel">{t('myChart.planOfCare.orderBy')}</div>
                                    <div className="col-md-2 customLabel">{t('myChart.planOfCare.orderDetails')}</div>
                                </div>

                                {this.props.profile.content.planOfCare.planOfCareNext &&

                                    this.props.profile.content.planOfCare.planOfCareNext.map((next, i) => {
                                        return <Fragment key={i}>
                                            <div className="row trows ">
                                                <div className="col-md-1">
                                                    {this.props.showVisibilitySettings &&
                                                        <i className={"fa fa-eye " + (this.eyeToggle('planOfCareNext-' + i))}
                                                            onClick={() => this.props.eyeClicked('planOfCareNext', i)}>
                                                        </i>
                                                    }
                                                </div>
                                                <div className="col-md-2">{next.title}</div>
                                                <div className="col-md-2">{next.date}</div>
                                                <div className="col-md-2">{next.location}</div>
                                                <div className="col-md-2">{next.orderBy}</div>
                                                <div className="col-md-2">{next.orderDetails}</div>
                                            </div>
                                        </Fragment>
                                    })
                                }

                                <div className="row">
                                    <div className="historyLabel">{t('myChart.planOfCare.planOfCareFutureTitle')}</div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2 customLabel">{t('myChart.planOfCare.title')}</div>
                                    <div className="col-md-2 customLabel">{t('myChart.planOfCare.date')}</div>
                                    <div className="col-md-2 customLabel">{t('myChart.planOfCare.location')}</div>
                                    <div className="col-md-2 customLabel">{t('myChart.planOfCare.orderBy')}</div>
                                    <div className="col-md-2 customLabel">{t('myChart.planOfCare.orderDetails')}</div>
                                </div>

                                {this.props.profile.content.planOfCare.planOfCareFuture &&

                                    this.props.profile.content.planOfCare.planOfCareFuture.map((future, i) => {
                                        return <Fragment key={i}>
                                            <div className="row trows ">
                                                <div className="col-md-1">
                                                    {this.props.showVisibilitySettings &&
                                                        <i className={"fa fa-eye " + (this.eyeToggle('planOfCareFuture-' + i))}
                                                            onClick={() => this.props.eyeClicked('planOfCareFuture', i)}>
                                                        </i>
                                                    }
                                                </div>
                                                <div className="col-md-2">{future.title}</div>
                                                <div className="col-md-2">{future.date}</div>
                                                <div className="col-md-2">{future.location}</div>
                                                <div className="col-md-2">{future.orderBy}</div>
                                                <div className="col-md-2">{future.orderDetails}</div>
                                            </div>
                                        </Fragment>
                                    })
                                }

                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
};

export default PlanOfCare;