import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import profileReqHandler from '../../api/profileRemote';
import providerReqHandler from '../../api/providerRemote';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';

class AllProfilesList extends Component {

    endCursor = '';
    hasMore = true;
    searchData = {
        searchTags: ''
    };

    constructor(props) {
        super(props);

        this.state = {
            profiles: []
        };

        this.getAllProfiles = this.getAllProfiles.bind(this);
        // this.getProfile = this.getProfile.bind(this);
    }

    componentDidMount() {
        this.getAllProfiles();
    }

    getAllProfiles() {
        let params = {
            endCursor: this.endCursor
        }

        if (this.searchData) {
            params = {
                ...params,
                ...this.searchData
            }
        }

        profileReqHandler.getAllProfiles(params).then(res => {
            if (res) {
                this.endCursor = res.endCursor;
                this.hasMore = res.hasMore;
                this.setState({ profiles: this.state.profiles.concat(res.profiles) });
            }
        });
    }

    onFormSubmit(values) {
        this.endCursor = '';
        this.searchData = values;        
        this.setState({ profiles: [] });
        this.getAllProfiles();
    }

    noRefresh(resetForm) {
        this.endCursor = '';
        this.searchData = {
            searchTags: ''
        };
        this.setState({ profiles: [] });
        resetForm(this.searchData);
        this.getAllProfiles();
    }

    loadProfile(profileData) {

        let data = {};
        providerReqHandler.getProvider(profileData.providerId).then(
            res => {
                if (res) {
                    data = {
                        profileId: profileData.profileId,
                        providerName: res.providerName,
                        providerTypeName: res.providerTypeName,

                    }
                    this.props.history.push({
                        pathname: '/profileDashboard',
                        state: { profileData: data, profile: profileData, adminMode: true }
                    })
                }
            })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 col-xs-12">
                        <h2>{t('allProfilesList.title')}</h2>
                        <Formik
                            validateOnChange={false}
                            validateOnBlur={true}
                            initialValues={this.searchData}
                            enableReinitialize={true}
                            onSubmit={(values) => this.onFormSubmit(values)}
                        >
                            {formik => (
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="form-group">
                                                <label htmlFor="searchTags">{t('allProfilesList.searchField')}</label>
                                                <Field className="form-control" type="text" name="searchTags" id="searchTags" />
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="marginTop25">
                                                <button
                                                    className="btn btn btn-primary"
                                                >{t('allProfilesList.searchButton')}</button>
                                                <button
                                                    type="button"
                                                    className="btn btn-default marginLeft10"
                                                    onClick={() => this.noRefresh(formik.resetForm)}
                                                ><i className="fa fa-refresh" aria-hidden="true"></i> {t('allProfilesList.refreshButton')}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </Formik>
                        <InfiniteScroll
                            dataLength={this.state.profiles.length}
                            next={() => this.getAllProfiles('test')}
                            hasMore={this.hasMore}
                            loader={<h4>{t('allProfilesList.loading')}</h4>}
                        >
                            <div className="table-responsive">
                                <table className="table" >
                                    <thead>
                                        <tr>
                                            <th className="col-md-2">{t('allProfilesList.userMail')}</th>
                                            <th className="col-md-2">{t('allProfilesList.firstName')}</th>
                                            <th className="col-md-2">{t('allProfilesList.lastName')}</th>
                                            {/* <th className="col-md-2">{t('allProfilesList.provider')}</th> */}
                                            <th className="col-md-2">{t('allProfilesList.addressField')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.profiles.length > 0 &&
                                            this.state.profiles.map((profile, i) => {
                                                return <tr key={i} className="customTableRow">
                                                    <td className="customTableTd">{profile.email}</td>
                                                    <td className="customTableTd">{profile.firstName}</td>
                                                    <td className="customTableTd">{profile.lastName}</td>
                                                    <td className="customTableTd">{profile.address}</td>
                                                    <td className="customTableTd">
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary marginLeft10"
                                                            onClick={this.loadProfile.bind(this, profile)}
                                                        > {t('allProfilesList.showProfile')}
                                                        </button>

                                                    </td>

                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </InfiniteScroll>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        // profilesList: state.profile.profilesList,
        profile: state.profile.profileData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // getProfilesList: personId => dispatch(profileReqHandler.getProfilesList(personId)),
        getProfile: profileId => dispatch(profileReqHandler.getProfile(profileId)),
        updateVisibilitySchema: updateData => dispatch(profileReqHandler.updateVisibilitySchema(updateData))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AllProfilesList));