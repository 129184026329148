import React from 'react';

export const Allergies = ({ profiles, t }) => {
    return (
        <div className="row">
            <div className="tab-warper">
                <h4 className="headName">{t('myChart.allergies.allergiesTitle')}</h4>

                <div className="profileContent">
                    <div className="row">
                        <div className="col-md-6 customLabel">{t('myChart.shared.name', true)}</div>
                        <div className="col-md-3 customLabel">{t('summary.person')}</div>
                        <div className="col-md-3 customLabel">{t('summary.provider')}</div>
                    </div>
                    {
                        profiles.map((currProfile, i) => {
                            return currProfile.allergies.map((allergy, j) => {
                                return <div key={j} className="row sub-tab-warper">
                                    <div className="col-md-6">{allergy.name}</div>
                                    <div className="col-md-3">{currProfile.exportPersonName}</div>
                                    <div className="col-md-3">{currProfile.providerName}</div>
                                    <div className="row">
                                        <div className="row">
                                            <div className="col-md-6 customLabel">{t('myChart.allergies.type', true)}</div>
                                            <div className="col-md-3 customLabel">{t('myChart.allergies.severity', true)}</div>
                                            <div className="col-md-3 customLabel">{t('myChart.shared.date', true)}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">{allergy.type}</div>
                                            <div className="col-md-3">{allergy.severity}</div>
                                            <div className="col-md-3">{allergy.date}</div>
                                        </div>
                                    </div>
                                </div>
                            })
                        })
                    }
                </div>
            </div>
        </div>

    )
}