import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const Vaccinations = ({ formik, t }) => {
    return (
        <FieldArray
            name="healthInfo.vaccinations"
            render={
                arrayHelper => (
                    <Fragment>

                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayHelper.push({
                                    vaccination: '',
                                    compliance: '',
                                    waiver: '',
                                    type: '',
                                    date1: '',
                                    date2: '',
                                    date3: '',
                                    date4: '',
                                    date5: ''
                                })}>
                                    <i className="fa fa-plus"></i> {t('education.healthInfo.vaccinations.addVaccinationButton')}
                                </button>
                            </div>
                        </div>

                        <div className="profileContent">
                            {
                                formik.values.healthInfo.vaccinations.map((vaccination, i) => {
                                    return <Fragment key={i}>
                                        <div className="row">
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'vaccination-' + i}>{t('education.healthInfo.vaccinations.vaccination')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.vaccinations[${i}].vaccination`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'compliance-' + i}>{t('education.healthInfo.vaccinations.compliance')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.vaccinations[${i}].compliance`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'waiver-' + i}>{t('education.healthInfo.vaccinations.waiver')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.vaccinations[${i}].waiver`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'type-' + i}>{t('education.healthInfo.vaccinations.type')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.vaccinations[${i}].type`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'date1-' + i}>{t('education.healthInfo.vaccinations.date1')}</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        {...formik.getFieldProps(`healthInfo.vaccinations[${i}].date1`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'date2-' + i}>{t('education.healthInfo.vaccinations.date2')}</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        {...formik.getFieldProps(`healthInfo.vaccinations[${i}].date2`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'date3-' + i}>{t('education.healthInfo.vaccinations.date3')}</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        {...formik.getFieldProps(`healthInfo.vaccinations[${i}].date3`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'date4-' + i}>{t('education.healthInfo.vaccinations.date4')}</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        {...formik.getFieldProps(`healthInfo.vaccinations[${i}].date4`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'date5-' + i}>{t('education.healthInfo.vaccinations.date5')}</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        {...formik.getFieldProps(`healthInfo.vaccinations[${i}].schoolydate5ear`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <label>{t('education.shared.remove')}</label>
                                                <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelper.remove(i)} >
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Fragment>
                                })
                            }
                        </div>
                    </Fragment>
                )
            }
        />
    );
}