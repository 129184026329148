import React, { Component, Fragment } from 'react';

class ChildInfoDyslexia extends Component {

    constructor(props) {
        super(props);

        this.eyeToggle = this.eyeToggle.bind(this);
    }

    eyeToggle(property) {

        if (property === 'childInfoDyslexia') {
            return this.props.visibilitySchema._globalSettings.elements[property] ? 'eyeBlack' : 'eyeGray';
        } else {
            return this.props.visibilitySchema.childInfoDyslexia[property] ? 'eyeBlack' : 'eyeGray';
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className="container">
                <div className="row">
                    <div>
                        {this.props.profile.content && this.props.showVisibilitySettings &&
                            <i className={"fa fa-eye " + (this.eyeToggle('childInfoDyslexia'))}
                                data-key="childInfoDyslexia"
                                onClick={() => this.props.eyeClicked()}>
                            </i>
                        }
                        <h4 className="headName">{t('fwisd.childInfoDyslexia.title')}</h4>
                        {this.props.profile.content && this.props.profile.content.childInfoDyslexia && 
                            <div className="profileContent">
                                <div>
                                    {this.props.profile.content.childInfoDyslexia.internal1 &&
                                        <Fragment>
                                            {this.props.showVisibilitySettings &&
                                                <i className={"fa fa-eye " + (this.eyeToggle('internal1'))}
                                                    onClick={() => this.props.eyeClicked('internal1')}>
                                                </i>
                                            }
                                            <span className="persInfoPtag">{t('fwisd.childInfoDyslexia.internal1')} {this.props.profile.content.childInfoDyslexia.internal1}</span>
                                        </Fragment>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    };
}
export default ChildInfoDyslexia;