import React from 'react';

export const MedicalQuestionsSelectors = ({ formik, t }) => (
    <div className='tab-warper'>
        <h4 className='headName'>{t('myChartProviders.medicalQuestions.medicalQuestionsTitle')}</h4>
        <div className='profileContent'>

            <div className='row'>
                <div className='col-md-6 customLabel'>
                    <div className='form-group'>
                        <label htmlFor='medicalQuestionsUrl'>{t('myChartProviders.shared.url')}</label>
                        <input
                            className='form-control'
                            {...formik.getFieldProps('configuration.medicalQuestionsUrl')}
                        />
                    </div>
                </div>               
            </div>           
        </div>
    </div >

)