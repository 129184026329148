import React from 'react';
import BaseProfile from './BaseProfile';
import { Translation } from 'react-i18next';

const CreateProfile = (props) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-sm-10 col-xs-12">
                    <Translation>
                        {
                            t => <h2>{t('myChart.addProfileTitle')}</h2>
                        }
                    </Translation>
                    <div className="row">
                        <BaseProfile {...props} isNew={true} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateProfile;