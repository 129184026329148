import React, { Fragment } from 'react';
import { FieldArray } from 'formik';

export const DiabeteScarelog = ({ formik, t }) => {
    return (
        <FieldArray
            name="healthInfo.diabetescarelog"
            render={
                arrayHelper => (
                    <Fragment>
                        <div className="row buttonFromHead">
                            <div className="col-md-6 customLabel">
                                <button type="button" className="btn" onClick={() => arrayHelper.push({
                                    schoolyear: '',
                                    date: '',
                                    time: '',
                                    bloodglucose: '',
                                    iob: '',
                                    carbintake: '',
                                    insulindose: '',
                                    injectionsite: '',
                                    ketoneresults: '',
                                    treatment: '',
                                    school: '',
                                    examinedby: '',
                                    comment: '',
                                    parentnotified: ''
                                })}>
                                    <i className="fa fa-plus"></i> {t('education.healthInfo.diabeteScarelog.addDiabeteScarelogButton')}
                                </button>
                            </div>
                        </div>

                        <div className="profileContent">
                            {
                                formik.values.healthInfo.diabetescarelog.map((diabetescarelog, i) => {
                                    return <Fragment key={i}>
                                        <div className="row">
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'schoolyear-' + i}>{t('education.shared.schoolYear')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].schoolyear`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'date-' + i}>{t('education.shared.date')}</label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].date`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'time-' + i}>{t('education.shared.time')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].time`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'bloodglucose-' + i}>{t('education.healthInfo.diabeteScarelog.bloodGlucose')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].bloodglucose`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'iob-' + i}>{t('education.healthInfo.diabeteScarelog.IOB')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].iob`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'carbintake-' + i}>{t('education.healthInfo.diabeteScarelog.carbIntake')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].carbintake`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'insulindose-' + i}>{t('education.healthInfo.diabeteScarelog.insulinDose')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].insulindose`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'injectionsite-' + i}>{t('education.healthInfo.diabeteScarelog.injectionSite')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].injectionsite`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'ketoneresults-' + i}>{t('education.healthInfo.diabeteScarelog.ketoneResults')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].ketoneresults`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'treatment-' + i}>{t('education.shared.treatment')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].treatment`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'school-' + i}>{t('education.shared.school')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].school`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'examinedby-' + i}>{t('education.shared.examinedBy')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].examinedby`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'comment-' + i}>{t('education.healthInfo.diabeteScarelog.comment')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].comment`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-3 customLabel">
                                                <div className="form-group">
                                                    <label htmlFor={'parentnotified-' + i}>{t('education.healthInfo.diabeteScarelog.parentNotified')}</label>
                                                    <input
                                                        className="form-control"
                                                        {...formik.getFieldProps(`healthInfo.diabetescarelog[${i}].parentnotified`)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-1">
                                                <label>{t('education.shared.remove')}</label>
                                                <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelper.remove(i)} >
                                                    <span>&times;</span>
                                                </button>
                                            </div>
                                        </div>
                                    </Fragment>
                                })
                            }
                        </div>
                    </Fragment>
                )
            }
        />
    );
}