import React from 'react';
import { HealthCondition } from './HealthCondition';
import { DiabeteScarelog } from './DiabeteScarelog';
import { Disability } from './Disability';
import { Officevisits } from './Officevisits';
import { IHPForms } from './IHPForms';
import { ChildhoodIllnesses } from './ChildhoodIllnesses';
import { Injuries } from './Injuries';
import { Medications } from './Medications';
import { PhysicalExams } from './PhysicalExams';
import { Tests } from './Tests';
import { Vaccinations } from './Vaccinations';

export const HealthInfo = ({ formik, t }) => {
    return (
        <div className="tab-warper">
            <h4 className="headName">{t('education.healthInfo.healthInfoTitle')}</h4>
            <HealthCondition formik={formik} t={t} />
            <DiabeteScarelog formik={formik} t={t} />
            <Disability formik={formik} t={t} />
            <Officevisits formik={formik} t={t} />
            <IHPForms formik={formik} t={t} />
            <ChildhoodIllnesses formik={formik} t={t} />
            <Injuries formik={formik} t={t} />
            <Medications formik={formik} t={t} />
            <PhysicalExams formik={formik} t={t} />
            <Tests formik={formik} t={t} />
            <Vaccinations formik={formik} t={t} />
        </div>
    )
}