import React from 'react';
import { withTranslation } from 'react-i18next';
const spainFlag = require('../../spain-flag-icon-64.png');
const usaFlag = require('../../united-states-of-america-flag-icon-64.png');

const LanguageSelector = ({ t, i18n }) => {
    const getFlag = ((flag) => {
        if (flag === "en") {
            return usaFlag;

        } else {
            return spainFlag;
        }
    });

    return (
        <li className="dropdown">
            <a href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false">
                <img className="languageImage" src={getFlag(`${i18n.language}`)} />
                <span className="spanMarginRgiht textLeft"> {t(`languages.${i18n.language}`)}</span>

            </a>
            <ul className="dropdown-menu">
                <li>
                    <a className="btn btn-link" onClick={() => i18n.changeLanguage('en')}>
                        <img className="languageImage" src={usaFlag} />
                        <span className="spanMargin textLeft"> {t(`languages.en`)}</span>
                    </a>
                </li>
                <li>
                    <a className="btn btn-link" onClick={() => i18n.changeLanguage('es')}>
                        <img className="languageImage" src={spainFlag} />
                        <span className="spanMargin textLeft">{t(`languages.es`)}</span>

                    </a>
                </li>
            </ul>
        </li>
    )
}

export default withTranslation()(LanguageSelector);