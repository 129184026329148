import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export const SurgicalHistory = ({ formik, arrayHelpers }) => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <div className="row buttonFromHead">
                <div className="col-md-6 customLabel">
                    <button type="button" className="btn" onClick={() => arrayHelpers.push({ name: '', when: '' })}>
                        <i className="fa fa-plus"></i> {t('myChart.history.addSurgicalHistoryButton')}
                    </button>
                </div>
            </div>
            <div className="profileContent">
                {
                    formik.values.history.surgicalHistory && formik.values.history.surgicalHistory.length !== 0 &&
                    formik.values.history.surgicalHistory.map((sh, i) => {
                        return <Fragment key={i}>
                            <div className="row">
                                <div className="col-md-6 customLabel">
                                    <div className="form-group">
                                        <label htmlFor={'name-' + i}>{t('myChart.shared.name')}</label>
                                        <input
                                            id={'name-' + i}
                                            className="form-control"
                                            {...formik.getFieldProps(`history.surgicalHistory[${i}].name`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-5 customLabel">
                                    <div className="form-group">
                                        <label htmlFor={'when-' + i}>{t('myChart.shared.when')}</label>
                                        <input
                                            id={'when-' + i}
                                            className="form-control"
                                            type="date"
                                            {...formik.getFieldProps(`history.surgicalHistory[${i}].when`)}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    <label>{t('myChart.shared.remove')}</label>
                                    <button type="button" className="btn btn-danger editRemove" onClick={() => arrayHelpers.remove(i)} >
                                        <span>&times;</span>
                                    </button>
                                </div>
                            </div>
                        </Fragment>
                    })
                }
            </div>
        </Fragment>
    );
}