import React, { Component, Fragment } from 'react';
import userReqHandler from '../../api/userRemote';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { InputErrorText } from '../common/InputErrorText';
import { oneOf } from 'prop-types';

class Register extends Component {

    onFormSubmit(values) {

        const regData = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            password: values.password
        };

        userReqHandler.register(regData).then(res => {

            if (res) {
                this.props.history.push('/login');
            }
        });
    }

    formValidation() {
        const { t } = this.props;
        const nameRegex = new RegExp(/^[A-Z]{1}[a-z]+$/);
        const emailRegex = new RegExp(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/);
        const strongPassRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/);

        return Yup.object({
            // firstName: Yup.string()
            //     .required(t('validationErrors.fieldEmpty'))
            //     .matches(nameRegex, t('validationErrors.firstNameInvalid')),
            // lastName: Yup.string()
            //     .required(t('validationErrors.fieldEmpty'))
            //     .matches(nameRegex, t('validationErrors.lastNameInvalid')),
            email: Yup.string()
                .required(t('validationErrors.fieldEmpty'))
                .matches(emailRegex, t('validationErrors.emailNotValid')),
            password: Yup.string()
                .required(t('validationErrors.fieldEmpty'))
                .matches(strongPassRegex, t('validationErrors.strongPass')),
            repPassword: Yup.string()
                .required(t('validationErrors.fieldEmpty'))
                .oneOf([Yup.ref('password')], 'Passwords not match.')
                .matches(strongPassRegex, t('validationErrors.strongPass')),
            agreements: Yup.boolean()
                .oneOf([true], t('validationErrors.agreements'))
        })
    }

    render() {
        const { t } = this.props;
        return (
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-6 col-xs-6">
                            <h2>{t('register.title')}</h2>
                            <Formik
                                validateOnChange={false}
                                validateOnBlur={true}
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    email: '',
                                    password: '',
                                    repPassword: '',
                                    agreements: false
                                }}
                                validationSchema={this.formValidation()}
                                onSubmit={(values) => this.onFormSubmit(values)}
                            >
                                {formik => (
                                    <form className="register-form" onSubmit={formik.handleSubmit} >

                                        <div className="form-group">
                                            <label htmlFor="firstName">{t('register.firstName')}</label>
                                            <input
                                                {...formik.getFieldProps('firstName')}
                                                className="form-control"
                                                placeholder={t('register.firstNamePlaceholder')}
                                            />
                                            <InputErrorText formik={formik} name="firstName" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="lastName">{t('register.lastName')}</label>
                                            <input
                                                {...formik.getFieldProps('lastName')}
                                                className="form-control"
                                                placeholder={t('register.lastNamePlaceholder')}
                                            />
                                            <InputErrorText formik={formik} name="lastName" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="email">{t('register.emailAddress')}</label>
                                            <input
                                                {...formik.getFieldProps('email')}
                                                className="form-control"
                                                placeholder={t('register.emailAddressPlaceholder')}
                                            />
                                            <InputErrorText formik={formik} name="email" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="password">{t('register.password')}</label>
                                            <input
                                                {...formik.getFieldProps('password')}
                                                type="password"
                                                className="form-control"
                                                placeholder={t('register.passwordPlaceholder')}
                                            />
                                            <InputErrorText formik={formik} name="password" />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="repPassword">{t('register.repeatPassword')}</label>
                                            <input
                                                {...formik.getFieldProps('repPassword')}
                                                type="password"
                                                className="form-control"
                                                placeholder={t('register.repeatPasswordPlaceholder')}
                                            />
                                            <InputErrorText formik={formik} name="repPassword" />
                                        </div>

                                        <div className="form-group">
                                            <input
                                                {...formik.getFieldProps('agreements')}
                                                id="agreements"
                                                type="checkbox"
                                                className="custom-control-input"
                                            />
                                            <label className="custom-control-label" id="agreementsLabel" htmlFor="agreements">{t('register.userAgreements')}</label><br />
                                            <InputErrorText formik={formik} name="agreements" />
                                        </div>

                                        <input type="submit" className="btn btn-primary" value={t('register.registerButton')} />
                                    </form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };
};

export default withTranslation()(Register);